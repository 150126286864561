import React from 'react';

import { Table } from '@components/shared/Table';

import { ScreenerFieldCell } from '../components/ScreenerFieldCell';
import * as Types from '../types';
import {
  getPreScreenerFieldId,
  getSurveyScreenerFieldId
} from 'components/StudiesApp/components/StudyPublished/components/buildParticipationsFilterDefs';

export const screenerFieldColumnDefinitions: Types.DefinitionFn = ({
  addFilter,
  study,
  serverSideTableEnabled = false
}) => {
  const columns: Types.DefinitionFnReturn = [];

  study?.pre_screener?.fields?.forEach(({ id, label }) => {
    columns.push({
      id: getPreScreenerFieldId(id, serverSideTableEnabled),
      meta: {
        label
      },
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='text'
          render={({ row }) => <ScreenerFieldCell screenerResult={row.original.screenerResults?.[id]} />}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props} tooltipText={label}>
          {label}
        </Table.ColumnHeader>
      )
    });
  });

  study?.survey_screener?.fields?.forEach(({ id, label }) => {
    columns.push({
      id: getSurveyScreenerFieldId(id, serverSideTableEnabled),
      meta: {
        label
      },
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='text'
          render={({ row }) => <ScreenerFieldCell screenerResult={row.original.screenerResults?.[id]} />}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props} tooltipText={label}>
          {label}
        </Table.ColumnHeader>
      )
    });
  });

  return columns;
};
