import * as React from 'react';

import { api as reduxApi, api } from '@api/reduxApi';
import { Alert, Loading, SlideOut } from '@components/common';
import { useCancelBackgroundTaskMutation } from '@components/shared/BackgroundTaskStatus/api';
import { usePlan } from '@hooks/usePlan';
import { useToasterWithTimeout } from '@stores/toaster';

import { AlreadyParticipatedAlert, UncontactableAlert } from './components/alerts';
import { MAX_BATCH_SIZE } from './consts';
import { StudyMessageSlideOut } from './StudyMessageSlideOut';
import { canceledSend, failedSend, successSend } from './toasts';
import { SendMessageParams } from './types';
import { getBatchingSettings } from './utils/getBatchingSettings';
import { useStudyLimits } from 'components/StudiesApp/components/StudyPublished/hooks/useStudyLimits';
import { useParticipantsCounts } from '@components/StudyMessages/hooks/useParticipantsCounts';

interface Props {
  teamId?: number | null;
  study: Study;
  resend?: boolean;
  onClose: () => void;
  onSuccess: (backgroundTask: BackgroundTask) => void;
  allSelected?: boolean;
  query?: ServerFilterQuery;
  selectedParticipationIds?: number[];
  previewParticipation: Participation;
}

export const ParticipationsInviteSlideOutServerSide: React.FC<Props> = ({
  study,
  resend = false,
  onClose,
  onSuccess,
  allSelected,
  query,
  selectedParticipationIds,
  previewParticipation
}) => {
  const {
    ineligibleCount: ineligible,
    invitableCount: invitable,
    contactableCount: contactable,
    reinviteableCount: reinviteable,
    isLoading: countsLoading
  } = useParticipantsCounts({
    studyId: study.id,
    query,
    ids: selectedParticipationIds
  });

  const toBeSentCount: number = invitable + (resend ? reinviteable : 0);
  const totalCount: number = selectedParticipationIds?.length || query?.count || 0;

  const [cancelBackgroundTask] = useCancelBackgroundTaskMutation();
  const { hasQuota, getQuota } = usePlan();
  const [showToast, clearToastTimeout] = useToasterWithTimeout();
  const [invite, { isLoading: isSending }] = api.useStudiesBulkParticipationInviteMutation();
  // const { studyLimits, studyLimitMatches } = useStudyLimits(study, {
  // ...(allSelected ? { query } : { ids: data?.inviteable_ids || [] })
  // });

  const handleSend: (params: SendMessageParams) => Promise<void> = async ({
    batch,
    addToBatch,
    resend,
    sender,
    excludeIneligible,
    message
  }) => {
    const sendNow = study?.batch_on && !addToBatch;

    const invite_params = {
      batch_on: batch.on,
      batch_size: batch.size,
      batch_wait_hours: batch.wait_hours,
      batch_auto_restart: batch.auto_restart
    };

    const participantsFilterQuery = query as ParticipantsFilterQuery;

    const customerIdsOrQuery =
      allSelected && query ? { query: participantsFilterQuery } : { participation_ids: selectedParticipationIds };

    try {
      const backgroundTask = await invite({
        studyId: study.id,
        sender: sender,
        send_now: sendNow,
        invite: invite_params,
        exclude_ineligible: excludeIneligible,
        message_id: message && message.custom ? message.id : null,
        template_name: 'invite',
        resend,
        ...customerIdsOrQuery
      }).unwrap();

      showToast(successSend(!!study, study, backgroundTask, handleCancel));
      onSuccess(backgroundTask);
    } catch (e) {
      showToast(failedSend(e.data.error));
    }
  };

  const handleCancel: (task: BackgroundTask) => Promise<void> = async (task) => {
    clearToastTimeout();

    try {
      await cancelBackgroundTask(task).unwrap();
      showToast(canceledSend());
    } catch (e) {
      showToast(failedSend(e.data.error));
    }
  };

  const batchingSettings = getBatchingSettings(toBeSentCount, study);
  const hasInviteQuota = hasQuota('invites', toBeSentCount);

  if (!hasInviteQuota) {
    return (
      <SlideOut title='Participation invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Alert type='error'>
            Cannot send all {toBeSentCount} messages as this exceeds your remaining quota of {getQuota('invites')}
          </Alert>
        </div>
      </SlideOut>
    );
  }

  if (countsLoading) {
    return (
      <SlideOut title='Participation invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Loading absolute />
        </div>
      </SlideOut>
    );
  }

  if (toBeSentCount === 0) {
    return (
      <SlideOut title='Participation invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Alert type='error'>
            There are no valid candidates to invite. Please check the selected candidates and try again.
          </Alert>
        </div>
      </SlideOut>
    );
  }

  return (
    <StudyMessageSlideOut
      continuousable={true}
      batchable
      batchingRequired={toBeSentCount > MAX_BATCH_SIZE}
      defaultBatching={batchingSettings}
      title='Participation invite'
      batchingTitle='study invites'
      study={study}
      event={resend ? 'invited_reminder' : 'invite'}
      inviteableCount={invitable}
      totalCount={totalCount}
      ineligibleCount={ineligible}
      contactableCount={contactable}
      customizable={!study.batch_on || !study.next_batch_at}
      resendCount={reinviteable}
      defaultResend={resend}
      previewId={previewParticipation?.customer_id}
      onClose={onClose}
      onSend={handleSend}
      // studyLimits={studyLimits}
      // studyLimitMatches={studyLimitMatches}
    >
      <>
        {isSending && <Loading absolute />}
        {reinviteable > 0 && <AlreadyParticipatedAlert diff={reinviteable} total={totalCount} />}
        {contactable < totalCount && (
          <UncontactableAlert diff={totalCount - contactable} medium={study.comms_medium} total={totalCount} />
        )}
      </>
    </StudyMessageSlideOut>
  );
};
