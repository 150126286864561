import { BasicAppLoader } from 'components/providers/BasicContext';
import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { renderComp } from '@components/utils';

import { AccountSwitcher } from './AccountSwitcher';

export const Wrapper = () => {
  return (
    <BasicAppLoader>
      <Router>
        <Routes>
          <Route path='*' element={<AccountSwitcher />} />
        </Routes>
      </Router>
    </BasicAppLoader>
  );
};

export function mount(): void {
  renderComp('AccountSwitcherApp', Wrapper);
}
