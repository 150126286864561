import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import tinytime from 'tinytime';

import { Text, TippyOrNot } from '@components/common';
import { TeamPicker } from '@components/shared/TeamPicker';
import { AskAiButton } from '@components/AiChat/components/AskAiButton';
import { STUDY_MUTATIONS } from '@components/StudiesApp/constants';
import { CircleArrowsSVG, HamburgerSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { useFeature } from '@hooks/useFeature';
import { usePendingRequests } from '@hooks/usePendingRequests';
import { useFixedSidebar } from '@hooks/useSidebar';

import { StudyStatus } from './../StudyStatus';
import { StudyUsers } from './../StudyUsers';
import { OptionsDropdown } from './OptionsDropdown';
import { ApprovalButton } from './ApprovalButton';

const DISABLED_TOOLTIP_DATE_FORMAT = tinytime('{MM} {DD} at {h}:{mm}{a}');

interface HeaderProps {
  study: Study;
  setLoading: (loading: boolean) => void;
  canEdit: boolean;
  updateStudy: (study: { id: number } & Partial<Study>) => void;
  isUpdating: boolean;
  approvalRequest?: ApprovalRequest;
}

export const Header: React.FC<HeaderProps> = ({
  study,
  setLoading,
  canEdit,
  updateStudy,
  isUpdating,
  approvalRequest
}) => {
  const { toggle, open } = useFixedSidebar();

  const showSavingIndicator = usePendingRequests({ mutations: STUDY_MUTATIONS });

  const teamsEnabled = useFeature('teams');

  const aiChatContext: AiChatContext | null = study
    ? { context_name: study.title, context_type: 'study', id: String(study.id) }
    : null;

  return (
    <div
      className={cn(
        'flex tablet:flex-row flex-col w-full px-6 py-2.5 items-center space-y-4 tablet:space-y-0 tablet:justify-between bg-white',
        { 'border-b border-gray-200': study.style === 'panel' || study.state === 'draft' }
      )}
    >
      <div className='mobile:flex-nowrap flex flex-wrap items-center self-center flex-1 w-full space-x-4 overflow-hidden text-sm truncate'>
        <button
          name='Toggle sidebar'
          aria-expanded={open}
          aria-label='Toggle sidebar'
          className='hover:text-gray-500 hover:underline text-gray-500'
          onClick={toggle}
        >
          <HamburgerSVG className='text-indigo-600' />
        </button>
        <span className='text-gray-500'>/</span>
        <Link className='hover:text-gray-500 hover:underline text-gray-500' to='/studies'>
          Studies
        </Link>
        <span className='text-gray-500'>/</span>
        <Text as='h2' h='600' truncate className='inline mr-2 leading-9' bold>
          {study.title}
        </Text>
      </div>
      <div className='tablet:flex tablet:items-end tablet:justify-between'>
        <div className='tablet:mt-0 tablet:ml-4 mobile:space-y-0 mobile:space-x-3 mobile:flex-row flex flex-col items-center flex-shrink-0 space-y-3'>
          {showSavingIndicator && (
            <div className='flex items-center space-x-2'>
              <CircleArrowsSVG className='animate-spin' />
              <span className='text-sm text-gray-500'>Saving...</span>
            </div>
          )}
          {study.state !== 'draft' && <AskAiButton pageContext={aiChatContext} />}
          <StudyUsers study={study} />
          {teamsEnabled && (
            <div className='ml-4'>
              <TeamPicker
                disabled={!canEdit}
                isLoading={isUpdating}
                value={study.team_id}
                onChange={(teamId) => {
                  track('updated_team', { draft: false });
                  updateStudy({ id: study.id, team_id: teamId });
                }}
              />
            </div>
          )}
          <div className='px-3 py-2.5 bg-white border border-gray-200 rounded-md'>
            <StudyStatus className='h400' status={study.state} />
          </div>
          <ApprovalButton approvalRequest={approvalRequest} study={study} />
          <OptionsDropdown study={study} setLoading={setLoading} />
        </div>
      </div>
    </div>
  );
};
