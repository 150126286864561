import classNames from 'classnames';
import { PageTitle } from 'components/common/helmets';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Heading, Text } from '@components/common';
import { DashboardHeader, DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { CandidatesSVG, CaretSVG, DocCreateSVG, ExternalLinkSVG, RepositorySVG } from '@components/svgs';
import { useUser } from '@hooks/useUser';

const LearnByDoingLink: React.FC<{
  href: string;
  title: string;
  text: string;
  svg: React.FC<{ className: string }>;
}> = ({ href, title, text, svg: Svg }) => (
  <Link to={href} title={title} className='group flex pb-6'>
    <div className='mt-1 mr-3'>
      <Svg className='text-gray-700' />
    </div>
    <div className='flex-1'>
      <div className='flex items-center mb-2'>
        <Text color='gray-700' className='group-hover:text-indigo-600 flex-1 text-gray-700'>
          {title}
        </Text>
        <CaretSVG className='text-indigo-600' />
      </div>
      <Text h='400' color='gray-500'>
        {text}
      </Text>
    </div>
  </Link>
);

export const GettingStarted: React.FC = () => {
  const user = useUser();
  const [idx, setIdx] = useState(0);
  const carousel = [
    {
      loom: 'https://www.loom.com/embed/ddc76614ca6b47ac9e8470ed03dcac0b',
      title: 'Spend less time on logistics',
      subtitle: 'Time is better spent on the research, not on coordination or project management.'
    },
    {
      loom: 'https://www.loom.com/embed/ed9ec980a1a4421e8d0689e65ad901e7',
      title: 'Create a great participant experience',
      subtitle: 'Put your customers at the center of your product, design & marketing decisions with Great Question.'
    },
    {
      loom: 'https://www.loom.com/embed/8a378c9bfe1141b1ba78fb1e35e6a078',
      title: 'Make research more visible',
      subtitle: 'Make your research more accessible for everyone on your team.'
    }
  ];
  const disablePrev = idx <= 0;
  const disableNext = idx >= carousel.length - 1;

  return (
    <DashboardLayout>
      <PageTitle>Getting Started</PageTitle>
      <DashboardHeader h1={`Welcome, ${user.first_name}`}>
        <Text>We’re so happy to have you a part of our community.</Text>
      </DashboardHeader>

      <DashboardLayoutBody>
        <div className='flex items-center mb-6'>
          <div className='flex-1'>
            <Heading>Get to know Great Question</Heading>
          </div>
          <a
            href='https://greatquestion.co/great/panel/form'
            className='flex items-center space-x-2'
            title='Great Question research panel'
            target='_blank'
          >
            Join our research panel
            <ExternalLinkSVG className='ml-2' />
          </a>
        </div>

        <div className='tablet:flex-row tablet:space-x-6 flex flex-col w-full'>
          <div className='flex-1 p-6 bg-white border border-gray-200'>
            <Text h='600' bold className='mb-6'>
              Explore the key benefits
            </Text>
            <div className='bg-gray-50 w-full h-48 mb-4 rounded-md'>
              <iframe
                title='exploring the key benefits video'
                // key={idx}
                className='w-full'
                // width="640"
                height='192'
                src={carousel[idx].loom}
                frameBorder='0'
                allowFullScreen
              />
            </div>
            <div className='mb-6 text-center'>
              <Text bold className='mb-1'>
                {carousel[idx].title}
              </Text>
              <Text h='400' color='gray-500'>
                {carousel[idx].subtitle}
              </Text>
            </div>
            <div className='flex items-center'>
              <button
                disabled={disablePrev}
                className={classNames(
                  'focus:outline-none disabled:text-gray-400 flex items-center justify-center w-6 h-6 text-gray-400 rounded-full',
                  { 'hover:bg-gray-50 text-indigo-600': !disablePrev }
                )}
                onClick={() => !disablePrev && setIdx(idx - 1)}
                name='previous'
              >
                <CaretSVG className='transform rotate-180' />
              </button>
              <div className='flex items-center justify-center flex-1 space-x-2'>
                {[0, 1, 2].map((i) => (
                  <div
                    key={i}
                    className={classNames('w-2 h-2 rounded-full', {
                      'bg-gray-200': idx !== i,
                      'bg-indigo-600': idx === i
                    })}
                  />
                ))}
              </div>
              <button
                disabled={disableNext}
                className={classNames(
                  'focus:outline-none disabled:text-gray-400 flex items-center justify-center w-6 h-6 text-gray-400 rounded-full',
                  { 'hover:bg-gray-50 text-indigo-600': !disableNext }
                )}
                onClick={() => !disableNext && setIdx(idx + 1)}
                name='next'
              >
                <CaretSVG />
              </button>
            </div>
          </div>

          <div className='flex-1 p-6 bg-white border border-gray-200'>
            <Text h='600' bold className='mb-6'>
              Dive into the details
            </Text>
            <div className='bg-gray-50 w-full h-48 mb-4 rounded-md'>
              <iframe
                title='dive into the details video'
                className='w-full'
                // width="640"
                height='192'
                src='https://www.loom.com/embed/ee3374c3883342a29fc32f7b4d8aa724'
                frameBorder='0'
                allowFullScreen
              />
            </div>
            <div className='text-center'>
              <Text bold className='mb-1'>
                Watch a full product demo
              </Text>
              <Text h='400' color='gray-500' className='mb-6'>
                1 minute video of our CEO walking through the full product experience.
              </Text>
              <a className='h400' href='/plans'>
                See plans &amp; pricing
              </a>
            </div>
          </div>

          <div className='flex-1 p-6 bg-white border border-gray-200'>
            <Text h='600' bold className='mb-6'>
              Learn by doing
            </Text>
            <LearnByDoingLink
              href='/candidates'
              title='Build your candidate panel'
              text='Grow your list of people who you can contact to participate in research.'
              svg={CandidatesSVG}
            />
            <hr className='pb-6 -mx-6' />
            <LearnByDoingLink
              href='/studies'
              title='Kickoff a research project'
              text='Schedule customer interviews, send a survey, and more.'
              svg={DocCreateSVG}
            />
            <hr className='pb-6 -mx-6' />
            <LearnByDoingLink
              href='/repository'
              title='Build your repository'
              text='Make past research and learnings easy to find.'
              svg={RepositorySVG}
            />
          </div>
        </div>
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
