import { Button, Modal, ModalHeading, Text } from '@components/common';
import React from 'react';

export const NoVideoHighlightsModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Modal
      icon='danger'
      onClose={onClose}
      renderFooter={() => (
        <Button onClick={onClose} primary>
          Got it
        </Button>
      )}
    >
      <ModalHeading className='mb-4'>No video highlights selected</ModalHeading>
      <section className='mt-2 mb-6'>
        <Text h='400'>You need to select video highlights in order to create a highlight reel.</Text>
      </section>
    </Modal>
  );
};
