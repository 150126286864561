const isObject = (item: unknown): item is Record<string, any> => {
  return !!item && typeof item === 'object' && !Array.isArray(item);
};

export const mergeDeep = <T extends object, S extends object>(target: T, source: S): T & S => {
  const output = { ...target } as T & S;

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      const sourceValue = source[key];
      const targetValue = target[key as keyof T];

      if (isObject(sourceValue) && isObject(targetValue)) {
        (output as any)[key] = mergeDeep(targetValue, sourceValue);
      } else {
        (output as any)[key] = sourceValue;
      }
    });
  }

  return output;
};
