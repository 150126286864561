import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { EditCustomerImportPage } from './EditCustomerImportPage';
import { NewCustomerImportPage } from './NewCustomerImportPage';

export const CustomerImportApp: React.VFC = () => (
  <Routes>
    <Route path=':id/edit' element={<EditCustomerImportPage />} />
    <Route path='new' element={<NewCustomerImportPage />} />
  </Routes>
);
