import * as React from 'react';
import { useEffect } from 'react';
import { api } from '@api/reduxApi';
import { usePermission } from '@hooks/usePermission';
import { useUser } from '@hooks/useUser';
import { useAccount } from '@hooks/useAccount';
import { useToaster } from '@stores/toaster';
import { track } from '@components/tracking';
import * as toasts from '@components/StudiesApp/components/StudyDraft/toasts';
import { Button, Loading, Modal, ModalHeading, ModalSubheading } from '@components/common';
import { TeamUserRow } from './TeamUserRow';
import { UserCombobox, UserComboboxItem } from 'components/shared/UserCombobox';

interface Props {
  study: Study;
  onClose: () => void;
}

export const StudyUsersModal: React.FC<Props> = ({ study, onClose }) => {
  const [
    updateStudy,
    { data: updatedStudy, isError: updateError, isLoading: updateLoading, isSuccess: updateSuccess }
  ] = api.useUpdateStudyMutation();
  const [
    deleteStudyUser,
    { data: studyWithNewUser, isError: deleteError, isLoading: deleteLoading, isSuccess: deleteSuccess }
  ] = api.useDeleteStudyUserMutation();
  const [
    createStudyUser,
    { data: studyWithRemovedUser, isError: createError, isLoading: createLoading, isSuccess: createSuccess }
  ] = api.useCreateStudyUserMutation();
  const canAdd = usePermission('addStudyUser')();

  const currentUser = useUser();

  const {
    account: { team }
  } = useAccount();

  const userById: Record<number, TeamUser> = team.reduce((memo, user) => {
    memo[user.id] = user;
    return memo;
  }, {});

  // it doesn't seem reliable. Is it possible that the user is not in the team?
  const members = study.user_ids.map((id) => userById[id]);

  const isMember = study.user_ids.includes(currentUser.id);

  const showToast = useToaster();

  useEffect(() => {
    track('opened_study_users_modal', { study_state: study.state, study_style: study.style });
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      showToast(toasts.successUpdate());
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      showToast(toasts.successUpdate());
    }
  }, [createSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      showToast(toasts.successUpdate());
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (updateError) {
      showToast(toasts.failedUpdate());
    }
  }, [updateError]);

  useEffect(() => {
    if (createError) {
      showToast(toasts.failedUpdate());
    }
  }, [createError]);

  useEffect(() => {
    if (deleteError) {
      showToast(toasts.failedUpdate());
    }
  }, [deleteError]);

  const addUsers = (items: UserComboboxItem[]) => {
    for (const item of items) {
      if (item.type === 'user') {
        addUser(item.user.id);
      }
    }
  };

  const addUser = (id: number) => {
    createStudyUser({ study_id: study.id, user_id: id });
  };

  const removeUser = (id: number) => {
    deleteStudyUser({ study_id: study.id, user_id: id });
  };

  const isLoading = updateLoading || createLoading || deleteLoading;

  const renderTeamMembers = () =>
    members.map((user) => {
      if (!user) return;

      const role = user.role || 'observer';

      return (
        <TeamUserRow
          key={user.id}
          user={user}
          study={study}
          updateStudy={updateStudy}
          onClickRemove={() => removeUser(user.id)}
          role={role}
        />
      );
    });

  return (
    <Modal onClose={onClose} size='md'>
      <ModalHeading className='mb-2'>Study collaborators</ModalHeading>
      <ModalSubheading className='mb-6'>{study.title}</ModalSubheading>
      <div>
        {isLoading && <Loading />}
        {canAdd && (
          <UserCombobox context={{ study_id: study.id }} placeholder='Enter name or email…' onSelect={addUsers} />
        )}
        <hr className='mt-4' />
        <p className='block mt-4 mb-4 font-bold'>Study members</p>
        <ul className='space-y-4 max-h-80 overflow-y-auto'>
          {renderTeamMembers()}
          {!isMember && (
            <TeamUserRow
              user={currentUser}
              study={study}
              updateStudy={updateStudy}
              onClickAdd={() => addUser(currentUser.id)}
              isMember={false}
            />
          )}
        </ul>
        <div className='flex justify-end mt-10'>
          <Button primary onClick={onClose}>
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};
