import React, { FC } from 'react';

import { Text } from '@components/common';
import { SUPPORT_EMAIL } from '@constants/emails';
import * as Sentry from '@sentry/react';
import { trackError } from './AppErroredPage';
import { AppErroredSVG } from './AppErroredSVG';
import { FallbackRender } from './providers';

interface Props {
  fallback?: FallbackRender;
}

const defaultFallback: FallbackRender = ({ error }) => {
  trackError(error);

  return (
    <div className='flex flex-col items-center'>
      <AppErroredSVG className='w-20 h-20' />
      <Text h='400' mb='2' className='text-center'>
        Something went wrong, please <a href={`mailto:${SUPPORT_EMAIL}`}>contact support.</a>
      </Text>
    </div>
  );
};

export const emptyFallback: FallbackRender = () => <></>;

export const ErrorBoundary: FC<Props> = ({ fallback = defaultFallback, children }) => {
  return <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>;
};
