export const STUDIES_COLUMN_LABELS = {
  title: 'Title',
  state: 'Status',
  style: 'Type',
  updated_at: 'Last updated',
  created_at: 'Created',
  options: '',
  owner_id: 'Owner',
  moderator_ids: 'Moderators',
  creator_id: 'Creator',
  completed: 'Completed'
};

export const STATIC_COLUMNS = ['title', 'options'];
