import { useEffect, useState } from 'react';
import { useUser } from './useUser';
import consumer from '@helpers/consumer';
import { api } from 'api/reduxApi';

const ConnectedAccountsChannel = (user_id: number) =>
  consumer.subscriptions.create(
    {
      channel: 'Users::ConnectedAccountsChannel',
      user_id: user_id
    },
    {}
  );

const ConnectedAccountCreatedChannel = (user_id: number) =>
  consumer.subscriptions.create(
    {
      channel: 'Users::ConnectedAccountCreatedChannel',
      user_id: user_id
    },
    {}
  );

export const useUserConnectedAccounts = () => {
  const { data, refetch, isUninitialized, isLoading } = api.useGetConnectedAccountsQuery();
  const [connectedAccounts, setConnectedAccounts] = useState<ConnectedAccount[]>([]);
  const user = useUser();

  useEffect(() => {
    if (data) {
      setConnectedAccounts(data);
    }
  }, [data]);

  useEffect(() => {
    const handleChannelUpdate = () => {
      if (!isUninitialized && !isLoading) {
        refetch();
      }
    };

    ConnectedAccountCreatedChannel(user.id).received = handleChannelUpdate;
    ConnectedAccountsChannel(user.id).received = handleChannelUpdate;

    return () => {
      ConnectedAccountCreatedChannel(user.id).received = undefined;
      ConnectedAccountsChannel(user.id).received = undefined;
    };
  }, [user.id, isUninitialized, isLoading, refetch]);

  return { connectedAccounts };
};
