import { SelectDropdown } from '@components/shared/SelectDropdown';
import React, { useState } from 'react';

interface Props {
  value: string | null;
  options?: { value: string; label: string }[];
  setEditMode: (value: boolean) => void;
  handleCellUpdate: (newValue: string | null) => void;
}

export const TextInput: React.FC<Props> = ({ value: initialValue, options, setEditMode, handleCellUpdate }: any) => {
  const [localValue, setLocalValue] = useState<string | null>(initialValue);

  const onCellUpdate = (newValue: string | null) => {
    setLocalValue(newValue);
    handleCellUpdate(newValue);
  };

  return (
    <SelectDropdown
      options={options ?? []}
      value={localValue ? [{ value: localValue, label: localValue }] : []}
      onChange={(newValue) => onCellUpdate(newValue[0].value)}
      overrides={{
        Input: {
          props: {
            className: 'w-full',
            placeholder: 'Search...',
            autoFocus: true
          }
        }
      }}
      onClose={(_, inputValue) => {
        if (inputValue === '') {
          onCellUpdate(null);
        }
        setEditMode(false);
      }}
      onCreate={({ value }) => {
        onCellUpdate(value);
        setEditMode(false);
      }}
      defaultIsOpen
      creatable
    />
  );
};
