import React, { SVGProps } from 'react';

export const PersonSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.9375 4.5C4.9375 5.31223 5.26016 6.09118 5.83449 6.66551C6.40882 7.23984 7.18777 7.5625 8 7.5625C8.81223 7.5625 9.59118 7.23984 10.1655 6.66551C10.7398 6.09118 11.0625 5.31223 11.0625 4.5C11.0625 3.68777 10.7398 2.90882 10.1655 2.33449C9.59118 1.76016 8.81223 1.4375 8 1.4375C7.18777 1.4375 6.40882 1.76016 5.83449 2.33449C5.26016 2.90882 4.9375 3.68777 4.9375 4.5V4.5Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.3125 14.5625C2.3125 13.0541 2.91172 11.6074 3.97833 10.5408C5.04494 9.47422 6.49158 8.875 8 8.875C9.50842 8.875 10.9551 9.47422 12.0217 10.5408C13.0883 11.6074 13.6875 13.0541 13.6875 14.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
