import React from 'react';

import { Loading, Text } from '@components/common';
import { DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { PageHeader } from '@components/shared/PageHeader';
import { Alert, AlertLink } from '@components/common';
import { useRecordsWithUser } from '@hooks/useRecordsWithUser';
import { useShowAiChatBubble } from '@stores/aiChat';

import { useGetAiChatsQuery } from '../../api';
import { ChatsTable } from './ChatsTable';

export const ChatsIndex = () => {
  useShowAiChatBubble();
  const { data: chats, isLoading, isSuccess } = useGetAiChatsQuery();

  const chatsWithUsers = useRecordsWithUser(chats || []);

  return (
    <DashboardLayout>
      <PageHeader h1='Ask AI History' hideDefaultButtons />
      <Alert className='m-6 mb-0' type='promo' heading='Ask AI is now available for video call studies and interviews'>
        <div>
          Click the <b>Ask AI</b> button in your study or interview to get started.
        </div>
      </Alert>
      <DashboardLayoutBody className='min-h-full'>
        {isLoading && <Loading absolute />}
        {isSuccess && <ChatsTable chats={chatsWithUsers} />}
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
