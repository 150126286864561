import * as React from 'react';

import { Provider as BulkUploadFlowProvider } from '@components/BulkUploadFlow';
import { Provider as TagManagerProvider } from '@components/TagManager';
import { Provider as AccountProvider } from '@stores/account';
import { Provider as AiChatProvider } from '@stores/aiChat';
import { Provider as CacheProvider } from '@stores/cache';
import { Provider as ConfigProvider } from '@stores/config';
import { Provider as PermissionsProvider } from '@stores/permissions';
import { Provider as TagsProvider } from '@stores/tags';
import { Provider as ToastProvider } from '@stores/toaster';
import { Provider as UserProvider } from '@stores/user';

export const Context: React.FC<{ whoami: Whoami }> = ({ whoami, children }) => (
  <AccountProvider value={{ account: whoami.account, plan: whoami.plan }}>
    <UserProvider initialState={{ user: whoami.user, auth_method: whoami.auth_method }}>
      <ConfigProvider initialState={{ config: whoami.config }}>
        <PermissionsProvider state={{ role: whoami.role }}>
          <TagsProvider>
            <ToastProvider>
              <TagManagerProvider>
                <CacheProvider>
                  <BulkUploadFlowProvider>
                    <AiChatProvider>{children}</AiChatProvider>
                  </BulkUploadFlowProvider>
                </CacheProvider>
              </TagManagerProvider>
            </ToastProvider>
          </TagsProvider>
        </PermissionsProvider>
      </ConfigProvider>
    </UserProvider>
  </AccountProvider>
);
