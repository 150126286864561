import { MouseEvent, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { baseArtifactsColumnDefs } from '../helpers/baseArtifactsColumnDefs';
import { activityArtifactsColumnDefs } from '../helpers/activityArtifactsColumnsDefs';
import { useRenderTagsList } from '@components/RepositoryApp/hooks/useRenderTagsList';
import { useRenderAvatars } from '@components/RepositoryApp/hooks/useRenderAvatars';
import { ArtifactAttributes } from '@components/RepositoryApp/types';
import { AlgoliaHit } from '@components/shared/Search';

interface Props {
  onDelete?: (h: Pick<ArtifactAttributes, 'href' | 'title' | 'kind' | 'model_id'>) => void;
  onCopyLink: (e: MouseEvent, href: string) => void;
  getColumnLabel: (key: string) => string;
}

export const useArtifactsColumnDefinitions = ({
  onDelete,
  onCopyLink,
  getColumnLabel
}: Props): ColumnDef<AlgoliaHit<ArtifactAttributes>>[] => {
  const { renderTagsList } = useRenderTagsList();
  const { renderAvatars } = useRenderAvatars();

  const onDeleteArtifact = (e: MouseEvent, { href, title, kind, model_id }: ArtifactAttributes) => {
    e.stopPropagation();
    onDelete?.({ href, title, kind, model_id });
  };

  const definitions = useMemo<ColumnDef<AlgoliaHit<ArtifactAttributes>>[]>(() => {
    return [
      ...baseArtifactsColumnDefs({
        getColumnLabel,
        onDeleteArtifact,
        onCopyLink,
        renderAvatars,
        renderTagsList
      }),
      ...activityArtifactsColumnDefs({ getColumnLabel })
    ];
  }, [getColumnLabel, renderAvatars, renderTagsList]);

  return definitions;
};
