export const COLUMN_LABELS = {
  name: 'Name',
  shortlisted_at: 'Shortlisted at',
  requested_at: 'Invited at',
  invited_at: 'Sent screener at',
  applied_at: 'Applied at',
  booked_at: 'Booked at',
  interview_at: 'Interview at',
  updated_at: 'Updated at',
  started_at: 'Started at',
  completed_at: 'Completed at',
  rejected_at: 'Removed at',
  no_show_at: 'Updated at',
  opted_out_at: 'Opted out at',
  canceled_at: 'Canceled at',
  participations_count: '# of studies',
  current_year_usd_amount_in_cents: 'Incentives Paid (Current Year)',
  all_time_usd_amount_in_cents: 'Incentives Paid (All Time)',
  invited_participations_count: '# of invited studies',
  responded_participations_count: '# of responded studies',
  completed_participations_count: '# of completed studies',
  average_rating: 'Average rating',
  contact_access: 'Contact Access',
  timezone: 'Timezone',
  last_contacted_at: 'Last Contacted At',
  email: 'Email',
  phone_number: 'Phone number',
  status: 'Status',
  opted_in: 'Opt-in',
  consented_at: 'Opt-in date',
  incentive_in_whole_currency: 'Incentive',
  'preScreenerField.ideal_response': 'Ideal?',
  'pre_screener_response:match_score': '# of ideal answers',
  token: 'Token',
  rating: 'Participant rating',
  recruiting_source: 'Source',
  moderator: 'Moderator',
  join_url: 'Call link',
  interview_path: 'Interview',
  study_path: 'Study',
  eligible: 'Eligible',
  limit: 'Segment'
};

export const EDITABLE_COLUMNS = [
  'rating',
  'opted_in',
  'email',
  'phone_number',
  'moderator',
  'incentive_in_whole_currency'
];

export const STATIC_COLUMNS = ['id', 'name'];
