import React, { useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import DatePicker from 'react-datepicker';

import { ClickOutside } from '@components/ClickOutside';
import { Button, Modal, Text, Textarea } from '@components/common';

import { parseCellValue, sanitizeValue } from '../helpers';
import { useTableContext } from '../hooks';
import * as Icons from '../icons';
import { CellProps } from '../types';
import { TextInput } from '@components/shared/Table/components/TextInput';
import { MultiselectInput } from '@components/shared/Table/components/MultiselectInput';

export const Cell = <D extends Record<string, any>, O extends string = string>(props: CellProps<D, O>) => {
  const { type = 'text', columnName, rowName, table, row, column, cell, getValue, options = [], render } = props;
  const { editableColumns = [], errors } = useTableContext<D>();

  const initialValue = getValue();
  const isEditable = editableColumns.includes(column.id);
  const hasError = !!errors.find(({ rowIndex, columnId }) => rowIndex === row.index && columnId === column.id);

  const [value, setValue] = useState(initialValue);
  const [editMode, setEditMode] = useState<boolean>(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const itemsToChoose = options.map((v) => ({ value: v, label: v }));

  const handleOnBlur = () => {
    if (value === initialValue) {
      setEditMode(false);
      return;
    }

    setEditMode(false);
    table.options.meta?.updateCellData?.({ row, columnId: column.id, value });
  };

  const handleCellUpdate = (newValue: any) => {
    setEditMode(false);
    setValue(newValue);
    table.options.meta?.updateCellData?.({ row, columnId: column.id, value: newValue });
  };

  const handleOnClickOutside = () => {
    const hasBlurHandlers =
      (type === 'text' && itemsToChoose.length === 0) ||
      type === 'number' ||
      type === 'url' ||
      type === 'multiple_choice';

    if (type !== 'free_text' && !hasBlurHandlers) {
      setEditMode(false);
    }
  };

  const renderSetValue = (newValue: any) => {
    setValue(newValue);
    table.options.meta?.updateCellData?.({ row, columnId: column.id, value: newValue });
  };

  const getParsedValue = useCallback<() => string>(() => parseCellValue(value, type), [value, type]);

  const renderEdit = useCallback(() => {
    switch (type) {
      case 'text':
        return itemsToChoose.length ? (
          <TextInput
            value={value}
            setEditMode={setEditMode}
            options={itemsToChoose}
            handleCellUpdate={handleCellUpdate}
          />
        ) : (
          <input
            type={type}
            value={value ?? ''}
            className='h400 w-full'
            onBlur={handleOnBlur}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleCellUpdate(value)}
            placeholder='Enter…'
            autoFocus
          />
        );
      case 'number':
      case 'url':
        return (
          <input
            type={type}
            value={value ?? ''}
            className='h400 w-full'
            onBlur={handleOnBlur}
            onChange={(e) => setValue(type === 'number' ? Number(e.target.value) : e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleCellUpdate(value)}
            placeholder='Enter…'
            autoFocus
          />
        );
      case 'datetime':
        return (
          <DatePicker
            onChange={handleCellUpdate}
            value={value ? getParsedValue() : ''}
            onBlur={handleOnBlur}
            className='h400 w-full'
            placeholderText='Enter…'
            selected={sanitizeValue(value, type)}
            autoFocus
            showYearDropdown
          />
        );
      case 'multiple_choice':
        return (
          <MultiselectInput
            value={value}
            setEditMode={setEditMode}
            options={itemsToChoose}
            handleCellUpdate={handleCellUpdate}
          />
        );
      case 'boolean':
        return (
          <div className='flex space-x-2 px-2'>
            <label className='flex items-center space-x-1 cursor-pointer'>
              <input type='radio' value='0' checked={!!value} onChange={() => handleCellUpdate(true)} />
              <Text className='text-sm' as='span'>
                Yes
              </Text>
            </label>
            <label className='flex items-center space-x-1 cursor-pointer'>
              <input
                type='radio'
                value='1'
                checked={typeof value !== 'undefined' && value !== null && !value}
                onChange={() => handleCellUpdate(false)}
              />
              <Text className='text-sm' as='span'>
                No
              </Text>
            </label>
            <button className='hover:underline text-xs text-gray-500' onClick={() => handleCellUpdate(null)}>
              Clear
            </button>
          </div>
        );
      case 'free_text':
        return (
          <Modal
            title={`Edit ${columnName || cell.column.id} for ${rowName || row.original.name}`}
            size='md'
            onClose={() => setEditMode(false)}
            renderFooter={() => (
              <>
                <Button onClick={() => setEditMode(false)}>Cancel</Button>
                <Button primary onClick={() => handleCellUpdate(textareaRef.current?.value)}>
                  Save
                </Button>
              </>
            )}
          >
            <Textarea defaultValue={value} ref={textareaRef} />
          </Modal>
        );
      default:
        return null;
    }
  }, [value, type]);

  const renderValue = useCallback(() => {
    if (render)
      return render({ type, options, table, row, column, cell, value, getParsedValue, setValue: renderSetValue });

    if (value == null || value === '') return '-';

    switch (type) {
      case 'datetime':
      case 'multiple_choice':
        return (
          <Text h='400' truncate>
            {getParsedValue()}
          </Text>
        );
      case 'boolean':
        return value ? (
          <Icons.Check role='img' className='text-green-600' />
        ) : (
          <Icons.Close role='img' className='text-red-600' />
        );
      default:
        return (
          <Text h='400' truncate>
            {value}
          </Text>
        );
    }
  }, [value, type, getParsedValue]);

  return (
    <div
      className={cn('h400 w-full h-11 flex bg-white border-b border-gray-200 items-center', {
        'text-red-600': hasError,
        'cursor-pointer hover:bg-indigo-50': isEditable && !editMode
      })}
      onClick={() => !editMode && !render && setEditMode(isEditable)}
    >
      {render ? (
        renderValue()
      ) : (
        <>
          {isEditable && editMode ? (
            <ClickOutside className='w-full' onClick={handleOnClickOutside}>
              {renderEdit()}
            </ClickOutside>
          ) : (
            <div className='w-full px-2 truncate'>{renderValue()}</div>
          )}
        </>
      )}
    </div>
  );
};
