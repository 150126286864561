import { ExportCsvButtonServerSide } from 'components/common/ExportCsvButtonServerSide';
import { ExportCsvModal } from 'components/shared/ExportCsvModal';
import { useFeature } from 'hooks/useFeature';
import React, { useMemo, useState } from 'react';

import { Button, Select, Text } from '@components/common';
import { Grid } from '@components/common/Grid';
import { DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { PageHeader } from '@components/shared/PageHeader';
import { usePermission } from '@hooks/usePermission';
import { usePlan } from '@hooks/usePlan';

import { Metric } from './components';
import { GROUP_PERIOD_OPTIONS, METRIC_DEFS, RANGE_OPTIONS } from './constants';

function startsAtStringFromMonths(months: number): string {
  const from = new Date();
  from.setMonth(from.getMonth() - months);
  return from.toISOString();
}

export const ReportingApp: React.FC = () => {
  const [months, setMonths] = useState(6);
  const [groupPeriod, setGroupPeriod] = useState<'month' | 'week'>('month');
  const starts_at = useMemo(() => startsAtStringFromMonths(months), [months]);
  const [csvExportModalOpen, setCsvExportModalOpen] = useState<boolean>(false);

  const isAdmin = usePermission('isAdmin')();

  const { hasFeature } = usePlan();
  const hasExport = useFeature('studies_csv_export');

  if (!hasFeature('reporting') || !isAdmin) return null;

  return (
    <DashboardLayout>
      <PageHeader
        h1='Reporting'
        hideDefaultButtons
        renderBulkActions={() => (
          <>
            {hasExport && (
              <ExportCsvButtonServerSide subjectString='study performance data' params={{ kind: 'studies' }} />
            )}
            <Select<'week' | 'month'>
              options={GROUP_PERIOD_OPTIONS}
              value={groupPeriod}
              onChange={(v) => setGroupPeriod(v)}
            />
            <Select options={RANGE_OPTIONS} value={months.toString()} onChange={(v) => setMonths(parseInt(v))} />
          </>
        )}
      />
      <DashboardLayoutBody className='min-h-full'>
        {csvExportModalOpen && (
          <ExportCsvModal
            subjectString='study performance data'
            params={{ kind: 'studies' }}
            setOpen={setCsvExportModalOpen}
          />
        )}
        <Text as='h2' bold h='600' className='my-4'>
          Topline
        </Text>
        <Grid mobile={2} tablet={2} desktop={4} monitor={4} gap={4}>
          {METRIC_DEFS.slice(0, 4).map((metric) => (
            <Metric
              {...metric}
              starts_at={starts_at}
              period={groupPeriod}
              key={`${metric.id}-${metric.type}-${months}`}
            />
          ))}
        </Grid>
        <Text as='h2' bold h='600' className='my-4'>
          Usage
        </Text>
        <Grid mobile={1} tablet={1} desktop={2} monitor={2} gap={4}>
          {METRIC_DEFS.slice(4, 8).map((metric) => (
            <Metric {...metric} starts_at={starts_at} key={`${metric.id}-${months}`} period={groupPeriod} />
          ))}
        </Grid>
        <Text as='h2' bold h='600' className='mt-8 mb-4'>
          Recruitment duration
        </Text>
        <Grid mobile={1} tablet={1} desktop={2} monitor={2} gap={4}>
          {METRIC_DEFS.slice(8, 10).map((metric) => (
            <Metric {...metric} starts_at={starts_at} key={`${metric.id}-${months}`} period={groupPeriod} />
          ))}
        </Grid>
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
