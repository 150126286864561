import { api } from 'api/reduxApi';

type Hook = {
  studyLimits: StudyLimit[];
  studyLimitMatches: Record<number, number>;
};

type useStudyLimits = (study: Study | undefined, candidateParams: IdsOrFilterQuery) => Hook;

export const useStudyLimits: useStudyLimits = (study, candidateParams) => {
  const { data: studyLimits = [] } = api.useGetStudyLimitsQuery(study?.id as number, {
    skip: !study
  });

  const { data: studyLimitMatches = {} } = api.useGetStudyLimitMatchesQuery(
    {
      studyId: study?.id as number,
      candidateIds: candidateParams.ids,
      query: candidateParams.query
    },
    {
      skip: !study || !studyLimits?.length || (!candidateParams.ids?.length && !candidateParams.query)
    }
  );

  return {
    studyLimits,
    studyLimitMatches
  };
};
