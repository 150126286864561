import React from 'react';

import { Table } from '@components/shared/Table';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { AlgoliaHit } from '@components/shared/Search';
import { ArtifactAttributes } from '@components/RepositoryApp/types';
import { ArtifactKind } from '@components/RepositoryApp/hits/artifacts/ArtifactKind';
import { formatSeconds } from '@components/VideoPlayer';
import Tippy from '@tippyjs/react';
import { FullPageViewSVG, LinkSVG, SolidKebabSVG, TrashSVG } from '@components/svgs';
import { Menu } from '@components/common/Menu';
import { ArtifactAction } from '@components/shared/Artifact';
import { DELETE_LABEL_MAP } from '../constants';
import { useRenderAvatars } from '@components/RepositoryApp/hooks/useRenderAvatars';
import { useRenderTagsList } from '@components/RepositoryApp/hooks/useRenderTagsList';

export interface DefinitionFnArgs {
  onCopyLink: (e: React.MouseEvent, href: string) => void;
  renderTagsList: ReturnType<typeof useRenderTagsList>['renderTagsList'];
  onDeleteArtifact: (e: React.MouseEvent, artifact: ArtifactAttributes) => void;
  renderAvatars: ReturnType<typeof useRenderAvatars>['renderAvatars'];
  getColumnLabel: (key: string) => string;
}

export type DefinitionFnReturn = ColumnDef<AlgoliaHit<ArtifactAttributes>>[];

export type DefinitionFn = (args: DefinitionFnArgs) => DefinitionFnReturn;

export const baseArtifactsColumnDefs: DefinitionFn = ({
  onCopyLink,
  renderTagsList,
  renderAvatars,
  onDeleteArtifact,
  getColumnLabel
}) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'model_id',
      size: 48,
      cell: ({ row }) => (
        <Table.Checkbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
      header: ({ table }) => (
        <Table.Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      )
    },
    {
      id: 'title',
      accessorKey: 'title',
      cell: (props) => (
        <Table.Cell<AlgoliaHit<ArtifactAttributes>>
          {...props}
          render={({ row }) => (
            <Link className='truncate px-2' to={row.original.href}>
              {row.original.title}
            </Link>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort disableHide>
          {getColumnLabel('title')}
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<AlgoliaHit<ArtifactAttributes>>
          {...props}
          render={({ row }) => <ArtifactKind kind={row.original.kind} />}
        />
      ),
      id: 'kind',
      accessorKey: 'kind',
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          {getColumnLabel('kind')}
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => <Table.Cell<AlgoliaHit<ArtifactAttributes>> {...props} type='text' />,
      id: 'subtitle',
      accessorKey: 'subtitle',
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          {getColumnLabel('subtitle')}
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<AlgoliaHit<ArtifactAttributes>>
          {...props}
          render={({ row }) => (
            <span className='px-2 truncate'>
              {row.original.tag_ids?.length ? renderTagsList(row.original.tag_ids) : '-'}
            </span>
          )}
        />
      ),
      id: 'tag_ids',
      accessorKey: 'tag_ids',
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          {getColumnLabel('tag_ids')}
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<AlgoliaHit<ArtifactAttributes>>
          {...props}
          render={({ row }) => (
            <span className='px-2 truncate'>
              {row.original.study_user_ids?.length ? renderAvatars([row.original.study_user_ids[0]]) : '-'}
            </span>
          )}
        />
      ),
      id: 'study_user_ids',
      accessorKey: 'study_user_ids',
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          {getColumnLabel('study_user_ids')}
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<AlgoliaHit<ArtifactAttributes>>
          {...props}
          render={({ row }) => (
            <span className='px-2 truncate'>
              {row.original.study_user_ids.slice(1).length ? renderAvatars(row.original.study_user_ids.slice(1)) : '-'}
            </span>
          )}
        />
      ),
      accessorKey: 'study_user_ids',
      id: 'collaborators',
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          {getColumnLabel('collaborators')}
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<AlgoliaHit<ArtifactAttributes>>
          {...props}
          render={({ row }) => (
            <span className='px-2 truncate'>{typeof row.original.views === 'number' ? row.original.views : '-'}</span>
          )}
        />
      ),
      id: 'views',
      accessorKey: 'views',
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          {getColumnLabel('views')}
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<AlgoliaHit<ArtifactAttributes>>
          {...props}
          render={({ row }) => (
            <span className='px-2 truncate'>{row.original.clips ? row.original.clips.length : '-'}</span>
          )}
        />
      ),
      id: 'clips',
      accessorKey: 'clips',
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          {getColumnLabel('clips')}
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<AlgoliaHit<ArtifactAttributes>>
          {...props}
          render={({ row }) => (
            <span className='px-2 truncate'>
              {typeof row.original.duration === 'number' ? formatSeconds(row.original.duration) : '-'}
            </span>
          )}
        />
      ),
      id: 'duration',
      accessorKey: 'duration',
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          {getColumnLabel('duration')}
        </Table.ColumnHeader>
      )
    },
    {
      cell: ({ row }) => (
        <div className='h400 h-11 flex items-center px-2 bg-white border-b border-gray-200'>
          <Tippy content='Copy link' arrow={false}>
            <button
              className='hover:text-indigo-600 focus:outline-none mr-2'
              onClick={(e) => onCopyLink(e, row.original.href)}
            >
              <LinkSVG />
            </button>
          </Tippy>
          <Menu
            popperProps={{ zIndex: 50 }}
            className='overflow-hidden bg-white border border-b-0 border-gray-200 rounded z-50'
            renderTrigger={() => (
              <div>
                <Tippy content='More...' arrow={false}>
                  <button className='hover:text-indigo-600 focus:outline-none'>
                    <SolidKebabSVG className='w-4 h-4' />
                  </button>
                </Tippy>
              </div>
            )}
          >
            <ArtifactAction href={row.original.href} icon={<FullPageViewSVG />} targetBlank>
              View on full page
            </ArtifactAction>
            {['Story', 'Clip', 'Highlight', 'HighlightReel'].includes(row.original.kind) && (
              <ArtifactAction onClick={(e) => onDeleteArtifact(e, row.original)} icon={<TrashSVG />} remove>
                {DELETE_LABEL_MAP[row.original.kind]}
              </ArtifactAction>
            )}
          </Menu>
        </div>
      ),
      id: 'actions',
      size: 64,
      maxSize: 64,
      header: (props) => <Table.ColumnHeader {...props} isStatic />
    }
  ];

  return columns;
};
