import React, { VFC } from 'react';

import { ParticipationPill } from '@components/shared/ParticipationPill';
import { Tooltip } from '@components/shared/Tooltip';
import { CalendarWithClockSVG, ClockRefreshSVG, WaitlistedSVG } from '@components/svgs';

interface Props {
  participation: Participation;
  onClickProposedTime: () => void;
}

export const StatusCell: VFC<Props> = ({ participation, onClickProposedTime }) => {
  return (
    <div className='space-x-2 px-2 flex items-center w-full h-full'>
      <ParticipationPill participation={participation} />

      {participation.waitlisted_at && (
        <Tooltip content='On waitlist'>
          <span>
            <WaitlistedSVG className='text-yellow-600' aria-label='On waitlist' />
          </span>
        </Tooltip>
      )}

      {participation.reschedule_requested_at && (
        <Tooltip content='Sent reschedule request'>
          <span>
            <ClockRefreshSVG className='text-gray-500' />
          </span>
        </Tooltip>
      )}

      {participation.time_proposal && (
        <Tooltip content='Proposed alternative times'>
          <button
            className='hover:bg-gray-100 flex items-center justify-center w-6 h-6 rounded-full'
            aria-label='Review time proposal'
            name='Review time proposal'
            onClick={onClickProposedTime}
          >
            <CalendarWithClockSVG className='text-red-600' />
          </button>
        </Tooltip>
      )}
    </div>
  );
};
