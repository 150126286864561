import { Button, Modal, ModalHeading, Text } from '@components/common';
import React from 'react';

export const NotOnlyVideoHighlightsModal = ({ onClose }) => {
  return (
    <Modal
      onClose={onClose}
      renderFooter={() => (
        <Button onClick={onClose} primary>
          Got it
        </Button>
      )}
    >
      <ModalHeading className='mb-4'>
        Heads up
        <Text h='400' className='mt-1 text-gray-500'>
          Not all your selections will be added to reel.
        </Text>
      </ModalHeading>
      <section className='mt-4 mb-6'>
        <Text h='400'>
          You've selected some items that aren't video highlights. Only video highlights will be used in the highlight
          reel.
        </Text>
      </section>
    </Modal>
  );
};
