import { Alert } from 'components/common';
import React from 'react';
import { overDailyLimit } from '../utils';

interface Props {
  dailyInviteLimit: DailyInviteLimit;
  count: number;
}

export const DailyInviteLimitAlert: React.FC<Props> = ({ dailyInviteLimit, count }) => {
  const additionMessage = dailyInviteLimit.current_count === 0
    ? ''
    : ` in addition to the ${dailyInviteLimit.current_count} candidates you've already invited`;

  if (overDailyLimit(dailyInviteLimit, count)) {
    return (
        <Alert type='error' heading='Daily invite limit reached'>
          You've selected to invite {count} candidates{additionMessage}, which is greater than your {dailyInviteLimit.limit} invite limit. Select fewer candidates to invite or
          wait until tomorrow to send more invites.
        </Alert>
    );
  }

  return <></>;
};
