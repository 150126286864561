import * as React from 'react';
import { useState } from 'react';

import { bulkParticipationsAction, sendStudyMessage } from '@api/queries';
import { Loading, Text } from '@components/common';
import { Toast, useToaster } from '@components/stores/toaster';

import { StudyMessageSlideOut } from './StudyMessageSlideOut';
import { useFeature } from '@hooks/useFeature';

interface Props {
  study: Study;
  participations: Participation[];
  onSuccess?: () => void;
  onClose: () => void;
  allSelected?: boolean;
  query?: ServerFilterQuery;
}

const toasts: { [key: string]: Toast } = {
  sent: {
    heading: 'Cancel emails sent!',
    text: `All participants were canceled and emailed.`,
    icon: 'success'
  },
  canceled: {
    heading: `Successfully canceled!`,
    text: 'All participants were marked as canceled.',
    icon: 'success'
  }
};

export const CancelTaskSlideOut: React.FC<Props> = ({
  allSelected,
  query,
  study,
  participations,
  onSuccess,
  onClose
}) => {
  const showToast = useToaster();
  const serversideParticipantsEnabled = useFeature('serverside_participations');
  const totalCount = serversideParticipantsEnabled && allSelected ? query?.count || 0 : participations.length;

  const [loading, setLoading] = useState<boolean>(false);
  const [action, setAction] = useState<'cancel' | 'mark_invited'>('cancel');

  async function handleSend({ message, send, sender }) {
    setLoading(true);

    const selectedParticipationsIds = participations.map((p) => p.id);

    await bulkParticipationsAction(study.id, {
      action,
      ...(allSelected && serversideParticipantsEnabled ? { query } : { ids: selectedParticipationsIds }),
      fields: []
    });

    if (send) {
      await sendStudyMessage(
        { id: message.id, studyId: study.id },
        {
          ...(allSelected && serversideParticipantsEnabled
            ? { query }
            : { participation_ids: selectedParticipationsIds }),
          sender
        }
      );
      setLoading(false);
      onSuccess?.();
      showToast(toasts.sent);
    } else {
      setLoading(false);
      onSuccess?.();
      showToast(toasts.canceled);
    }
  }

  const title = 'Mark as canceled';
  const cta = 'Confirm & send';

  const [send, setSend] = useState(true);

  return (
    <StudyMessageSlideOut
      optional={{ send, setSend }}
      customizable
      title={title}
      cta={cta}
      study={study}
      event='cancel_task'
      previewCandidate={participations[0]?.customer}
      previewParticipation={participations[0]}
      totalCount={totalCount}
      inviteableCount={totalCount}
      contactableCount={totalCount}
      onClose={onClose}
      onSend={handleSend}
    >
      <>{loading && <Loading absolute />}</>
      <div className='mb-6'>
        <div className='h500-bold mb-2'>Cancellation type</div>

        <div className='flex mb-2'>
          <input
            id='action_mark_invited'
            className='mr-2'
            type='radio'
            name='action'
            checked={action === 'mark_invited'}
            onChange={() => setAction('mark_invited')}
          />
          <label htmlFor='action_mark_invited'>
            <Text h='400'>Cancel only</Text>
            <Text h='400' color='gray-500'>
              The slot will be re-opened, and canceled participants will still have access to complete it.
            </Text>
          </label>
        </div>

        <div className='flex mb-2'>
          <input
            id='action_cancel'
            className='mr-2'
            type='radio'
            name='action'
            checked={action === 'cancel'}
            onChange={() => setAction('cancel')}
          />
          <label htmlFor='action_cancel'>
            <Text h='400'>Cancel and withdraw invitation</Text>
            <Text h='400' color='gray-500'>
              The slot will be re-opened, but canceled participants won’t have access to complete it.
            </Text>
          </label>
        </div>
      </div>
    </StudyMessageSlideOut>
  );
};
