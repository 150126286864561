import { Table } from 'components/shared/Table';
import React, { useMemo } from 'react';
import { Text, Toggle, Tooltip } from '@components/common';
import { capitalize, noop } from 'components/utils';

interface Props {
  answers: ScreenerResponseAnswerValue<'card_sort'>[];
  categories: string[];
}

export const AgreementMatrix: React.FC<Props> = ({ answers, categories }) => {
  const columns = useMemo(
    () => [
      {
        id: 'card',
        accessorKey: 'card',
        cell: (props) => (
          <Text h='400' className='flex items-center justify-center bg-white p-2 h-11 truncate'>
            {props.getValue()}
          </Text>
        ),
        header: () => <div className='bg-white h-11' />
      },
      ...categories.map((category) => ({
        id: category,
        accessorKey: category,
        cell: (props) => (
          <Text
            style={{
              width: '145px',
              backgroundColor: props.getValue() ? `rgba(104, 117, 245, ${props.getValue()})` : '#E5E7EB',
              color: props.getValue() > 0.5 ? 'white' : '#374151'
            }}
            h='600'
            className='flex items-center justify-center m-1.5 rounded-md p-2 h-11'
          >
            {props.getValue() || ''}
          </Text>
        ),
        header: () => (
          <Text h='400' className='p-2 h-11'>
            {capitalize(category)}
          </Text>
        )
      }))
    ],
    []
  );

  return (
    <div className='w-full'>
      <div className='mb-4 flex items-center'>
        <div className='flex items-center flex-1'>
          <Text bold className='mr-1'>
            Agreement matrix
          </Text>
          <Tooltip>content</Tooltip>
        </div>
        <div className='flex items-center mr-2'>
          <Toggle onToggle={noop} />
          <div className='mr-1 ml-2'>Show merged categories</div>
          <Tooltip>content</Tooltip>
        </div>
        <div className='flex items-center'>
          <Toggle onToggle={noop} />
          <div className='mr-1 ml-2'>Show merged categories</div>
          <Tooltip>content</Tooltip>
        </div>
      </div>

      <div className='relative max-w-full overflow-auto'>
        <Table columnPinning={{ left: ['card'] }} data={[]} columns={columns} />
      </div>
    </div>
  );
};
