import { format as timeagoFormat } from 'timeago.js';

import { getStudyLabel } from '@api/utils';
import { currencyToSymbol } from '@components/utils';

type Builder<R> = (json: any) => R;

type Serializer<D extends Record<string, any>> = { attributes: D };

function dateIf(date): Date | null {
  return date ? new Date(date) : null;
}

export const buildObservation: Builder<Observation> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at)
});

export const buildApprovalRequest: Builder<ApprovalRequest> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  approved_at: dateIf(attributes.approved_at)
});

export const buildIncentive: Builder<Incentive> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at),
  redeemed_at: dateIf(attributes.redeemed_at),
  sent_at: dateIf(attributes.sent_at),
  resent_at: dateIf(attributes.resent_at),
  used_at: dateIf(attributes.used_at)
});

export const buildConsentForm: Builder<any> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at)
});

export const buildCalendarEvent: Builder<any> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at),
  discarded_at: dateIf(attributes.discarded_at),
  start_at: dateIf(attributes.start_at),
  end_at: dateIf(attributes.end_at),
  last_synced_at: dateIf(attributes.last_synced_at)
});

export const buildGuide: Builder<any> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at),
  published_at: dateIf(attributes.created_at)
});

export const buildInterviewTemplate: Builder<any> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at)
});

export const buildEmailTemplate: Builder<any> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at)
});

export const buildActivity: Builder<PublicActivity> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at)
});

export const buildRecord: Builder<any> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at)
});

export const buildWhoami: Builder<Whoami> = (data) => {
  return {
    user: { ...data.user, created_at: new Date(data.user.created_at) } as User,
    auth_method: data.auth_method,
    account: data.account as GQAccount,
    role: data.role,
    config: data.config as GQConfig,
    plan: data.plan as WhoamiPlan,
    isMasquerading: data.isMasquerading,
    account_public_domain: data.account_public_domain,
    localCandidateDbKey: '123'
  };
};

// Does this created_at break somewhere else?
export const buildCustomer: Builder<Candidate> = ({ attributes }) => {
  const data = {
    ...attributes,
    // created_at: new Date(attributes.created_at),
    // updated_at: new Date(attributes.updated_at),
    // unsubscribed_at: dateIf(attributes.unsubscribed_at),
    last_contacted_at: attributes.last_contacted_at ? new Date(attributes.last_contacted_at).getTime() : undefined,
    last_contacted_at_str: attributes.last_contacted_at
      ? timeagoFormat(new Date(attributes.last_contacted_at))
      : undefined,
    experience: attributes.experience || '',
    location: attributes.location || ''
  };

  if (attributes.health_status) {
    data.health_status = Object.entries(attributes.health_status)
      .filter(([_, v]) => v === true)
      .map(([k]) => k);
  }
  return data;
};

export const buildSimpleCustomer: Builder<Partial<Candidate>> = ({ attributes }) => {
  return {
    ...attributes,
    created_at: new Date(attributes.created_at),
    updated_at: new Date(attributes.updated_at)
  };
};

export const buildParticipation: Builder<Participation> = ({ attributes }) => {
  const data = {
    ...attributes,
    created_at: dateIf(attributes.created_at),
    updated_at: dateIf(attributes.updated_at),
    interview_at: dateIf(attributes.interview_at),
    completed_at: dateIf(attributes.completed_at),
    requested_at: dateIf(attributes.requested_at),
    applied_at: dateIf(attributes.applied_at),
    shortlisted_at: dateIf(attributes.shortlisted_at),
    no_show_at: dateIf(attributes.no_show_at),
    opted_out_at: dateIf(attributes.opted_out_at),
    started_at: dateIf(attributes.started_at),
    rejected_at: dateIf(attributes.rejected_at),
    thanked_at: dateIf(attributes.thanked_at),
    reschedule_requested_at: dateIf(attributes.reschedule_requested_at),
    last_activity_at: dateIf(attributes.last_activity_at),
    study_path: '/studies/' + attributes.project_id + '/participants#candidates/' + attributes.customer_id,
    study: attributes.study && buildProject({ attributes: attributes.study }),
    waitlisted_at: dateIf(attributes.waitlisted_at)
  };
  if (attributes.customer) {
    data.customer = buildCustomer(attributes.customer);
  }
  // if (attributes.other_participations) {
  //   data.other_participations = attributes.other_participations.map((attributes) => buildParticipation({ attributes }));
  // }
  if (attributes.time_proposal) {
    data.time_proposal = attributes.time_proposal.attributes;
  }
  if (attributes.incentive) {
    data.incentive = attributes.incentive.attributes;
  }
  if (attributes.activities) {
    data.activities = attributes.activities.map(buildActivity);
  }
  if (attributes.event_details) {
    data.join_url = ((attributes.event_details || {}).location || {}).join_url;
  }
  return data;
};

export const buildSimpleParticipation: Builder<Participation> = ({ attributes }) => {
  const data = {
    ...attributes,
    updated_at: dateIf(attributes.updated_at)
  };
  return data;
};

export const buildProject: Builder<Study> = ({ attributes }) => {
  const data = {
    ...attributes,
    label: getStudyLabel(attributes),
    created_at: dateIf(attributes.created_at),
    updated_at: dateIf(attributes.updated_at),
    next_batch_at: dateIf(attributes.next_batch_at),
    pre_screener: attributes.pre_screener ? buildScreener(attributes.pre_screener) : null,
    survey_screener: attributes.survey_screener ? buildScreener(attributes.survey_screener) : null,
    landing_page: attributes.landing_page ? buildLandingPage(attributes.landing_page) : null,
    currencySymbol: currencyToSymbol(attributes.currency)
  };
  return data;
};

export const buildLandingPage: Builder<LandingPage> = ({ attributes }: Serializer<LandingPage>) => {
  const data = {
    ...attributes
  };
  return data;
};

export const buildScreener: Builder<Screener> = ({ attributes }: Serializer<Screener>) => {
  const data = {
    ...attributes
  };
  if (attributes.fields && attributes.fields.length > 0) {
    data.fields = attributes.fields.map(buildScreenerField);
  }
  return data;
};

export const buildScreenerField: Builder<ScreenerField> = ({ attributes }: Serializer<ScreenerField>) => ({
  ...attributes
});

export const buildFunding: Builder<Funding> = ({ attributes }) => ({
  ...attributes,
  study: buildProject(attributes.study.data),
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at),
  funded_at: dateIf(attributes.funded_at)
});

export const buildStudyLocation: Builder<StudyLocation> = (attributes) => ({
  ...attributes
});

export const buildInsight: Builder<Insight> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at)
});

export const buildRecording: Builder<Recording> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at),
  transcript: attributes.transcript && buildTranscript(attributes.transcript),
  clips: (attributes.clips || []).map(buildClip)
});
export const buildTranscript: Builder<Transcript> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at),
  processing_at: dateIf(attributes.processing_at)
});

export const buildHighlight: Builder<Highlight> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at)
});

export const buildCustomerImport: Builder<CustomerImport> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at)
});

function diffInSeconds(starts_at: Date, ends_at: Date) {
  return (ends_at.getTime() - starts_at.getTime()) / 1000;
}

export const buildZoomRecording: Builder<ZoomRecording> = (data) => {
  return {
    ...data,
    start_time: dateIf(data.start_time)
  };
};

export const buildDocument: Builder<ApiDocument> = (data) => {
  return { ...data.attributes, updated_at: dateIf(data.attributes.updated_at) };
};

export const buildClip: Builder<Clip> = ({ attributes }) => ({
  ...attributes,
  session: attributes.session && buildRepoSession(attributes.session)
});

export const buildRepoSession: Builder<RepoSession> = ({ attributes }) => ({
  ...attributes,
  session_at: dateIf(attributes.session_at),
  candidate: attributes.candidate && buildCustomer(attributes.candidate),
  participations: attributes.participations && attributes.participations.map((p) => buildParticipation(p)),
  participation: attributes.participation && buildParticipation(attributes.participation),
  study: attributes.study && buildProject(attributes.study),
  recording: attributes.recording && buildRecording(attributes.recording)
});

export const buildStudyTemplate: Builder<StudyTemplate> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at),
  updated_at: dateIf(attributes.updated_at),
  screener: attributes.screener && buildScreener({ attributes: attributes.screener })
});

export const buildRoleRequest: Builder<RoleRequest> = ({ attributes }) => ({
  ...attributes,
  created_at: dateIf(attributes.created_at)
});

export const buildAttr_: Builder<Attr_> = ({ attributes }) => ({
  ...attributes,
  updated_at: dateIf(attributes.updated_at),
  created_at: dateIf(attributes.created_at)
});

export const buildConnectedAccount: Builder<ConnectedAccount> = ({ attributes }) => ({
  ...attributes,
  expires_at: dateIf(attributes.expires_at),
  created_at: dateIf(attributes.created_at)
});

export const buildConnectedLandingPage: Builder<LandingPage> = ({ attributes }) => ({
  ...attributes,
  updated_at: dateIf(attributes.updated_at),
  created_at: dateIf(attributes.created_at)
});

export const buildExternalCandidatesRequest: Builder<ExternalCandidatesRequest> = ({ attributes }) => ({
  ...attributes,
  updated_at: dateIf(attributes.updated_at),
  created_at: dateIf(attributes.created_at)
});

export const buildCandidateMessage: Builder<CandidateMessage> = ({ attributes }) => ({
  ...attributes,
  updated_at: dateIf(attributes.updated_at),
  created_at: dateIf(attributes.created_at),
  sent_at: dateIf(attributes.sent_at),
  opened_at: dateIf(attributes.opened_at),
  clicked_at: dateIf(attributes.clicked_at),
  canceled_at: dateIf(attributes.canceled_at),
  state_timestamp: dateIf(attributes.state_timestamp)
});
