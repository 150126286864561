import { PersonSVG, VideoCameraSVG } from '@components/svgs';
import { GridGallery, GridGalleryBlock, Modal, ModalHeading, Text } from '@components/common';
import React from 'react';
import pluralize from 'pluralize';
import { MergeCells } from '@components/shared/Tiptap/icons';

const BREAKPOINT_COLS = {
  768: 1,
  1024: 2,
  1280: 3,
  default: 3
};

interface Props {
  response: ScreenerResponseAnswerValue<'card_sort'>;
  onClose: () => void;
}

export const ResponseDetailsModal: React.FC<Props> = ({ response, onClose }) => {
  return (
    <Modal size='xl' onClose={onClose}>
      <div className=''>
        <ModalHeading>Cards & categories</ModalHeading>
        <div className='flex items-center mb-6 mt-3'>
          <PersonSVG className='mr-1 text-gray-500' />
          <div className='text-gray-500'>{response.participation_name || 'Unnamed'}</div>
          <a href={`/sessions/${response.session_uuid}`} target='_blank'>
            <VideoCameraSVG className='w-4 h-4 ml-2' />
          </a>
        </div>
      </div>
      <div className='flex-grow pl-0 overflow-auto -ml-4'>
        <GridGallery breakpointCols={BREAKPOINT_COLS}>
          {response.categories.map(({ cards, name, merged_categories }) => (
            <GridGalleryBlock className='p-4 border rounded-md border-gray-200' key={name}>
              <div className='mb-4'>
                <Text bold>{name}</Text>
                {!!merged_categories?.length && (
                  <div className='mt-2.5 flex items-center'>
                    <MergeCells className='text-gray-500 mr-2' />
                    <Text h='400' color='gray-500'>
                      {merged_categories.join(', ')}
                    </Text>
                  </div>
                )}
              </div>
              {cards?.map((card) => (
                <Text key={card} h='400' className='px-4 py-2 border border-gray-200 rounded-md mb-1'>
                  {card}
                </Text>
              ))}
              <Text h='200' color='gray-500 mt-4'>
                {pluralize('cards', cards?.length || 0, true)}
              </Text>
            </GridGalleryBlock>
          ))}
        </GridGallery>
      </div>
    </Modal>
  );
};
