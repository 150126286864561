import pluralize from 'pluralize';

import { api } from '@api/reduxApi';

import { BLOCK_SETTINGS_FORM_DEFAULT_VALUES, _BLOCKABLE_TYPES } from './constants';

import * as Enums from './types/enums';
import * as Forms from './types/forms';
import * as Models from './types/models';
import * as BlockableComponents from './types/blockable-components';

const parseBlockableQueryUrl = ({ kind }: Pick<Models.Block, 'kind'>): string => {
  let url = `/${pluralize(kind)}`;

  if (kind !== Enums.Kind.prototypeTest && kind !== Enums.Kind.websiteTest && kind !== Enums.Kind.cardSort) {
    const prefix = '/questions';
    url = `${prefix}${url}`;
  }

  return url;
};

export const surveyBuilderApi = api.injectEndpoints({
  /**
   * Block endpoints
   */
  endpoints: (builder) => ({
    getSurveyBuilderBlocks: builder.query<Models.Block[], number>({
      query: (surveyId) => `/surveys/${surveyId}/blocks`,
      transformResponse: (response: Models.Block[]) => response,
      providesTags: ['SurveyBuilderBlocks']
    }),
    createSurveyBuilderBlock: builder.mutation<
      Models.Block,
      { surveyId: number } & Pick<Models.Block, 'blockable_id' | 'description' | 'kind' | 'required' | 'title'>
    >({
      query: ({ blockable_id, description, kind, required, surveyId, title }) => ({
        url: `/surveys/${surveyId}/blocks`,
        method: 'POST',
        body: { block: { blockable_id, blockable_type: _BLOCKABLE_TYPES[kind], description, required, title } }
      }),
      transformResponse: (response: Models.Block) => response
    }),
    updateSurveyBuilderBlock: builder.mutation<
      Models.Block,
      Pick<Models.Block, 'description' | 'id' | 'required' | 'title'>
    >({
      query: ({ id, title, description, required }) => ({
        url: `/blocks/${id}`,
        method: 'PUT',
        body: { block: { title, description, required } }
      }),
      transformResponse: (response: Models.Block) => response
    }),
    destroySurveyBuilderBlock: builder.mutation<void, number>({
      query: (blockId) => ({
        url: `/blocks/${blockId}`,
        method: 'DELETE'
      })
    }),
    duplicateSurveyBuilderBlock: builder.mutation<Models.Block, number>({
      query: (blockId) => ({
        url: `/blocks/${blockId}/duplicate`,
        method: 'POST'
      })
    }),
    updateSurveyBuilderBlockPosition: builder.mutation<void, Pick<Models.Block, 'id' | 'position'>>({
      query: ({ id, position }) => ({
        url: `/blocks/positions/${id}`,
        method: 'PUT',
        body: { position }
      })
    }),
    /**
     * Blockable endpoints
     */
    getSurveyBuilderBlockable: builder.query<Models.Blockable, Pick<Models.Block, 'id' | 'kind'>>({
      query: ({ id, kind }) => ({
        url: parseBlockableQueryUrl({ kind }) + `/${id}`
      })
    }),
    createSurveyBuilderBlockable: builder.mutation<Models.Blockable, Pick<Models.Block, 'kind'>>({
      query: ({ kind }) => ({
        method: 'POST',
        url: parseBlockableQueryUrl({ kind }),
        body: { [kind]: BLOCK_SETTINGS_FORM_DEFAULT_VALUES[kind] }
      })
    }),
    updateSurveyBuilderBlockable: builder.mutation<
      Models.Blockable,
      Pick<Models.Block, 'id' | 'kind'> & Pick<Forms.Data, 'config'>
    >({
      query: ({ id, kind, config }) => ({
        method: 'PUT',
        url: parseBlockableQueryUrl({ kind }) + `/${id}`,
        body: { [kind]: config }
      })
    }),
    createSurveyBuilderPrototypeTestStep: builder.mutation<
      Models.PrototypeTestStep,
      { step: Pick<Models.PrototypeTestStep, 'external_element_id' | 'last'>; pathId: number }
    >({
      query: ({ pathId, step }) => ({
        url: `/prototype_tests/paths/${pathId}/steps`,
        method: 'POST',
        body: { step }
      })
    }),
    updateSurveyBuilderPrototypeTestStep: builder.mutation<
      Models.PrototypeTestStep,
      Pick<Models.PrototypeTestStep, 'id' | 'external_element_id' | 'last'>
    >({
      query: (step) => ({
        url: `/prototype_tests/steps/${step.id}`,
        method: 'PUT',
        body: { step }
      })
    }),
    getUnmoderatedVersions: builder.query<{ version: 'v1' | 'v2' }, number | undefined>({
      query: (surveyId) => `/unmoderated_versions/${surveyId}`
    }),
    getPrototypeTestScreens: builder.query<BlockableComponents.PrototypeTestScreen[], number>({
      query: (prototypeTestId) => ({
        url: `/prototype_tests/${prototypeTestId}/screens`,
        transformResponse: (response: BlockableComponents.PrototypeTestScreen[]) => response
      })
    }),
    syncPrototypeTest: builder.mutation<Models.Blockable<Enums.Kind.prototypeTest>, number>({
      query: (prototypeTestId) => ({
        url: `/prototype_tests/${prototypeTestId}/sync`,
        method: 'POST'
      })
    }),
    validatePrototypeTest: builder.query<{ valid: boolean }, number>({
      query: (prototypeTestId) => ({
        url: `/prototype_tests/${prototypeTestId}/validate`
      })
    })
  })
});
