import React, { forwardRef, HTMLAttributes, useMemo } from 'react';
import { Table } from '@components/shared/Table';
import { getCardsTableColumnDefs } from './helpers/getCardsTableColumnDefs';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
}

export const CardsTable = forwardRef<HTMLDivElement, Props>(({ answers }, ref) => {
  const columns = useMemo(() => getCardsTableColumnDefs(), []);

  return <Table data={answers} columns={columns} className='w-full bg-white border border-gray-200 table-fixed' />;
});
