import React from 'react';

import { Table } from '@components/shared/Table';

import { ContactAccessCell } from '../components/ContactAccessCell';
import * as Types from '../types';
import HealthStatus from '@components/CandidatesApp/CandidatesIndex/HealthStatus';

export const customerColumnDefinitions: Types.DefinitionFn = ({
  addFilter,
  enableTeams,
  getColumnLabel,
  participationStatus
}) => {
  const columns: Types.DefinitionFnReturn = [
    {
      id: 'opted_in',
      accessorKey: 'customer.opted_in',
      cell: (props) => <Table.Cell<Participation> {...props} type='boolean' />,
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('opted_in')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'participations_count',
      accessorKey: 'customer.participations_count',
      cell: (props) => <Table.Cell<Participation> {...props} type='number' />,
      header: (props) => (
        <Table.ColumnHeader sortIconType='number' addFilter={addFilter} {...props}>
          {getColumnLabel?.('participations_count')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'current_year_usd_amount_in_cents',
      accessorKey: 'customer.current_year_usd_amount_in_cents',
      cell: (props) => <Table.Cell<Participation> {...props} type='number' />,
      header: (props) => (
        <Table.ColumnHeader sortIconType='number' addFilter={addFilter} {...props}>
          {getColumnLabel?.('current_year_usd_amount_in_cents')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'all_time_usd_amount_in_cents',
      accessorKey: 'customer.all_time_usd_amount_in_cents',
      cell: (props) => <Table.Cell<Participation> {...props} type='number' />,
      header: (props) => (
        <Table.ColumnHeader sortIconType='number' addFilter={addFilter} {...props}>
          {getColumnLabel?.('all_time_usd_amount_in_cents')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'invited_participations_count',
      accessorKey: 'customer.invited_participations_count',
      cell: (props) => <Table.Cell<Participation> {...props} type='number' />,
      header: (props) => (
        <Table.ColumnHeader sortIconType='number' addFilter={addFilter} {...props}>
          {getColumnLabel?.('invited_participations_count')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'responded_participations_count',
      accessorKey: 'customer.responded_participations_count',
      cell: (props) => <Table.Cell<Participation> {...props} type='number' />,
      header: (props) => (
        <Table.ColumnHeader sortIconType='number' addFilter={addFilter} {...props}>
          {getColumnLabel?.('responded_participations_count')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'completed_participations_count',
      accessorKey: 'customer.completed_participations_count',
      cell: (props) => <Table.Cell<Participation> {...props} type='number' />,
      header: (props) => (
        <Table.ColumnHeader sortIconType='number' addFilter={addFilter} {...props}>
          {getColumnLabel?.('completed_participations_count')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'timezone',
      accessorKey: 'customer.timezone',
      cell: (props) => <Table.Cell<Participation> {...props} type='text' />,
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('timezone')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'last_contacted_at',
      accessorKey: 'customer.last_contacted_at',
      cell: (props) => <Table.Cell<Participation> {...props} type='datetime' />,
      header: (props) => (
        <Table.ColumnHeader sortIconType='date' addFilter={addFilter} {...props}>
          {getColumnLabel?.('last_contacted_at')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'average_rating',
      accessorKey: 'customer.average_rating',
      cell: (props) => <Table.Cell<Participation> {...props} type='number' />,
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('average_rating')}
        </Table.ColumnHeader>
      )
    }
  ];

  if (participationStatus == 'shortlisted') {
    columns.push({
      id: 'eligible',
      accessorKey: 'customer.eligible',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          render={({ row }) => <HealthStatus status={row.original.customer?.health_status || []} />}
        />
      ),
      header: (props) => <Table.ColumnHeader {...props}>{getColumnLabel?.('eligible')}</Table.ColumnHeader>
    });
  }

  if (enableTeams) {
    columns.push({
      id: 'contact_access',
      accessorKey: 'customer.contact_access',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          render={({ row }) =>
            row.original.customer && (
              <ContactAccessCell
                candidateTeamIds={row.original.customer.team_ids ?? []}
                contactAccess={row.original.customer.contact_access}
              />
            )
          }
        />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('contact_access')}
        </Table.ColumnHeader>
      )
    });
  }

  return columns;
};
