import Tippy from '@tippyjs/react';
import tinytime from 'tinytime';
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { SlideOut, Text } from '@components/common';
import { track } from '@components/tracking';
import { compact } from '@components/utils';
import {
  EVENT_TYPE_LABELS,
  timeRangeStr
} from '@components/RepoSessionApp/ProfileSlideout/components/CalendarEvents/CalendarEventLink';
import { CalendarEventStatusPill } from '@components/RepoSessionApp/ProfileSlideout/components/CalendarEvents/CalendarEventStatusPill';

import { TimezoneIndicator } from '../TimezoneIndicator';
import { Guests } from './Guests';
import { Basic } from '../Skeleton';
import { ExternalLinkSVG } from 'components/svgs';

const dateFormat = tinytime('{dddd}, {MMMM} {DD}');
const timeFormat = tinytime('{h}:{mm}{a}');

const TimeRow: React.FC<{ time: Date }> = ({ time }) => (
  <div className='flex flex-col space-y-1'>
    <Text h='400'>
      {dateFormat.render(time)}, {timeFormat.render(time)}
    </Text>
    <TimezoneIndicator className='text-gray-500' />
  </div>
);

export const buildDataRows = (
  participation: Participation,
  calendarEvent: CalendarEvent
): [string, React.ReactNode][] =>
  compact([
    ['Date', dateFormat.render(calendarEvent.start_at)],
    [
      'Time',
      <div className='flex flex-col space-y-1'>
        <Text h='400'>{timeRangeStr(calendarEvent)}</Text>
        <TimezoneIndicator className='text-gray-500' />
      </div>
    ],
    ['Duration', Math.floor(+calendarEvent.end_at - +calendarEvent.start_at) / 1000 / 60 + ' minutes'],
    !!calendarEvent.conference_provider && ['Call location', calendarEvent.conference_provider],
    [
      'Join URL',
      (() => {
        const url = calendarEvent.conference_url || calendarEvent.location;
        if (url) {
          return (
            <a href={url} title={url} target='_blank' className='h400 flex items-center space-x-2 truncate'>
              <ExternalLinkSVG className='flex-shrink-0 w-4 h-4' />
              <span>{url}</span>
            </a>
          );
        }
        return (
          <Text h='400' color='gray-500' truncate>
            None
          </Text>
        );
      })()
    ],
    ['Attendees', <Guests participation={participation} calendarEvent={calendarEvent} />],
    ['Description', <div className='h400 whitespace-pre-wrap'>{calendarEvent.description}</div>],
    ['Created', <TimeRow time={calendarEvent.created_at} />],
    ['Last updated at', <TimeRow time={calendarEvent.updated_at} />],
    !!calendarEvent.last_synced_at && ['Last synced at', <TimeRow time={calendarEvent.last_synced_at} />],
    !!calendarEvent.discarded_at && [
      calendarEvent.replaced_at ? 'Replaced at' : 'Canceled at',
      <TimeRow time={calendarEvent.discarded_at} />
    ]
  ]);

export const CalendarEventSlideOut: React.FC = () => {
  const { participation_id: participationIdStr, id: idStr } = useParams<{ participation_id: string; id: string }>();
  const participationId = Number(participationIdStr);
  const id = Number(idStr);

  const navigate = useNavigate();

  const onClose = () => navigate(`../..`);

  const { data: calendarEvents } = api.useGetParticipationCalendarEventsQuery(
    { id: participationId },
    {
      skip: !id
    }
  );
  const calendarEvent = calendarEvents?.find((c) => c.id === id);
  const { data: participation } = api.useGetParticipationQuery(
    { id: participationId },
    {
      skip: !id
    }
  );

  useEffect(() => {
    if (calendarEvent?.event_type) {
      track('viewed_calendar_event_slideout', { event_type: calendarEvent.event_type });
    }
  }, [calendarEvent?.event_type]);

  return (
    <SlideOut size='2xl' onClose={onClose}>
      <div className='px-4'>
        {calendarEvent ? (
          <Text h='700' truncate>
            {calendarEvent.title}
          </Text>
        ) : (
          <Basic mb='4' />
        )}
        {calendarEvent?.discarded_at && (
          <div className='mt-2'>
            <Tippy content='The interview was updated. A new event was generated.'>
              <span>
                <CalendarEventStatusPill calendarEvent={calendarEvent} />
              </span>
            </Tippy>
          </div>
        )}
        {calendarEvent ? (
          <Text bold mb='4'>
            {EVENT_TYPE_LABELS[calendarEvent.event_type]} event details
          </Text>
        ) : (
          <Basic h='4' />
        )}
        {calendarEvent && participation && (
          <table className='w-full table-fixed'>
            <tbody>
              {buildDataRows(participation, calendarEvent).map(([label, value]) => (
                <tr key={label}>
                  <td className='w-1/4 py-2 pr-6 align-top'>
                    <Text h='400' medium className='whitespace-nowrap'>
                      {label}
                    </Text>
                  </td>
                  <td className='py-2'>{typeof value === 'string' ? <Text h='400'>{value}</Text> : value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </SlideOut>
  );
};
