import copy from 'copy-to-clipboard';
import React, { FC, MouseEvent, useCallback, useMemo } from 'react';
import { AlgoliaData, AlgoliaHit, useSearch } from '@components/shared/Search';
import { Table } from '@components/shared/Table';
import { useToaster } from '@components/stores/toaster';
import { track } from '@components/tracking';
import { useRepositoryContext } from '../../hooks/useRepositoryContext';
import { ArtifactAttributes } from '../../types';
import { useArtifactsColumnDefinitions } from '@components/RepositoryApp/hits/artifacts/hooks/useArtifactsColumnDefinitions';
import { useColumnLabels } from '@components/shared/Table/hooks/useGetColumnLabels';
import { ARTIFACT_COLUMN_LABELS, STATIC_COLUMNS } from '@components/RepositoryApp/hits/artifacts/constants';
import { TableSidebar } from '@components/shared/TableSidebar';
import { MultiselectDropdownItem } from '@components/shared/MultiselectCombobox';
import { useVisibleColumns } from '@components/shared/Table/hooks/useVisibleColumns';
import { useCollectionView } from '@stores/view';
import { DeepKeys } from '@tanstack/react-table';

interface Props {
  onDelete?: (h: Pick<ArtifactAttributes, 'href' | 'title' | 'kind' | 'model_id'>) => void;
}

export const ArtifactTable: FC<Props> = ({ onDelete }) => {
  const { data } = useSearch<AlgoliaData>();
  const { hits = [], hasMore = false } = data ?? {};

  const { view, setView } = useCollectionView();

  const getColumnLabel = useColumnLabels(ARTIFACT_COLUMN_LABELS);

  const { setSelectedArtifacts } = useRepositoryContext();
  const showToast = useToaster();

  const onCopyLink = useCallback((e: MouseEvent, href: string) => {
    e.stopPropagation();
    copy(`${location.origin}${href}`);
    showToast({ heading: 'Success!', text: 'Link copied to clipboard', icon: 'success' });
    track('copied_link_to_artifact');
  }, []);

  const columns = useArtifactsColumnDefinitions({ onDelete, onCopyLink, getColumnLabel });

  const columnIds = useMemo<string[]>(() => columns.map(({ id }) => id as string), [columns]);

  const updateStudyVisibleAttrs = (columnIds: string[]) => setView({ columns: columnIds });

  const onColumnVisibilityChange = useCallback((column: Record<DeepKeys<Study>, boolean>) => {
    const columnIds = Object.keys(column).filter((col) => column[col]);
    updateStudyVisibleAttrs(columnIds);
  }, []);

  const visibleColumns = useVisibleColumns({
    columnIds,
    alwaysVisibleColumns: STATIC_COLUMNS,
    currentVisibleColumns: view?.columns || columnIds
  });

  const selectedValues = useMemo<string[]>(
    () => Object.keys(visibleColumns).filter((col) => visibleColumns[col] && !STATIC_COLUMNS.includes(col)),
    [visibleColumns]
  );

  const items = useMemo<MultiselectDropdownItem<string>[]>(
    () =>
      columnIds.reduce<MultiselectDropdownItem<string>[]>(
        (acc, id) => (STATIC_COLUMNS.includes(id) ? acc : acc.concat({ label: getColumnLabel(id), value: id })),
        []
      ),
    [columnIds, getColumnLabel]
  );

  return (
    <div className='flex items-stretch flex-1'>
      <div className='relative flex-1 max-w-full overflow-auto border border-t-0 border-gray-200'>
        <Table<AlgoliaHit<ArtifactAttributes>>
          data={hits}
          columns={columns}
          visibleColumns={visibleColumns}
          className='w-full bg-white border-t border-gray-200 table-fixed'
          columnPinning={{ left: ['model_id', 'title'], right: ['actions'] }}
          rowIdentifier='model_id'
          onColumnVisibilityChange={onColumnVisibilityChange}
          onSelectRow={(rows) => {
            const selectedHits = hits.filter((hit) => rows[hit.model_id]);
            const selectedArtifactsIds = selectedHits.map((hit) => hit.objectID);
            setSelectedArtifacts(selectedArtifactsIds);
          }}
        />
      </div>
      <TableSidebar
        className='w-12 bg-white border border-l-0 border-gray-200'
        selectedValues={selectedValues}
        items={items}
        onVisibileColumnsChange={updateStudyVisibleAttrs}
      />
    </div>
  );
};
