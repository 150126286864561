import React, { SVGProps } from 'react';

export const UnmergeSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2533_33181)'>
      <path
        d='M6.5 2.83203H1.83333C1.1 2.83203 0.5 3.43203 0.5 4.16536V5.4987'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.5 10.168V11.8346C0.5 12.568 1.1 13.168 1.83333 13.168H6.5'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.16406 7.83203H5.0974'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.5 9.5013L5.1 7.83464L3.5 6.16797'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.5 2.83203H14.1667C14.9 2.83203 15.5 3.43203 15.5 4.16536V5.4987'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5 10.168V11.8346C15.5 12.568 14.9 13.168 14.1667 13.168H9.5'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.8318 7.83203H10.8984'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.4984 9.5013L10.8984 7.83464L12.4984 6.16797'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.6875 15.6875L15.6875 0.6875' stroke='currentColor' strokeWidth='0.88' strokeLinecap='round' />
    </g>
    <defs>
      <clipPath id='clip0_2533_33181'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
