import React, { forwardRef, SVGProps } from 'react';

export const PrototypeTaskMetricsSpriteSVG = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} {...props} xmlns='http://www.w3.org/2000/svg'>
    <symbol id='prototypeTaskMetrics-success' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_5741_15038)'>
        <path
          d='M4 8.81802L5.63333 11.136C5.69529 11.2286 5.77845 11.305 5.8759 11.3589C5.97335 11.4128 6.08226 11.4427 6.19358 11.446C6.3049 11.4493 6.4154 11.426 6.5159 11.378C6.61639 11.33 6.70396 11.2587 6.77133 11.17L12 4.55469'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.5 8C0.5 9.98912 1.29018 11.8968 2.6967 13.3033C4.10322 14.7098 6.01088 15.5 8 15.5C9.98912 15.5 11.8968 14.7098 13.3033 13.3033C14.7098 11.8968 15.5 9.98912 15.5 8C15.5 6.01088 14.7098 4.10322 13.3033 2.6967C11.8968 1.29018 9.98912 0.5 8 0.5C6.01088 0.5 4.10322 1.29018 2.6967 2.6967C1.29018 4.10322 0.5 6.01088 0.5 8Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_5741_15038'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </symbol>
    <symbol id='prototypeTaskMetrics-incomplete' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_5741_15030)'>
        <path
          d='M8.00008 15.3385C12.0501 15.3385 15.3334 12.0553 15.3334 8.00514C15.3334 3.95508 12.0501 0.671875 8.00008 0.671875C3.94997 0.671875 0.666748 3.95508 0.666748 8.00514C0.666748 12.0553 3.94997 15.3385 8.00008 15.3385Z'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.5749 5.35938L5.36011 10.5742'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.36011 5.35938L10.5749 10.5742'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_5741_15030'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </symbol>
    <symbol id='prototypeTaskMetrics-avgClicksToGoal' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_5741_15082)'>
        <path
          d='M7.07121 6.73964L7.74894 15.0878C7.75101 15.4934 8.27754 15.5784 8.59394 15.2918L10.5115 13.0353L13.4817 13.0422C13.8873 13.0402 14.1039 12.5349 13.8725 12.295L7.93534 6.4065C7.59361 6.01366 7.01394 6.25768 7.07121 6.73964Z'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 0.539062V3.20573'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.06665 2.60938L4.99998 4.476'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1 7.53906H3.66667'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.06665 12.476L4.99998 10.6094'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15 7.53906H12.3334'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13 2.60938L11.0667 4.476'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_5741_15082'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </symbol>
    <symbol id='prototypeTaskMetrics-misClickRate' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_5741_15071)'>
        <path
          d='M5.50731 10.989C4.80294 10.7195 4.20539 10.2282 3.80496 9.58911C3.40453 8.95005 3.22298 8.19802 3.28773 7.44666C3.35249 6.69529 3.66003 5.98542 4.16389 5.42429C4.66775 4.86316 5.34056 4.48127 6.08065 4.33632C6.53101 4.2505 6.99372 4.25254 7.44331 4.34232'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.48126 13.8715C4.03599 13.5725 2.74313 12.7718 1.83153 11.6111C0.919923 10.4504 0.44837 9.00464 0.500339 7.52968C0.552309 6.05473 1.12445 4.64573 2.11549 3.55209C3.10652 2.45846 4.45253 1.75072 5.91525 1.55417C6.52395 1.47427 7.141 1.4828 7.74725 1.5795'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.54863 12.1318C7.41512 11.2972 7.54944 10.4417 7.93231 9.68814C8.31517 8.93458 8.92691 8.32168 9.67973 7.93737C10.4325 7.55306 11.2878 7.41711 12.1226 7.54901C12.9575 7.68092 13.7292 8.0739 14.3268 8.67158C14.9245 9.26925 15.3175 10.0409 15.4494 10.8758C15.5813 11.7107 15.4454 12.5659 15.0611 13.3187C14.6767 14.0715 14.0638 14.6832 13.3103 15.0661C12.5567 15.449 11.7013 15.5833 10.8666 15.4498C10.0348 15.3161 9.26637 14.9235 8.67065 14.3278C8.07493 13.732 7.6823 12.9636 7.54863 12.1318Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M13 10L10 13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10 10L13 13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.5 7.91081L9.278 5.13281' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M9.27795 5.128L9.65662 2.628L11.822 0.5L12.1566 2.24933L13.906 2.584L11.778 4.74933L9.27795 5.128Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_5741_15071'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </symbol>
    <symbol id='prototypeTaskMetrics-avgDuration' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_5741_15057)'>
        <path
          d='M1.875 8C1.875 9.62445 2.52031 11.1824 3.66897 12.331C4.81763 13.4797 6.37555 14.125 8 14.125C9.62445 14.125 11.1824 13.4797 12.331 12.331C13.4797 11.1824 14.125 9.62445 14.125 8C14.125 6.37555 13.4797 4.81763 12.331 3.66897C11.1824 2.52031 9.62445 1.875 8 1.875C6.37555 1.875 4.81763 2.52031 3.66897 3.66897C2.52031 4.81763 1.875 6.37555 1.875 8V8Z'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M8 8V5.8125' stroke='currentColor' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M8 8L10.7341 10.7347'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_5741_15057'>
          <rect width='14' height='14' fill='white' transform='translate(1 1)' />
        </clipPath>
      </defs>
    </symbol>
    <symbol id='prototypeTaskMetrics-cards' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M10.6564 1.12567H2.21908C2.05323 1.12567 1.89418 1.19155 1.77691 1.30882C1.65963 1.4261 1.59375 1.58515 1.59375 1.751V14.251C1.59375 14.4168 1.65963 14.5759 1.77691 14.6932C1.89418 14.8104 2.05323 14.8763 2.21908 14.8763H10.6564C10.8223 14.8763 10.9813 14.8104 11.0986 14.6932C11.2159 14.5759 11.2818 14.4168 11.2818 14.251V1.75033C11.2818 1.58448 11.2159 1.42543 11.0986 1.30816C10.9813 1.19088 10.8223 1.125 10.6564 1.125V1.12567Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.2812 2.21875L13.9406 2.92008C14.1008 2.96249 14.2377 3.06679 14.3211 3.21004C14.4044 3.3533 14.4275 3.52381 14.3852 3.68408L11.5572 14.4101C11.5303 14.5121 11.4781 14.6057 11.4052 14.6821'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M1.59375 12.0625H11.2398' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </symbol>
    <symbol id='prototypeTaskMetrics-folder' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M15.28 5.20131C15.28 4.58278 14.7785 4.08131 14.16 4.08131H6.87997L5.53597 2.28931C5.32444 2.00731 4.9925 1.84131 4.63997 1.84131H1.83997C1.22137 1.84131 0.719971 2.34271 0.719971 2.96131V13.0413C0.719971 13.6599 1.22137 14.1613 1.83997 14.1613H14.16C14.7785 14.1613 15.28 13.6598 15.28 13.0413V5.20131Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </symbol>
  </svg>
));
