import React, { forwardRef, HTMLAttributes, useMemo, useState } from 'react';
import { Table } from '@components/shared/Table';
import { getCategoriesTableColumnDefs } from './helpers/getCategoriesTableColumnDefs';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
}

export const CategoriesTable = forwardRef<HTMLDivElement, Props>(({ answers }, ref) => {
  const columns = useMemo(() => getCategoriesTableColumnDefs(), []);

  return <Table data={answers} columns={columns} className='w-full bg-white border border-gray-200 table-fixed' />;
});
