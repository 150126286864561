import cn from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input, Text } from '@components/common';
import { ErrorSvg } from '@components/svgs';
import { emailPattern } from '@helpers/emailRegexpValidation';

import {
  BooleanInput,
  DateInput,
  EmailInput,
  InfoInput,
  LocationSearchInput,
  LongTextInput,
  MultipleChoiceInput,
  MultipleChoicePillInput,
  NumberInput,
  NumberRangeInput,
  ShortTextInput,
  SingleChoiceInput,
  SingleChoicePillInput,
  TaskInput,
  WebsiteInput
} from './fields';
import { InputProps, QuestionProps } from './types';
import { ZoomableImage } from '@components/common';
import { LinkifiedText } from 'components/common/LinkifiedText';

export const QuestionCard: React.FC<{ isActive: boolean; onClick?: () => void }> = ({
  isActive,
  children,
  onClick
}) => {
  return (
    <div className={cn('w-full flex', { 'opacity-50': !isActive })} onClick={onClick || undefined}>
      {children}
    </div>
  );
};

export const QuestionInner: React.FC = ({ children }) => <div className='max-w-40 flex-1'>{children}</div>;

interface QuestionTitleProps {
  required?: boolean;
  htmlFor?: string;
}

export const QuestionTitle: React.FC<QuestionTitleProps> = ({ htmlFor, children, required }) => (
  <label
    htmlFor={htmlFor}
    className={cn('text-xl font-bold leading-normal text-custom-brand flex items-start space-x-1')}
  >
    <span>{children}</span>
    {required && <span className='h400'>*</span>}
  </label>
);
export const QuestionNumber: React.FC<{ number: number }> = ({ number }) => (
  <div className='text-custom-brand w-8 text-xl font-bold'>{number}.</div>
);

type FieldDisplayType = 'multiple_choice_list' | 'multiple_choice_pill' | 'single_choice_list' | 'single_choice_pill';
type InputComponetsType = Omit<
  Record<ScreenerFieldType | FieldDisplayType, React.FC<InputProps>>,
  'start_loom' | 'stop_loom'
>;
const InputComponents: InputComponetsType = {
  short_text: ShortTextInput,
  yes_no: BooleanInput,
  long_text: LongTextInput,
  email: EmailInput,
  number: NumberInput,
  website: WebsiteInput,
  multiple_choice: MultipleChoiceInput,
  single_choice: SingleChoiceInput,
  multiple_choice_list: MultipleChoiceInput,
  multiple_choice_pill: MultipleChoicePillInput,
  single_choice_list: SingleChoiceInput,
  single_choice_pill: SingleChoicePillInput,
  number_range: NumberRangeInput,
  task: TaskInput,
  info: InfoInput,
  date: DateInput,
  location: LocationSearchInput,
  prototype_test: InfoInput,
  card_sort: InfoInput
};

export const Question: React.FC<QuestionProps> = ({
  number,
  register,
  watch,
  errors,
  field,
  autofocus,
  onFocus,
  onClick,
  isActive,
  setValue,
  control
}) => {
  let inputKey = field.field_type as string;
  const { t } = useTranslation('SurveyQuestion');
  if (['multiple_choice', 'single_choice'].includes(field.field_type)) {
    inputKey = inputKey + '_' + (field.layout || 'list');
  }
  const Input = InputComponents[inputKey];
  let helper = field.helper;

  if (!helper && field.field_type === 'multiple_choice') {
    helper = t('select_all_that_apply');
  }

  const hasImageUrl = !!(field.image_url && field.image_url.length > 1);

  return (
    <QuestionCard key={`field_${field.id}`} onClick={onClick} isActive={!!isActive}>
      <QuestionNumber number={number} />
      <QuestionInner>
        <QuestionTitle htmlFor={String(field.id)} required={field.required}>
          <LinkifiedText text={field.label} />
        </QuestionTitle>
        {helper &&
          field.field_type !== 'info' &&
          helper.split('\n').map((t, i) => (
            <p key={i} id={`description-for-${field.id}`} className='text-custom-brand mt-2 text-sm'>
              <LinkifiedText text={t} />
            </p>
          ))}

        {hasImageUrl && (
          <ZoomableImage
            src={field.image_url || undefined}
            className='w-96 object-contain object-center h-48 my-4'
            alt={field.image_alt || 'Question image'}
          />
        )}

        <div className='w-full mt-5'>
          <Input
            id={String(field.id)}
            control={control}
            field={field}
            onFocus={onFocus}
            register={register}
            autofocus={autofocus}
            watch={watch}
            error={errors[field.id]}
            setValue={setValue}
          />
        </div>
        {errors[field.id] && (
          <Text
            role='alert'
            aria-label='Error message'
            aria-describedby={String(field.id)}
            color='red-600'
            h='400'
            className='flex items-center mt-4 truncate no-branding'
          >
            <ErrorSvg className='mr-2' />
            {t(errors[field.id].message) || t('required')}
          </Text>
        )}
      </QuestionInner>
    </QuestionCard>
  );
};

const CONSENTS = {
  data: {
    label: 'data_label',
    required: true
  },
  can_opt_out: {
    label: 'can_opt_out_label',
    required: true
  },
  opt_in: {
    label: 'opt_in_label',
    required: false
  }
};
function Consent({ company_name, style, register, external, errors, opted_in }) {
  const { t } = useTranslation('SurveyQuestion');

  const consents = Object.keys(CONSENTS).filter((key) => key !== 'opt_in' || !opted_in);

  return (
    <div>
      <div className='relative mt-6'>
        {errors.consent && (
          <Text color='red-600' h='400' className='-top-5 absolute flex items-center col-span-3'>
            <ErrorSvg className='mr-2' />
            Please accept the required terms of participation.
          </Text>
        )}
        <ul className='space-y-2'>
          {consents.map((c) => {
            const key = 'consent[' + c + ']';
            const name = 'consent_' + c;
            const error = (errors.consent || {})[c];
            const required = CONSENTS[c].required || style === 'panel';
            let label = t(CONSENTS[c].label, { company: company_name });
            if (!required) {
              label = label + ' (Optional)';
            }
            if (external && CONSENTS[c].label === 'opt_in_label') return;

            const inputClass = cn('h-4 w-4  focus-ring-custom-brand input-custom-brand border-gray-300 rounded', {
              'border-red-600 focus:border-red-300 focus:ring-red': error
            });

            return (
              <li
                className={cn('flex flex-row items-center space-x-2 text-lg', {
                  hidden: style === 'panel' && CONSENTS[c].label === 'opt_in_label'
                })}
                key={'li' + key}
              >
                <input
                  id={name}
                  type='checkbox'
                  defaultChecked={style === 'panel' && CONSENTS[c].label === 'opt_in_label'}
                  className={inputClass}
                  name={key}
                  ref={register({ required })}
                />
                <label htmlFor={name} className={cn('text-base font-normal', { 'text-red-600': error })}>
                  {label}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
function ConsentPreview({ company_name }) {
  const { t } = useTranslation('SurveyQuestion');

  return (
    <>
      <ul className='space-y-2'>
        {Object.keys(CONSENTS).map((c) => {
          const key = 'consent[' + c + ']';
          const name = 'consent_' + c;
          return (
            <li key={name} className='flex flex-row items-center space-x-2 text-lg'>
              <input
                disabled
                id={name}
                type='checkbox'
                className='focus:ring-indigo-500 w-4 h-4 text-indigo-600 border-gray-300 rounded'
                name={key}
              />
              <label htmlFor={name} className={'text-base font-normal '}>
                {t(CONSENTS[c].label, { company: company_name })}
              </label>
            </li>
          );
        })}
      </ul>
    </>
  );
}

type NameAndIdQuestionProps = Pick<UseFormMethods, 'errors' | 'watch' | 'register'> & {
  account: PublicGQAccount;
  study: PublicStudy;
  token: string;
  external: boolean;
  hasPrefillId: boolean;
  hasOptedIn: boolean;
  number: number;
  isActive?: boolean;
  onFocus?: () => void;
  onClick?: () => void;
};

export const RULES: Record<string, ValidationRules> = {
  name: {
    required: { value: true, message: 'please_enter_your_name' },
    pattern: { value: /[^\s]/, message: 'please_enter_your_name' }
  },
  email: {
    required: { value: true, message: 'please_enter_your_email_address' },
    pattern: { value: emailPattern, message: 'please_enter_valid_email_address' }
  },
  phone_number: {
    required: { value: true, message: 'please_enter_phone_number' }
  }
};

export const NotQualifiedQuestion: React.FC = () => {
  const { t } = useTranslation('SurveyQuestion');

  return (
    <QuestionCard isActive>
      <QuestionInner>
        <LinkifiedText text={t('not_qualified')} />
      </QuestionInner>
    </QuestionCard>
  );
};

export const NameAndIdQuestion: React.FC<NameAndIdQuestionProps> = ({
  token,
  external,
  hasPrefillId,
  hasOptedIn,
  study,
  account,
  register,
  errors,
  watch,
  isActive,
  onFocus,
  onClick
}) => {
  const unique_id_attr = account.unique_id_attr;
  const hasToken = Boolean(token && token.length > 0);

  const { t } = useTranslation('SurveyQuestion');

  return (
    <QuestionCard onClick={onClick} isActive={!!isActive}>
      <QuestionInner>
        <QuestionTitle>{t('your_details')}</QuestionTitle>
        {!external && (
          <div className='relative mt-5 mb-8'>
            <label htmlFor='name' className='text-custom-brand mb-2 font-bold'>
              {t('full_name')}
            </label>
            <ShortTextInput
              placeholder={t('placeholder_enter')}
              field={{ id: 'name', required: true } as any}
              ariaLabel='Name'
              rules={RULES.name}
              register={register}
              autofocus
              error={errors.name}
              watch={watch}
              onFocus={onFocus}
            />
            {errors.name && (
              <Text aria-label='Error message' color='red-600' h='400' className='flex items-center mt-4 truncate'>
                <ErrorSvg className='mr-2' />
                {t(errors.name.message) || t('required')}
              </Text>
            )}
          </div>
        )}
        {!external && (study.comms_medium === 'sms' || unique_id_attr === 'phone_number') && (
          <div className='relative mb-8'>
            <label htmlFor='phone_number' className='text-custom-brand mb-2 font-bold'>
              {t('phone_number')}
            </label>
            <ShortTextInput
              placeholder='e.g. +1 415 555 5555'
              field={{ id: 'phone_number', required: true } as any}
              rules={RULES.phone_number}
              register={register}
              readOnly={unique_id_attr === 'phone_number' && (hasPrefillId || hasToken)}
              error={errors['phone_number']}
              watch={watch}
              onFocus={onFocus}
            />
            {errors.phone_number && (
              <Text aria-label='Error message' color='red-600' h='400' className='flex items-center mt-4 truncate'>
                <ErrorSvg className='mr-2' />
                {t(errors.phone_number.message) || t('required')}
              </Text>
            )}
          </div>
        )}
        {!external && (study.comms_medium === 'email' || unique_id_attr === 'email') && (
          <div className='relative mb-8'>
            <label htmlFor='email' className='text-custom-brand mb-2 font-bold'>
              {t('email_address')}
            </label>
            <EmailInput
              placeholder={t('placeholder_enter')}
              field={{ id: 'email', required: true } as any}
              ariaLabel='Email'
              rules={RULES.email}
              register={register}
              readOnly={unique_id_attr === 'email' && (hasPrefillId || hasToken)}
              error={errors['email']}
              watch={watch}
              onFocus={onFocus}
            />
            {errors.email && (
              <Text aria-label='Error message' color='red-600' h='400' className='flex items-center mt-4 truncate'>
                <ErrorSvg className='mr-2' />
                {t(errors.email.message) || t('required')}
              </Text>
            )}
          </div>
        )}
        <Consent
          register={register}
          company_name={account.name}
          style={study.style}
          external={external}
          errors={errors}
          opted_in={hasOptedIn}
        />
      </QuestionInner>
    </QuestionCard>
  );
};

export const NameQuestionPreview: React.FC<{ company_name: string }> = ({ company_name }) => (
  <div className='max-w-40 flex-1'>
    <div className='text-custom-brand block text-xl font-bold leading-normal'>Your details</div>
    <div className='mt-5 mb-6'>
      <label htmlFor='email' className='text-custom-brand mb-2 font-bold'>
        Your full name
      </label>
      <Input className='w-full' disabled value='' placeholder='Enter…' />
    </div>
    <div className='mb-6'>
      <label htmlFor='email' className='text-custom-brand mb-2 font-bold'>
        Your email address
      </label>
      <Input className='w-full' disabled value='' placeholder='Enter…' />
    </div>
    <div>
      <ConsentPreview company_name={company_name} />
    </div>
  </div>
);
