import React, { useMemo, VFC } from 'react';
import { Grid } from '@components/common/Grid';
import { SummaryColumn } from '@components/ScreenerResults/components';
import * as BlockableComponents from '@components/SurveyBuilder/types/blockable-components';

interface Props {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  sortType: BlockableComponents.CardSort['sort_type'];
}

export const CardSortSummary: VFC<Props> = ({ answers, sortType }) => {
  const totalColumns = sortType === 'closed' ? 5 : 4;

  const completed = useMemo(() => answers.filter((answer) => answer.completed), [answers]);

  const successRate = useMemo(() => {
    const successCount = answers.filter((answer) => answer.completed).length;

    return (successCount / answers.length) * 100;
  }, [answers]);

  const incompleteRate = useMemo(() => {
    const incompleteCount = answers.filter((answer) => !answer.completed).length;

    return (incompleteCount / answers.length) * 100;
  }, [answers]);

  const avgDuration = useMemo(() => {
    const totalDuration = completed.reduce((acc, answer) => acc + (answer.time_in_task ?? 0), 0);

    return totalDuration / completed.length / 1000;
  }, [answers]);

  const totalCards = useMemo(() => answers.reduce((acc, answer) => acc + answer.cards.length, 0), [answers]);

  const totalCategories = useMemo(() => answers.reduce((acc, answer) => acc + answer.categories.length, 0), [answers]);

  return (
    <section className='p-4 mb-2 border border-gray-200 rounded'>
      <Grid desktop={totalColumns} tablet={totalColumns} mobile={2}>
        <SummaryColumn value={successRate} label='Overall agreement' iconHref='#prototypeTaskMetrics-success' />
        {sortType === 'closed' && (
          <SummaryColumn value={incompleteRate} label='Incomplete' iconHref='#prototypeTaskMetrics-incomplete' />
        )}
        <SummaryColumn value={totalCards} label='Cards' iconHref='#prototypeTaskMetrics-cards' />
        <SummaryColumn value={totalCategories} label='Avg. Cat. per response' iconHref='#prototypeTaskMetrics-folder' />
        <SummaryColumn value={avgDuration} label='Avg. Duration' iconHref='#prototypeTaskMetrics-avgDuration' />
      </Grid>
    </section>
  );
};
