import { Table } from '@components/shared/Table';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { compact } from '@components/utils';
import { MultiselectDropdownItem } from 'components/shared/MultiselectCombobox';
import { STATIC_COLUMNS, STUDIES_COLUMN_LABELS } from '@components/StudiesApp/components/StudiesIndex/contants';
import { useStudiesColumnDefinitions } from '@components/StudiesApp/components/StudiesIndex/hooks/useStudiesColumnDefinitions';
import { useCollectionView } from '@stores/view';
import { useVisibleColumns } from '@components/shared/Table/hooks/useVisibleColumns';
import { TableSidebar } from '@components/shared/TableSidebar';
import { useAccount } from '@hooks/useAccount';
import { DeepKeys } from '@tanstack/react-table';
import cn from 'classnames';
import { api } from '@api/reduxApi';
import { CellError, OnUpdateCellParams } from '@components/shared/Table/types';
import { failedUpdate, successUpdate } from '@components/StudiesApp/toasts';
import { useToaster } from '@stores/toaster';
import { PagyRequest } from '@api/queries';
import { useColumnLabels } from '@components/shared/Table/hooks/useGetColumnLabels';

interface Props {
  onlyBackendFilters?: boolean;
  records: Study[];
  groupBy?: 'owner' | 'status' | 'type';
  borderColor?: string;
  setSort?: (sort: CollectionView['sort']) => void;
  studyAttrs?: Attr_[];
  queryArgs?: PagyRequest<{
    teamId?: number | null;
    includeArchived?: boolean;
    tab?: string;
  }>;
}

export const ListViewTable: React.FC<Props> = ({
  onlyBackendFilters,
  records: studies,
  groupBy,
  borderColor = 'gray-200',
  setSort,
  studyAttrs = [],
  queryArgs
}) => {
  const {
    account: { team }
  } = useAccount();

  const showToast = useToaster();

  const [updateStudy, { isSuccess, isError }] = api.useUpdateStudyMutation();

  const getStudyCreator = useCallback((creator_id: number) => team.find(({ id }) => id === creator_id), [team]);

  const getStudyModerators = useCallback(
    (moderator_ids: number[]) =>
      compact(moderator_ids?.map((id) => team.find(({ id: userId }) => userId === id)) || []),
    [team]
  );

  const { view, setView } = useCollectionView();

  const updateStudyVisibleAttrs = (columnIds: string[]) => setView({ columns: columnIds });

  const onColumnVisibilityChange = useCallback((column: Record<DeepKeys<Study>, boolean>) => {
    const columnIds = Object.keys(column).filter((col) => column[col]);
    updateStudyVisibleAttrs(columnIds);
  }, []);

  const getColumnLabel = useColumnLabels(STUDIES_COLUMN_LABELS);

  const columns = useStudiesColumnDefinitions({
    groupBy,
    getStudyModerators,
    getStudyCreator,
    studyAttrs,
    getColumnLabel
  });

  const columnIds = useMemo<string[]>(() => columns.map(({ id }) => id!), [columns]);
  
  const items = useMemo<MultiselectDropdownItem<string>[]>(
    () =>
      columnIds.reduce<MultiselectDropdownItem<string>[]>(
        (acc, id) => (STATIC_COLUMNS.includes(id) ? acc : acc.concat({ label: getColumnLabel(id), value: id })),
        []
      ),
    [columnIds, getColumnLabel]
  );

  const visibleColumns = useVisibleColumns({
    columnIds,
    alwaysVisibleColumns: STATIC_COLUMNS,
    currentVisibleColumns: view?.columns || columnIds
  });

  const selectedValues = useMemo<string[]>(
    () => Object.keys(visibleColumns).filter((col) => visibleColumns[col] && !STATIC_COLUMNS.includes(col)),
    [visibleColumns]
  );

  const updateStudyAttrs = useCallback(
    ({
      row,
      columnId,
      value,
      errors,
      setErrors
    }: OnUpdateCellParams<Study> & {
      errors: CellError[];
      setErrors: (errors: CellError[]) => void;
    }) => {
      try {
        updateStudy({
          id: row.original.id,
          custom_attributes: { ...(row.original.custom_attributes || {}), [columnId]: value },
          originalQueryArgs: queryArgs,
          shouldInvalidateStudiesList: false
        });

        showToast(successUpdate());
      } catch {
        const error = errors.find((error) => error.rowIndex === row.index && error.columnId === columnId);

        if (!error) {
          setErrors([...errors, { rowIndex: row.index, columnId }]);
        }
        showToast(failedUpdate());
      }
    },
    [queryArgs]
  );

  const editableColumns = useMemo(() => [...(studyAttrs || []).map(({ name }) => name)], [studyAttrs]);

  const columnOrder = useMemo<string[]>(
    () =>
      [
        'state',
        'title',
        'style',
        'updated_at',
        'created_at',
        'owner_id',
        'creator_id',
        'moderator_ids',
        ...studyAttrs.map(({ name }) => name),
        'completed',
        'options'
      ].filter((col) => STATIC_COLUMNS.includes(col) || (view?.columns || columnIds)?.includes(col)),
    [studyAttrs, view?.columns, columnIds]
  );

  return (
    <div className='flex items-stretch flex-1'>
      <div className={`relative flex-1 max-w-full overflow-auto border-l border-r border-${borderColor}`}>
        <Table<Study>
          manualSorting={onlyBackendFilters}
          manualPagination={onlyBackendFilters}
          columnOrder={columnOrder}
          onColumnVisibilityChange={onColumnVisibilityChange}
          onUpdateCell={updateStudyAttrs}
          editableColumns={editableColumns}
          columnPinning={{ left: ['title'], right: ['options'] }}
          onSort={setSort}
          data={studies}
          columns={columns}
          visibleColumns={visibleColumns}
          className={`w-full bg-white border-t border-${borderColor} table-fixed`}
        />
      </div>
      <TableSidebar
        items={items}
        selectedValues={selectedValues}
        className={cn('w-12 bg-white border border-gray-200', {
          'border-r-0 border-t-0': borderColor === 'none',
          'border-l-0': borderColor === 'gray-200'
        })}
        onVisibileColumnsChange={updateStudyVisibleAttrs}
      />
    </div>
  );
};
