import { useCallback } from 'react';
import { UseColumnLabelsReturnType } from 'components/shared/Table/types';
import { capitalize } from '@components/utils';

export const useColumnLabels = (columnLabels: Record<string, string>): UseColumnLabelsReturnType => {
  const getColumnLabel = useCallback<UseColumnLabelsReturnType>(
    (key: string) => {
      return columnLabels[key] ?? capitalize(key);
    },
    [columnLabels]
  );

  return getColumnLabel;
};
