import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { ArtifactsView, PublicArtifactsView } from '../components';

export interface ArtifactsAttributes {
  artifacts: string[];
  uuid: string;
}

export const Artifacts = Node.create<ArtifactsAttributes>({
  name: 'artifacts',

  group: 'block',

  atom: true,

  draggable: true,

  addAttributes() {
    return {
      artifacts: {
        default: []
      },
      uuid: {
        default: null
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'artifact-component',
        getAttrs: (dom: Element) => {
          const artifactsString = dom.getAttribute('data-artifacts');
          const uuid = dom.getAttribute('data-uuid');

          if (!artifactsString) return null;

          return {
            artifacts: artifactsString.split(','),
            uuid
          };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'artifact-component',
      {
        'data-artifacts': HTMLAttributes.artifacts.join(','),
        'data-uuid': HTMLAttributes.uuid
      }
    ];
  },

  addNodeView() {
    if ((this.editor.options?.editorProps as any)?.api === 'public') {
      return ReactNodeViewRenderer(PublicArtifactsView);
    }
    return ReactNodeViewRenderer(ArtifactsView);
  }
});
