import React from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { EXTERNAL_ATTRS } from '@components/config';
import { Table } from '@components/shared/Table';

import * as Types from '../types';
import { CustomAttrsCell } from '@components/shared/Table/components';
import { ATTR_TO_SORT_ICON_MAPPING } from '@components/shared/GridTable/components/OtherHeader/constants';

export const extraColumnDefinitions: Types.DefinitionFn = ({ addFilter, candidateAttrs = [], study }) => {
  const definitions: ColumnDef<Participation>[] = [];

  const attributes: Attr_[] = [...candidateAttrs];

  if (study?.has_external_candidates_requests) {
    attributes.push(...EXTERNAL_ATTRS);
  }

  attributes.forEach(({ name, label, attr_type }) => {
    definitions.push({
      id: `extra.${name}`,
      accessorFn: (participation) => participation.customer?.extra[name],
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          options={[]}
          type={attr_type}
          render={
            ['text', 'multiple_choice'].includes(attr_type)
              ? (props) => <CustomAttrsCell {...props} attrId={candidateAttrs.find((attr) => attr.name === name)?.id} />
              : undefined
          }
        />
      ),
      header: (props) => (
        <Table.ColumnHeader
          addFilter={addFilter}
          sortIconType={ATTR_TO_SORT_ICON_MAPPING[attr_type] || 'text'}
          {...props}
        >
          {label}
        </Table.ColumnHeader>
      )
    });
  });

  return definitions;
};
