import { Avatar, Popper, Text } from '@components/common';
import * as React from 'react';
import cn from 'classnames';

const MAX_MODERATORS = 5;

export const ModeratorsCell: React.FC<{ moderators?: TeamUser[] }> = ({ moderators }) => {
  if (!moderators?.length) {
    return <span className='px-2'>-</span>;
  }

  if (moderators.length === 1) {
    return (
      <div className='flex items-center flex-nowrap truncate'>
        {moderators[0] && <Avatar className='px-2 truncate' noTippy user={moderators[0]} />}
        {moderators[0].name && (
          <Text h='400' className='truncate'>
            {moderators[0].name}
          </Text>
        )}
      </div>
    );
  }

  return (
    <Popper
      event='hover'
      placement='bottom-end'
      offset={[0, 8]}
      content={() => (
        <div className='p-4 rounded-md shadow-lg bg-white'>
          <Text className='uppercase mb-4' h='200' color='gray-500'>
            Moderators
          </Text>
          <ul className='flex flex-col space-y-4'>
            {moderators?.map((user) => (
              <li key={user.id} className='flex items-center'>
                <Avatar noTippy user={user} size='xl' />
                <div className='flex flex-col flex-1 ml-4'>
                  <p className='h400 font-medium text-gray-700'>{user.name}</p>
                  <p className='h200 text-gray-500'>{user.job_title}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    >
      <div className='flex items-center flex-nowrap cursor-pointer px-2 overflow-hidden'>
        {moderators?.slice(0, MAX_MODERATORS)?.map((user, i) => (
          <Avatar noTippy key={user?.id} user={user} className={cn({ ['-ml-0.5']: i > 0 })} />
        ))}
        {moderators.length > MAX_MODERATORS && (
          <Avatar noTippy user={{ initials: `+${moderators.length - MAX_MODERATORS}` }} className='-ml-2' />
        )}
      </div>
    </Popper>
  );
};
