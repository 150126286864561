import React, { PropsWithChildren, useMemo } from 'react';
import cn from 'classnames';

import { Text } from '@components/common';
import { Item, Menu, MenuProps } from '@components/common/Menu';
import { SORT_ICONS } from '@components/shared/GridTable/components/OtherHeader/constants';
import { move } from '@helpers/move';

import * as Icons from '../icons';
import { ColumnHeaderProps } from '../types';
import { useTableContext } from '@components/shared/Table/hooks';
import { Tooltip } from 'components/shared/Tooltip';

export const ColumnHeader = <D extends Record<string, any>>({
  isStatic = false,
  header,
  table,
  children,
  disableHide = false,
  disableSort = false,
  sortIconType = 'text',
  tooltipText = '',
  addFilter
}: PropsWithChildren<ColumnHeaderProps<D>>) => {
  const columnId = header.column.id;
  const sortCopy = SORT_ICONS[sortIconType];

  const handleOnItemSelect: MenuProps['onItemSelect'] = (value) => {
    switch (value) {
      case 'hide':
        table.setColumnVisibility((previousVisibility) => {
          return { ...previousVisibility, [columnId]: false };
        });
        break;
      case 'filter':
        addFilter?.(columnId);
        break;
      case 'sort_asc':
        table.options.meta?.onSort?.({ value: columnId, desc: false });
        break;
      case 'sort_desc':
        table.options.meta?.onSort?.({ value: columnId, desc: true });
        break;
      case 'move_left':
      case 'move_right': {
        table.setColumnOrder((state) => {
          const columnVisibility = table.getState().columnVisibility;
          const visibleColumns = Object.keys(columnVisibility).filter((key) => columnVisibility[key]);
          const allColumns = table.getFlatHeaders().map((header) => header.id);

          const defaultColumnOrder = visibleColumns.length > 0 ? visibleColumns : allColumns;

          const previousOrder: string[] = state.length > 0 ? state : defaultColumnOrder;
          const index = previousOrder.findIndex((id) => id === columnId);

          if (
            (value === 'move_left' && index === 0) ||
            (value === 'move_right' && index === previousOrder.length - 1)
          ) {
            return previousOrder;
          }

          return move<string>(previousOrder, index, index + (value === 'move_left' ? -1 : 1));
        });
        break;
      }
      default:
        break;
    }
  };

  const dropdownDisabled =
    isStatic || (disableHide && disableSort && !addFilter && Boolean(header.column.getIsPinned()));

  return (
    <Menu
      onItemSelect={handleOnItemSelect}
      className='w-60 overflow-hidden text-sm bg-white border border-gray-200 rounded-md shadow-lg'
      popperProps={{ placement: 'bottom-end', zIndex: 10, isDisabled: dropdownDisabled }}
      renderTrigger={({ isOpen }) => (
        <div
          className={cn('group h-11 relative flex items-center justify-between bg-white border-b border-gray-200', {
            'cursor-pointer': !dropdownDisabled
          })}
        >
          <Tooltip content={tooltipText} isDisabled={!tooltipText} width={200}>
            <span className='h400 ml-2 font-bold truncate'>{children}</span>
          </Tooltip>
          {!dropdownDisabled && (
            <Icons.ChevronDown
              className={cn('group-hover:opacity-100 flex-shrink-0 opacity-0 mr-2', { 'opacity-100': isOpen })}
            />
          )}
          {!isStatic && (
            <div
              className={cn(
                'absolute w-1 h-full top-0 right-0 select-none cursor-col-resize opacity-0 hover:opacity-100 bg-gray-500'
              )}
              onMouseDown={header.getResizeHandler()}
            />
          )}
        </div>
      )}
    >
      {addFilter && (
        <Item value='filter' className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'>
          <Icons.Filter className='mr-2' /> Filter
        </Item>
      )}
      {!disableHide && (
        <Item value='hide' className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'>
          <Icons.Hide className='mr-2' /> Hide
        </Item>
      )}
      {!disableSort && (
        <>
          <Item value='sort_asc' className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'>
            <sortCopy.ascending.Icon className='mr-2' />
            <Text h='400'>{sortCopy.ascending.text}</Text>
          </Item>
          <Item value='sort_desc' className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'>
            <sortCopy.descending.Icon className='mr-2' />
            <Text h='400'>{sortCopy.descending.text}</Text>
          </Item>
        </>
      )}
      {!header.column.getIsPinned() && (
        <>
          <Item value='move_left' className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'>
            <Icons.MoveLeft className='mr-2' /> Move to left
          </Item>
          <Item value='move_right' className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'>
            <Icons.MoveRight className='mr-2' /> Move to right
          </Item>
        </>
      )}
    </Menu>
  );
};
