import { ELocalStorageKeys } from 'constants/localStorageKeys';
import * as React from 'react';
import { useRef, useState } from 'react';
import { HeaderGroup, Row, TableInstance } from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';

import { Option } from '@components/common';
import { GridWithStickyCells } from '@components/common/tables/GridWithStickyCells';
import { AddColumnButton } from '@components/shared/GridTable/components/AddColumnButton';
import { ColumnsDropdown, GridTableTabs } from '@components/shared/GridTable/components/ColumnsDropdown';
import Tippy from '@tippyjs/react';

import { useLoadColumnOrderFromLocalStorage } from './hooks/useLoadColumnOrderFromLocalStorage';

interface Props {
  disableAutoSizerHeight?: boolean;
  tableInstance: TableInstance<any>;
  columnOptions: Option[];
  visibleColumnNames?: string[];
  setVisibleColumnNames: (names: string[]) => void;
  tableColumnsOrder: ELocalStorageKeys;
  tabs?: GridTableTabs;
  pagination?: boolean;
}

interface Context {
  prepareOnlyOnce: (row: Row) => void;
  headers: HeaderGroup<any>[];
  rows: Row[];
}

const COLUMN_BAR_WIDTH = '46px';

const GridContext = React.createContext<Context>({} as Context);

const GridCell = ({ columnIndex, rowIndex, style }) => {
  const { prepareOnlyOnce, headers, rows } = React.useContext(GridContext);

  let props: any;
  let content: React.ReactNode;

  if (rowIndex === 0) {
    // headers actually act as the first row of cells
    props = { style };
    content = headers[columnIndex].render('Header');
  } else {
    // and every subsequent row is index +1
    const row = rows[rowIndex - 1];
    prepareOnlyOnce(row);
    const cell = row.cells[columnIndex];
    props = cell.getCellProps({ style });
    content = cell.render('Cell');
  }

  const headerProps = headers[columnIndex];

  return (
    <div
      className={`border-gray-200 bg-white group flex items-center px-1 border-0 border-b overflow-visible ${
        rowIndex > 0 && columnIndex === 0 ? 'xx-first-grid' : ''
      }`}
      {...props}
    >
      {content}
      {rowIndex === 0 && (
        <Tippy arrow={false} hideOnClick delay={500} content='Drag to change column width'>
          <div
            className='hover:bg-indigo-600 absolute top-0 right-0 z-10 inline h-full px-1'
            {...headerProps.getResizerProps()}
          />
        </Tippy>
      )}
    </div>
  );
};

export const GridTable: React.FC<Props> = ({
  tableInstance,
  visibleColumnNames,
  setVisibleColumnNames,
  disableAutoSizerHeight,
  columnOptions,
  tableColumnsOrder,
  tabs,
  pagination
}) => {
  const {
    state: { columnOrder },
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    setColumnOrder,
    totalColumnsWidth
  } = tableInstance;

  const ref = useRef<HTMLDivElement>(null);

  const [columnsDropdownVisible, setColumnsDropdownVisible] = useState(false);
  const [scrollXStorage, setScrollXStorage] = useState<number>(0);
  const [scrollYStorage, setScrollYStorage] = useState<number>(0);

  useLoadColumnOrderFromLocalStorage(tableColumnsOrder, setColumnOrder);

  const { headers } = headerGroups[0];

  const closeColumnDropdown = () => setColumnsDropdownVisible(false);

  const toggleColumnDropdown = () => setColumnsDropdownVisible((prev) => !prev);

  const showColumns = (columns: string[]) => {
    setVisibleColumnNames(columns);
    closeColumnDropdown();
  };

  const prepared: any = [];

  const prepareOnlyOnce = (row) => {
    if (!prepared.includes(row.id)) {
      prepareRow(row);
      prepared.push(row.id);
    }
  };

  const onScroll = () => {
    if (ref.current) {
      setScrollYStorage(ref.current.scrollTop);
      setScrollXStorage(ref.current.scrollLeft);
    }
  };

  return (
    <GridContext.Provider value={{ prepareOnlyOnce, headers, rows: pagination ? page : rows }}>
      <div
        className='min-h-screen'
        style={getTableBodyProps({ style: { width: 'auto', paddingRight: COLUMN_BAR_WIDTH } }).style}
      >
        <AutoSizer key={`${totalColumnsWidth}${columnOrder.join()}`} disableHeight={disableAutoSizerHeight}>
          {({ height = 1000, width }) => (
            <GridWithStickyCells
              ref={ref}
              initialScrollLeft={scrollXStorage}
              initialScrollTop={scrollYStorage}
              onScroll={onScroll}
              className='bg-gray-50 border border-gray-200 xx-grid-table'
              width={width}
              height={height}
              columnCount={headers.length}
              columnWidth={(i) => headers[i].width}
              rowCount={pagination ? page.length + 1 : rows.length + 1}
              rowHeight={(i) => 44}
            >
              {GridCell}
            </GridWithStickyCells>
          )}
        </AutoSizer>
        <div className='absolute top-0 bottom-0 right-0 w-12 bg-white border border-gray-200'>
          <div className='relative flex items-center justify-center w-12 h-12'>
            <AddColumnButton toggleColumnDropdown={toggleColumnDropdown} />

            {columnsDropdownVisible && (
              <ColumnsDropdown
                tabs={tabs}
                onClose={closeColumnDropdown}
                columns={columnOptions}
                visibleColumns={visibleColumnNames || []}
                showColumns={showColumns}
              />
            )}
          </div>
        </div>
      </div>
    </GridContext.Provider>
  );
};

export { Props as GridTableProps };
