import { useStudyLimits } from 'components/StudiesApp/components/StudyPublished/hooks/useStudyLimits';
import pluralize from 'pluralize';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { getScreeners } from '@api/queries';
import { api } from '@api/reduxApi';
import { Alert, Loading, SlideOut } from '@components/common';
import { DropdownItem } from '@components/common/DropdownCombobox';
import SearchSelector from '@components/shared/SearchSelector';
import { usePlan } from '@hooks/usePlan';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Toast, useToaster } from '@stores/toaster';

import { NoCandidateAccessAlert } from './components/alerts';
import { StudyMessageSlideOut } from './StudyMessageSlideOut';
import { SendMessageParams } from './types';

const errorToast: Toast = {
  heading: 'Failed to send screener!',
  icon: 'error',
  text: 'Something went wrong. Please try again later.'
};

const successToast = (): Toast => ({
  heading: 'Screener invites will be sent!',
  text: '',
  icon: 'success'
});

interface Props {
  teamId?: number | null;
  study?: Study;
  ids?: number[];
  onSuccess?: () => void;
  onClose: () => void;
  query?: ServerFilterQuery;
  allSelected?: boolean;
  selectedCount: number;
  previewCandidate?: Candidate;
}

export const CandidatesScreenerSlideOut: React.FC<Props> = ({
  teamId,
  study: initialStudy,
  ids = [],
  query,
  allSelected,
  selectedCount,
  previewCandidate,
  onSuccess,
  onClose
}) => {
  const [selectedStudyId, setSelectedStudyId] = useState<number | undefined>();

  const {
    data: study = initialStudy,
    isLoading: studyLoading,
    isError: studyError
  } = api.useGetStudyQuery(selectedStudyId ?? skipToken);

  const candidateParams = allSelected ? { query } : { ids };
  const { studyLimits, studyLimitMatches } = useStudyLimits(study, candidateParams);

  const [screeners, setScreeners] = useState<Screener[]>([]);
  const [screener, setScreener] = useState<Screener | null>(study ? study.pre_screener : null);
  const showToast = useToaster();
  const { hasQuota, getQuota } = usePlan();
  const [candidateBulkScreenerInvite, { isLoading: inviteLoading }] = api.useCandidateBulkScreenerInviteMutation();
  const [getCounts, { data, isSuccess }] = api.useGetInviteSlideOutCountsMutation();
  const { data: canAccess } = api.useCanAccessCandidatesQuery(
    { candidates: candidateParams, team_id: study?.team_id },
    {
      skip: !study || !ids
    }
  );

  const [fetchedScreeners, setFetchedScreeners] = useState<boolean>(!!study);

  useEffect(() => {
    if (study) {
      setScreener(study.pre_screener);
    }
  }, [study]);

  async function handleSend({ batch, excludeIneligible, message, sender }: SendMessageParams) {
    if (!screener) return;

    try {
      const batch_params = {
        batch_on: batch.on,
        batch_size: batch.size,
        batch_wait_hours: batch.wait_hours,
        batch_target: batch.target
      };
      await candidateBulkScreenerInvite({
        batch: batch_params,
        screenerId: screener.id,
        exclude_ineligible: excludeIneligible,
        ...(allSelected ? { query } : { customer_ids: ids }),
        message_id: message && message.custom ? message.id : null,
        sender
      }).unwrap();

      showToast(successToast());
      onSuccess?.();
    } catch (e) {
      showToast(errorToast);
      onClose();
    }
  }

  useEffect(() => {
    if (!study) {
      getScreeners().then((resp) => {
        setScreeners(resp);
        setFetchedScreeners(true);
      });
    }
  }, []);

  const handleSelect = async (id: string): Promise<void> => {
    const selectedScreener = screeners.find(({ id: studyId }) => id.toString() === studyId.toString());

    setSelectedStudyId(selectedScreener?.project_id);
    await getCounts({
      studyId: selectedScreener?.project_id as number,
      ...(allSelected ? { query } : { candidateIds: ids })
    });
  };

  const ineligibleCount = isSuccess && data ? data.ineligible_count : 0;
  const contactableCount = isSuccess && data ? data.contactable_count : 0;
  const hasInviteQuota = hasQuota('invites', selectedCount);

  const options = useMemo(
    () =>
      screeners
        .filter((s) => !teamId || s.project?.team_id === teamId)
        .map(({ name: label, id, project }): DropdownItem => {
          const disabled = project && ['draft', 'closed'].includes(project.state);
          return {
            label: disabled ? `${label} (${project.state})` : label,
            value: id.toString(),
            disabled
          };
        }),
    [screeners]
  );

  if (!hasInviteQuota) {
    return (
      <SlideOut title='Screener invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Alert type='warning'>
            Cannot send all {pluralize('messages', selectedCount, true)} as this exceeds your remaining quota of{' '}
            {getQuota('invites')}
          </Alert>
        </div>
      </SlideOut>
    );
  }

  if (canAccess === false) {
    return (
      <SlideOut title='Screener invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <NoCandidateAccessAlert />
        </div>
      </SlideOut>
    );
  }

  const loading = studyLoading || inviteLoading;

  if (screener && study && selectedCount > 0) {
    return (
      <StudyMessageSlideOut
        title='Screener invite'
        batchingTitle='screener invites'
        batchingTarget={true}
        batchable
        cta='Send Screener'
        study={study}
        event='screener'
        customizable
        totalCount={selectedCount}
        inviteableCount={selectedCount}
        ineligibleCount={ineligibleCount}
        contactableCount={contactableCount}
        previewCandidate={previewCandidate}
        onClose={onClose}
        onSend={handleSend}
        studyLimits={studyLimits}
        studyLimitMatches={studyLimitMatches}
      >
        <>
          {loading && <Loading absolute />}
          {!initialStudy && (
            <>
              <SearchSelector
                label='Select Screener…'
                options={options}
                onSelect={handleSelect}
                defaultSelected={screener?.id?.toString()}
              />
              <p className='mt-4 text-gray-500'>
                <span>
                  Candidates will automatically be added to the study{' '}
                  <span className='font-semibold'>{study.title}</span>
                </span>
              </p>
            </>
          )}
        </>
      </StudyMessageSlideOut>
    );
  } else {
    return (
      <SlideOut title='Screener invite' onClose={onClose} size='xl'>
        <div className='p-6 border-t border-gray-200'>
          {(loading || !fetchedScreeners) && <Loading absolute />}
          <p className='mb-4 text-gray-500'>
            Learn more about candidates before deciding to invite them to participate in a study.
          </p>
          {!initialStudy && (
            <SearchSelector
              inputClassName='xx-screener-dropdown'
              label='Select Screener…'
              options={options}
              onSelect={handleSelect}
              defaultSelected={screener?.id?.toString()}
            />
          )}
          {!loading && screener && study && selectedCount == 0 && <p> You need to select at least one candidate. </p>}
        </div>
      </SlideOut>
    );
  }
};
