import * as React from 'react';
import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { DEFAULT_CANDIDATES_VIEW } from '@components/CandidatesApp/CandidatesIndex';
import CandidatesListPage from '@components/CandidatesApp/CandidatesIndex/CandidatesListPage';
import { Loading } from '@components/common';
import { Toast, useToaster } from '@stores/toaster';
import { Provider as CollectionView } from '@stores/view';

const toasts: Record<string, (study: Study) => Toast> = {
  default: (s) => ({
    heading: 'We couldn’t shortlist those candidates',
    text: 'If the problem continues, please contact support.',
    icon: 'error'
  }),
  contact_access: (s) => ({
    heading: 'Some candidates have contact access restrictions',
    text: s.team_id
      ? 'Check that all candidates belong to the same team as the study.'
      : 'This study isn’t on a team and can only access the general panel.',
    icon: 'error'
  })
};

interface Props {
  study: Study;
  onAdd: (task: BackgroundTask) => void;
  refetchParties: () => void;
  studySegment: StudyLimit | null;
  teamId?: number | null;
}

export const AddCandidates: React.FC<Props> = ({ study, onAdd, refetchParties, studySegment, teamId }) => {
  const [bulkShortlist, { data, isSuccess, isLoading, isError, error }] = api.useBulkShortlistMutation();

  const showToast = useToaster();

  const handleAdd = (selectedIds: number[], query?: FilterQuery) =>
    bulkShortlist({
      studyId: study.id,
      ...(query ? { query } : { customer_ids: selectedIds })
    });

  useEffect(() => {
    if (data && isSuccess) {
      onAdd(data.background_task);
      refetchParties();
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      const reason = (error as any)?.error || 'default';
      const toast = toasts[reason] || toasts.default;

      showToast(toast(study));
    }
  }, [isError, error]);

  return (
    <div className='h-full'>
      {isLoading && <Loading />}
      <CollectionView
        default={{
          ...DEFAULT_CANDIDATES_VIEW,
          filters: '?filters[]=eligibility%20is%20true&filters[]=participated_in_studies%20includes_none%20' + study.id
        }}
        scope='candidates'
        page={`study-${study.id}`}
      >
        <CandidatesListPage addCandidatesModal teamId={teamId} onAdd={handleAdd} studySegment={studySegment} />
      </CollectionView>
    </div>
  );
};
