import React from 'react';

import { Table } from '@components/shared/Table';
import { Tooltip } from '@components/shared/Tooltip';

import * as Types from '../types';

export const activityColumnDefinitions: Types.DefinitionFn = ({ addFilter, study, getColumnLabel }) => {
  const columns: Types.DefinitionFnReturn = [
    {
      id: 'shortlisted_at',
      accessorKey: 'shortlisted_at',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='datetime'
          render={({ value, getParsedValue }) => (
            <Tooltip content={getParsedValue()} isDisabled={!value}>
              <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
            </Tooltip>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader sortIconType='date' addFilter={addFilter} {...props}>
          {getColumnLabel?.('shortlisted_at')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'requested_at',
      accessorKey: 'requested_at',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='datetime'
          render={({ value, getParsedValue }) => (
            <Tooltip content={getParsedValue()} isDisabled={!value}>
              <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
            </Tooltip>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader sortIconType='date' addFilter={addFilter} {...props}>
          {getColumnLabel?.('requested_at')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'updated_at',
      accessorKey: 'updated_at',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='datetime'
          render={({ value, getParsedValue }) => (
            <Tooltip content={getParsedValue()} isDisabled={!value}>
              <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
            </Tooltip>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader sortIconType='date' addFilter={addFilter} {...props}>
          {getColumnLabel?.('updated_at')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'completed_at',
      accessorKey: 'completed_at',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='datetime'
          render={({ value, getParsedValue }) => (
            <Tooltip content={getParsedValue()} isDisabled={!value}>
              <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
            </Tooltip>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader sortIconType='date' addFilter={addFilter} {...props}>
          {getColumnLabel?.('completed_at')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'rejected_at',
      accessorKey: 'rejected_at',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='datetime'
          render={({ value, getParsedValue }) => (
            <Tooltip content={getParsedValue()} isDisabled={!value}>
              <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
            </Tooltip>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader sortIconType='date' addFilter={addFilter} {...props}>
          {getColumnLabel?.('rejected_at')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'no_show_at',
      accessorKey: 'no_show_at',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='datetime'
          render={({ value, getParsedValue }) => (
            <Tooltip content={getParsedValue()} isDisabled={!value}>
              <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
            </Tooltip>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader sortIconType='date' addFilter={addFilter} {...props}>
          {getColumnLabel?.('no_show_at')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'opted_out_at',
      accessorKey: 'opted_out_at',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='datetime'
          render={({ value, getParsedValue }) => (
            <Tooltip content={getParsedValue()} isDisabled={!value}>
              <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
            </Tooltip>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader sortIconType='date' addFilter={addFilter} {...props}>
          {getColumnLabel?.('opted_out_at')}
        </Table.ColumnHeader>
      )
    }
  ];

  if (study?.has_screener) {
    columns.push(
      {
        id: 'invited_at',
        accessorKey: 'invited_at',
        cell: (props) => (
          <Table.Cell<Participation>
            {...props}
            type='datetime'
            render={({ value, getParsedValue }) => (
              <Tooltip content={getParsedValue()} isDisabled={!value}>
                <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
              </Tooltip>
            )}
          />
        ),
        header: (props) => (
          <Table.ColumnHeader sortIconType='date' addFilter={addFilter} {...props}>
            {getColumnLabel?.('invited_at')}
          </Table.ColumnHeader>
        )
      },
      {
        id: 'applied_at',
        accessorKey: 'applied_at',
        cell: (props) => (
          <Table.Cell<Participation>
            {...props}
            type='datetime'
            render={({ value, getParsedValue }) => (
              <Tooltip content={getParsedValue()} isDisabled={!value}>
                <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
              </Tooltip>
            )}
          />
        ),
        header: (props) => (
          <Table.ColumnHeader sortIconType='date' addFilter={addFilter} {...props}>
            {getColumnLabel?.('applied_at')}
          </Table.ColumnHeader>
        )
      }
    );
  }

  if (study) {
    if (study.style === 'video_call') {
      columns.push(
        {
          id: 'booked_at',
          accessorKey: 'booked_at',
          cell: (props) => (
            <Table.Cell<Participation>
              {...props}
              type='datetime'
              render={({ value, getParsedValue }) => (
                <Tooltip content={getParsedValue()} isDisabled={!value}>
                  <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
                </Tooltip>
              )}
            />
          ),
          header: (props) => (
            <Table.ColumnHeader sortIconType='date' {...props}>
              {getColumnLabel?.('booked_at')}
            </Table.ColumnHeader>
          )
        },
        {
          id: 'interview_at',
          accessorKey: 'interview_at',
          cell: (props) => (
            <Table.Cell<Participation>
              {...props}
              type='datetime'
              render={({ value, getParsedValue }) => (
                <Tooltip content={getParsedValue()} isDisabled={!value}>
                  <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
                </Tooltip>
              )}
            />
          ),
          header: (props) => (
            <Table.ColumnHeader sortIconType='date' {...props}>
              {getColumnLabel?.('interview_at')}
            </Table.ColumnHeader>
          )
        },
        {
          id: 'canceled_at',
          accessorKey: 'canceled_at',
          cell: (props) => (
            <Table.Cell<Participation>
              {...props}
              type='datetime'
              render={({ value, getParsedValue }) => (
                <Tooltip content={getParsedValue()} isDisabled={!value}>
                  <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
                </Tooltip>
              )}
            />
          ),
          header: (props) => (
            <Table.ColumnHeader sortIconType='date' {...props}>
              {getColumnLabel?.('canceled_at')}
            </Table.ColumnHeader>
          )
        }
      );
    } else {
      columns.push({
        id: 'started_at',
        accessorKey: 'started_at',
        cell: (props) => (
          <Table.Cell<Participation>
            {...props}
            type='datetime'
            render={({ value, getParsedValue }) => (
              <Tooltip content={getParsedValue()} isDisabled={!value}>
                <div className='px-2 text-sm truncate'>{getParsedValue()}</div>
              </Tooltip>
            )}
          />
        ),
        header: (props) => (
          <Table.ColumnHeader sortIconType='date' {...props}>
            {getColumnLabel?.('started_at')}
          </Table.ColumnHeader>
        )
      });
    }
  }

  return columns;
};
