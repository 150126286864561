import { api } from 'api/reduxApi';
import React from 'react';

import { Button, CreateCsvExportButton, Modal } from '@components/common';

interface Props {
  setOpen: (open: boolean) => void;
  subjectString: string;
  params: CsvExportParams;
}

export const ExportCsvModal: React.FC<Props> = ({ setOpen, subjectString, params }) => {
  const [createCsvExport, { isSuccess, isError, isLoading }] = api.useCreateCsvExportMutation();

  const handleCsvExport = async () => {
    await createCsvExport(params).unwrap();

    setOpen(false);
  };

  return (
    <Modal
      size='md'
      onClose={() => setOpen(false)}
      className='xx-csv-export-modal'
      renderFooter={() => (
        <div className='flex flex-row justify-end space-x-4'>
          <Button aria-label='Cancel' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <CreateCsvExportButton
            primary
            isSuccess={isSuccess}
            aria-label='Export to CSV'
            isError={isError}
            isLoading={isLoading}
            successMessage={`Exported ${subjectString} to a CSV file. You will receive an email with the link when it is ready.`}
            errorMessage='Something went wrong when exporting the CSV file. Please try again later.'
            onClick={handleCsvExport}
          />
        </div>
      )}
    >
      <div>
        <h4 className='mb-3 text-xl font-semibold tracking-tight text-gray-800'>Export {subjectString} to CSV</h4>
        <p className='mb-2'>You are about to export {subjectString} to a CSV file.</p>
        <p className='mb-2'>You will receive a link to download it when it has been processed.</p>
      </div>
    </Modal>
  );
};
