import React from 'react';
import { SVGProps } from 'react';

export const BellSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.83301 12.6875C5.9071 12.9399 6.06095 13.1615 6.27153 13.3192C6.48211 13.4768 6.73808 13.562 7.00113 13.562C7.26418 13.562 7.52016 13.4768 7.73073 13.3192C7.94131 13.1615 8.09516 12.9399 8.16926 12.6875'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M7 1.75V0.4375' stroke='currentColor' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M7 1.75C8.16032 1.75 9.27312 2.21094 10.0936 3.03141C10.9141 3.85188 11.375 4.96468 11.375 6.125C11.375 10.2352 12.25 10.9375 12.25 10.9375H1.75C1.75 10.9375 2.625 9.81983 2.625 6.125C2.625 4.96468 3.08594 3.85188 3.90641 3.03141C4.72688 2.21094 5.83968 1.75 7 1.75V1.75Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
