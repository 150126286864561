import pluralize from 'pluralize';
import * as React from 'react';
import { useState } from 'react';

import { bulkParticipationsAction } from '@api/queries';
import { Loading } from '@components/common';
import { useToaster } from '@stores/toaster';

import { UncontactableAlert } from './components/alerts';
import { StudyMessageSlideOut } from './StudyMessageSlideOut';
import { SendMessageParams } from './types';
import { filterContactable } from './utils/filters';
import { useFeature } from '@hooks/useFeature';
import { useParticipantsCounts } from './hooks/useParticipantsCounts';
import { useSendStudyMessageMutation } from '@components/StudyMessages/api';

interface Props {
  study: Study;
  participations: Participation[];
  event: StudyMessageEvent;
  title: string;
  cta: string;
  onSuccess?: () => void;
  onClose: () => void;
  allSelected?: boolean;
  query?: ServerFilterQuery;
  selectedParticipationIds?: number[];
  previewParticipation: Participation;
}

const MESSAGES = {
  ad_hoc: 'ad hoc message',
  started_reminder: 'a reminder',
  booked_reminder: 'a reminder',
  invited_reminder: 'a reminder',
  reschedule_request: 'reschedule requests'
};

export const GenericMessageSlideOut: React.FC<Props> = ({
  study,
  participations,
  title,
  cta,
  event,
  onSuccess,
  onClose,
  allSelected,
  query,
  selectedParticipationIds,
  previewParticipation
}) => {
  const serversideParticipantsEnabled = useFeature('serverside_participations');
  const [createStudyMessage] = useSendStudyMessageMutation();
  const showToast = useToaster();
  const [loading, setLoading] = useState<boolean>();
  const localContactable = filterContactable(study.comms_medium, participations || []);

  const { contactableCount: serverContactable, isLoading } = useParticipantsCounts({
    studyId: study.id,
    query,
    ids: selectedParticipationIds,
    skipInvitable: true,
    skipIneligible: true,
    skipReinviteable: true
  });

  const contactableCount: number = serversideParticipantsEnabled
    ? serverContactable || 0
    : localContactable?.length || 0;

  const count: number = selectedParticipationIds?.length || query?.count || 0;

  async function handleSend({ message, sender }: SendMessageParams) {
    setLoading(true);

    const idsOrQuery: IdsOrFilterQuery =
      allSelected && serversideParticipantsEnabled && query ? { query } : { ids: selectedParticipationIds };

    if (count > 0 && event !== 'ad_hoc') {
      await bulkParticipationsAction(study.id, {
        action: event as ParticipationAction,
        ...idsOrQuery,
        fields: []
      });
    }

    const participantsFilterQuery = query as ParticipantsFilterQuery;

    const participationIdsOrQuery =
      allSelected && serversideParticipantsEnabled && query
        ? { query: participantsFilterQuery }
        : { participation_ids: selectedParticipationIds };

    const resp = await createStudyMessage({
      id: message.id,
      studyId: study.id,
      ...participationIdsOrQuery,
      count,
      sender
    }).unwrap();

    setLoading(false);

    if (resp) {
      onSuccess?.();

      showToast({
        heading: `${pluralize('Email', resp.count)} sent!`,
        text: `${pluralize('candidates', resp.count, true)} sent ${MESSAGES[event]}.`,
        icon: 'success'
      });
    } else {
      showToast({
        heading: 'An error occurred!',
        text: 'Please try again later.',
        icon: 'error'
      });
    }
  }

  return (
    <StudyMessageSlideOut
      customizable
      title={title}
      study={study}
      cta={cta}
      event={event}
      totalCount={count}
      inviteableCount={count}
      contactableCount={contactableCount}
      previewCandidate={previewParticipation.customer}
      previewParticipation={previewParticipation}
      onClose={onClose}
      onSend={handleSend}
    >
      {(loading || isLoading) && <Loading absolute />}
      <UncontactableAlert diff={count - contactableCount} medium={study.comms_medium} total={count} />
    </StudyMessageSlideOut>
  );
};
