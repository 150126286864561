import * as React from 'react';
import cn from 'classnames';

import { api } from '@api/reduxApi';
import { GQSurveyBuilder } from '@components/GQSurveyBuilder';
import { api as surveyBuilderApi, SurveyBuilder } from '@components/SurveyBuilder';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';

import { SurveyBuilderHeader } from './SurveyBuilderHeader';

type Props = {
  study?: Study;
  screener: Screener;
  builderTitle?: boolean;
  page: 'study' | 'template' | 'studyBuilder' | 'templateBuilder';
  onSave?: (v: Study) => void;
  preScreener?: boolean;
};

export const StudyScreenerBuilder: React.FC<Props> = ({ page, onSave, study, screener, builderTitle, preScreener }) => {
  const [updateStudyScreener] = api.useUpdateStudyScreenerMutation();

  const canUpdate = usePermission('updateStudy')();

  const showToast = useToaster();

  const { data: unmoderatedVersion, isLoading: isLoadingVersion } = surveyBuilderApi.useGetUnmoderatedVersionsQuery(
    study?.id,
    { skip: study?.style !== 'unmoderated_test' }
  );

  const save = async (questions: ScreenerField[]): Promise<Screener | null> => {
    return await updateStudyScreener({
      ...screener,
      fields: questions
    })
      .unwrap()
      .catch((e) => {
        const errorMessage = Array.isArray(e.data?.errors?.['fields.base'])
          ? e.data.errors['fields.base'].join(',')
          : 'Something went wrong. Please try again later.';
        showToast({
          heading: 'Failed to update!',
          text: errorMessage,
          icon: 'error'
        });
        return null;
      });
  };

  if (isLoadingVersion) {
    return null;
  }

  if (!preScreener && study && study.style === 'unmoderated_test' && unmoderatedVersion?.version === 'v2') {
    return (
      <div className='max-w-7xl flex flex-col items-stretch mx-auto'>
        <SurveyBuilderHeader
          className={cn('pb-6', { 'pt-gutter': builderTitle })}
          showHeading={builderTitle}
          surveyId={study.id}
        />
        <SurveyBuilder surveyId={study.id} />
      </div>
    );
  }

  return (
    <GQSurveyBuilder
      study={study}
      page={page}
      builderTitle={builderTitle}
      editable={canUpdate}
      screener={screener}
      save={save}
      onSave={onSave}
    />
  );
};
