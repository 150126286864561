import { ListViewTable } from 'components/StudiesApp/components/StudiesIndex/components/ListViewTable';
import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Provider as CollectionView } from '@stores/view';

import { useStudies } from '@hooks/useStudies';
import { useUser } from '@hooks/useUser';
import { useFeature } from '@hooks/useFeature';
import { api } from '@api/reduxApi';

const DEFAULT_VISIBLE = 3;
export const ActiveStudies: React.FC<{ setHitsNumber: React.Dispatch<React.SetStateAction<number>> }> = ({
  setHitsNumber
}) => {
  const { studies, loading } = useStudies();

  const hasPaginatedStudies = useFeature('paginated_studies');
  const { data: studyAttrs, isLoading: attrsLoading, isSuccess, isError } = api.useGetStudyAttrsQuery();


  const user = useUser();

  const [collapsed, setCollapsed] = useState(true);

  const filteredStudies = useMemo(
    () => studies.filter((study) => study.state === 'active' && study.owner_id === user.id && !study.is_phantom),
    [studies]
  );

  useEffect(() => {
    setHitsNumber((prev) => prev + filteredStudies.length);
  }, [filteredStudies]);

  const studiesToShow = collapsed ? filteredStudies.slice(0, DEFAULT_VISIBLE) : filteredStudies;

  if (loading || attrsLoading) {
    return <Skeleton className='h-96 mt-12 mb-4' />;
  }

  if (!studiesToShow.length) return null;

  return (
    <div>
      <h2 className='h600 mb-4 font-bold'>My active studies</h2>
      <div className='w-full bg-white border border-gray-200 overflow-hidden rounded-md'>
        <div className='overflow-x-auto' id='active-studies'>
          <CollectionView
            scope={hasPaginatedStudies ? 'paginated_studies' : 'studies'}
            defaultable
            default={{ sort: { value: 'updated_at', desc: true }, filters: '', layout: 'list' }}
          >
            <ListViewTable studyAttrs={studyAttrs} records={studiesToShow} borderColor='none' />
          </CollectionView>
        </div>
        {filteredStudies.length > DEFAULT_VISIBLE && (
          <div className='px-6 py-3 text-sm'>
            <a onClick={() => setCollapsed(!collapsed)} className='cursor-pointer'>
              {collapsed ? `View all` : 'Show less'}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
