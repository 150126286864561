import cn from 'classnames';
import * as React from 'react';
import { useRef, useState } from 'react';

import { Option, PopperDropdown, PopperDropdownButton, PopperDropdownLink, Text } from '@components/common';
import { CheckSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { useOnClickOutside } from '@components/utils';

interface Props {
  value?: string | null;
  onChange: (args: Partial<CollectionView['sort']>) => void;
  options: Option[];
  trackKey?: string;
  type?: 'sort' | 'group';
  wrapperClass?: string;
}

export const SortDropdown: React.FC<Props> = ({ value, wrapperClass, type = 'sort', onChange, options, trackKey }) => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const selectedOption = options.find((o) => o.value === value);

  const sortedOptions = [...options];

  sortedOptions.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  useOnClickOutside(ref, () => open && setOpen(false));

  return (
    <div className={cn('relative', wrapperClass)}>
      <PopperDropdown
        name={`${type}-dropdown`}
        text={
          <Text h='400' className='flex-1 text-left'>
            {selectedOption?.label || 'None'}
          </Text>
        }
        popperProps={{ zIndex: 50, renderOnBodyRoot: false }}
        closeOnDropdownClick={true}
        data-testid={`${type}-dropdown`}
        aria-label={`${type} by ${selectedOption?.label}`}
        dropdownClass='max-h-72 overflow-y-auto'
        className='whitespace-nowrap flex items-center justify-between rounded-md px-4 h-8 space-x-2 bg-white border-gray-200 border w-full'
        tooltip={
          !selectedOption?.label ? undefined : (
            <Text h='400' className='inline' color='white'>
              Sort by: {selectedOption?.label}
            </Text>
          )
        }
      >
        {sortedOptions.map((option) => (
          <PopperDropdownButton
            key={option.value}
            data-testid={option.value}
            onClick={() => {
              onChange({ value: option.value });

              if (trackKey) {
                track('sorted', { page: trackKey });
              }
            }}
            className='flex items-center justify-between'
          >
            <Text
              color={option.value === value ? 'indigo-600' : undefined}
              className='whitespace-nowrap flex-1 mr-1'
              h='400'
            >
              {option.label}
            </Text>
            {option.value === value && <CheckSVG />}
          </PopperDropdownButton>
        ))}
      </PopperDropdown>
    </div>
  );
};
