import { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Enums, Models } from '@components/SurveyBuilder';

import * as Types from '../types';

interface UseCardSortTaskTrackingArgs {
  block: Models.Block<Enums.Kind.cardSort>;
  debounceDelay?: number;
  onEvent?: (events: Types.CardSortEvent[]) => void;
}

interface UseCardSortTaskTrackingReturn {
  events: Types.CardSortEvent[];
  isComplete: boolean;
  results: Types.CardSortResult[];
  onMoveCard: (card: string, from: string | null, to: string | null) => void;
  onCreateCategory: (category: string) => void;
  onEditCategory: (previous: string, category: string) => void;
  onDeleteCategory: (category: string) => void;
}

export const useCardSortTaskTracking = (args: UseCardSortTaskTrackingArgs): UseCardSortTaskTrackingReturn => {
  const { block, debounceDelay = 500, onEvent } = args;

  const [events, setEvents] = useState<Types.CardSortEvent[]>([]);
  const [results, setResults] = useState<Types.CardSortResult[]>([]);

  const cachedEvents = useRef<Types.CardSortEvent[]>([]);

  const cachedResults = useRef<Types.CardSortResult[]>(
    block.blockable.categories.map((c) => ({ name: c, cards: [], custom: false }))
  );

  const isComplete = block.blockable.cards.length === cachedResults.current.reduce((acc, c) => acc + c.cards.length, 0);

  const send = () => {
    onEvent?.(cachedEvents.current);
    setEvents(cachedEvents.current);
    setResults(cachedResults.current);
  };

  const { callback: debouncedSend } = useDebouncedCallback(send, debounceDelay);

  const onMoveCard = (card: string, from: string | null, to: string | null) => {
    const event: Types.CardSortEvent<'move_card'> = {
      at: new Date(),
      card,
      from,
      to,
      event: 'move_card'
    };

    cachedEvents.current.push(event);

    if (to) {
      const targetCategory = cachedResults.current.find((c) => c.name === to);

      if (targetCategory) {
        targetCategory.cards.push(card);
      } else {
        cachedResults.current.push({ name: to, cards: [card], custom: false });
      }
    }

    if (from) {
      const fromCategory = cachedResults.current.find((c) => c.name === from);

      if (fromCategory) {
        fromCategory.cards = fromCategory.cards.filter((c) => c !== card);
      }
    }

    debouncedSend();
  };

  const onCreateCategory = (category: string) => {
    const event: Types.CardSortEvent<'create_category'> = {
      at: new Date(),
      category,
      event: 'create_category'
    };

    cachedEvents.current.push(event);
    cachedResults.current.push({ name: category, cards: [], custom: true });

    debouncedSend();
  };

  const onEditCategory = (category: string, previous: string) => {
    const event: Types.CardSortEvent<'edit_category'> = {
      at: new Date(),
      category,
      previous,
      event: 'edit_category'
    };

    cachedEvents.current.push(event);

    const categoryIndex = cachedResults.current.findIndex((c) => c.name === previous);

    if (categoryIndex !== -1) {
      cachedResults.current[categoryIndex].name = category;
    }

    debouncedSend();
  };

  const onDeleteCategory = (category: string) => {
    const event: Types.CardSortEvent<'delete_category'> = {
      at: new Date(),
      category,
      event: 'delete_category'
    };

    cachedEvents.current.push(event);

    const categoryIndex = cachedResults.current.findIndex((c) => c.name === category);

    if (categoryIndex !== -1) {
      cachedResults.current.splice(categoryIndex, 1);
    }

    debouncedSend();
  };

  return { events, isComplete, results, onMoveCard, onCreateCategory, onEditCategory, onDeleteCategory };
};
