import * as React from 'react';
import { useState } from 'react';

import { Button, Modal, PopperDropdown, PopperDropdownButton } from '@components/common';
import { PencilSVG, SolidKebabSVG, UnmergeSVG } from '@components/svgs';

export const CategoriesOptionsDropdown: React.FC = () => {
  const [unmergeModalOpen, setUnmergeModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  return (
    <>
      {unmergeModalOpen && (
        <Modal icon='danger' title='Unmerge categories' onClose={() => setUnmergeModalOpen(false)}>
          <p className='pb-12 text-gray-700'>
            You are about to unmerge previously merged categories. All categories will return to their previous states.
          </p>
          <div className='flex justify-end space-x-4'>
            <Button disabled={loading} onClick={() => setUnmergeModalOpen(false)}>
              Cancel
            </Button>
            <Button data-testid='Confirm' disabled={loading} danger>
              Confirm and unmerge
            </Button>
          </div>
        </Modal>
      )}

      <PopperDropdown
        text
        hideCaret
        name='more'
        buttonClassName='border-none rounded-full flex-shrink-0 pt-2 pr-2 pb-2 pl-2'
        renderIcon={() => <SolidKebabSVG className='w-4 h-4 flex-shrink-0' />}
      >
        <PopperDropdownButton>
          <PencilSVG className='inline mr-1 -mt-1' />
          Edit
        </PopperDropdownButton>
        <PopperDropdownButton onClick={() => setUnmergeModalOpen(true)}>
          <UnmergeSVG className='inline mr-1 -mt-1' />
          Unmerge
        </PopperDropdownButton>
      </PopperDropdown>
    </>
  );
};
