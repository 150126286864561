import { TabValues } from '@components/ScreenerResults/components/CardSortResponse/types';

export const TABS: TabValues[] = ['table', 'cards', 'categories', 'agreement_matrix'];

export const LABELS: Record<TabValues, string> = {
  table: 'Table',
  cards: 'Cards',
  categories: 'Categories',
  agreement_matrix: 'Agreement matrix'
};
