import cn from 'classnames';
import pluralize from 'pluralize';
import * as React from 'react';
import { useState } from 'react';

import { bulkParticipationsAction } from '@api/queries';
import { Button, Dropdown, DropdownLink, TippyOrNot } from '@components/common';
import { Toast, useToaster } from '@components/stores/toaster';
import { ExportSVG, SingleUserSVG, VerticalDotsSVG } from '@components/svgs';
import { track } from '@components/tracking';

import {
  useBookMutation,
  useCancelMutation,
  useConsentFormExportMutation,
  useMarkInvitedMutation,
  useNoShowMutation,
  useRemoveMutation,
  useShortlistMutation,
  useStartMutation
} from '../api';
import {
  AD_HOC_MESSAGE_SLIDEOUT,
  CANCEL_INTERVIEW_SLIDEOUT,
  CANCEL_TASK_SLIDEOUT,
  COMPLETE_AND_THANKS,
  FUND_STUDY,
  MANUAL_RESCHEDULE_MODAL,
  MANUAL_SCHEDULE_SLIDEOUT,
  RESCHEDULE_REQUEST_SLIDEOUT,
  SEND_INVITED_REMINDER,
  SEND_INVITES,
  SEND_INVITES_DROPDOWN,
  SEND_REMINDERS,
  SEND_SCREENER,
  SEND_THANKS,
  StudyAction,
  UPDATE_MODERATOR
} from './ACTIONS';
import { useFeature } from '@hooks/useFeature';

const NOTIFICATIONS = {
  remove: 'removed',
  no_show: 'marked as no show',
  book: 'marked as booked',
  complete: 'marked as completed',
  mark_invited: 'marked as invited',
  shortlist: 'shortlisted',
  cancel: 'canceled',
  start: 'started',
  consent_form_export: 'able to generate a consent form export'
};

interface Props {
  study: Study;
  participations?: Participation[];
  selectedIds: number[];
  currentStatus: Participation['status'];
  mode?: StudyAction | null;
  setMode: (mode: StudyAction) => void;
  closeAll: () => void;
  onSuccess?: (backgroundTask?: BackgroundTask) => void;
  setLoading?: (loading: boolean) => void;
  onSlideoutActions?: boolean;
  closeProfileSlideout?: () => void;
  canSchedule?: boolean;
  medium?: boolean;
  renderExportCSV?: () => React.ReactNode;
  candidateId?: number;
  allSelected?: boolean;
  query?: ServerFilterQuery;
}

export const BulkActions: React.FC<Props> = ({
  selectedIds,
  study,
  participations,
  currentStatus,
  mode,
  setMode,
  onSuccess,
  setLoading,
  closeAll,
  onSlideoutActions,
  closeProfileSlideout,
  canSchedule,
  medium,
  renderExportCSV,
  candidateId,
  allSelected,
  query
}) => {
  const serversideParticipantsEnabled = useFeature('serverside_participations');

  const [showMenu, setShowMenu] = useState<'compose' | 'mark_as' | 'reschedule' | 'three_dots' | null>();
  const [markInvited] = useMarkInvitedMutation();
  const [book] = useBookMutation();
  const [remove] = useRemoveMutation();
  const [noShow] = useNoShowMutation();
  const [cancel] = useCancelMutation();
  const [start] = useStartMutation();
  const [shortlist] = useShortlistMutation();
  const [consentFormExport] = useConsentFormExportMutation();

  const actionsMutations = {
    mark_invited: markInvited,
    book: book,
    remove: remove,
    no_show: noShow,
    cancel: cancel,
    start: start,
    shortlist: shortlist,
    consent_form_export: consentFormExport
  };

  const showToast = useToaster();

  const selectedIdsSet = new Set(selectedIds);
  const selectedParties = participations?.filter(({ id }) => selectedIdsSet.has(id));

  const canContact = study.state !== 'pending';

  const hasConsentForm = !!study.consent_form_id;

  const handleBulkAction = async (action: ParticipationAction) => {
    setShowMenu(null);
    setLoading?.(true);

    const notificationAction = NOTIFICATIONS[action];

    const successNotification: Toast = {
      icon: 'success',
      heading: `Successfully ${notificationAction}!`,
      text: `${pluralize('participants', selectedIds.length, true)} ${notificationAction}.`
    };

    const oldActions = ['complete'];

    if (oldActions.includes(action)) {
      const resp = await bulkParticipationsAction(study.id, {
        action,
        ...(allSelected && serversideParticipantsEnabled ? { query } : { ids: selectedIds })
      });

      if (resp) {
        onSuccess?.();
        showToast(successNotification);
      } else {
        const errorNotification: Toast = {
          icon: 'error',
          heading: `Something went wrong!`,
          text: `${pluralize('participants', selectedIds.length, true)} were not ${notificationAction}.`
        };
        showToast(errorNotification);
      }
    } else {
      try {
        const backgroundTask = await actionsMutations[action]({
          studyId: study.id,
          ...(allSelected && serversideParticipantsEnabled ? { query } : { ids: selectedIds })
        }).unwrap();

        if (backgroundTask) {
          showToast(successNotification);
          onSuccess?.(backgroundTask);
        } else {
          const errorNotification: Toast = {
            icon: 'error',
            heading: `Something went wrong!`,
            text: `${pluralize('participants', selectedIds.length, true)} were not ${notificationAction}.`
          };
          showToast(errorNotification);
        }
      } catch (e) {
        const errorNotification: Toast = {
          icon: 'error',
          heading: `Something went wrong!`,
          text: `${pluralize('participants', selectedIds.length, true)} were not ${notificationAction}.`
        };
        showToast(errorNotification);
      }
    }

    setLoading?.(false);
  };
  const handleClose = () => {
    onSlideoutActions ? setShowMenu(null) : closeAll();
  };

  const onLinkClick = (action: StudyAction): void => {
    onSlideoutActions ? closeProfileSlideout?.() : setShowMenu(null);
    setMode(action);
  };

  const onSendClick = () => {
    if (onSlideoutActions) {
      setShowMenu('compose');
    } else {
      mode === SEND_INVITES_DROPDOWN ? closeAll() : setMode(SEND_INVITES_DROPDOWN);
    }
  };

  const handleCancel = () => {
    if (study.style === 'video_call') {
      onLinkClick(CANCEL_INTERVIEW_SLIDEOUT);
    } else {
      handleBulkAction('cancel');
    }
  };

  const handleClickManualSchedule = () => {
    track('clicked_manual_schedule');
    if (canSchedule) {
      closeProfileSlideout?.();
      setMode(MANUAL_SCHEDULE_SLIDEOUT);
    }
    setShowMenu(null);
  };

  const allPartiesCanBeRescheduled = selectedParties?.every((p) => p.can_request_reschedule);
  const showManualReschedule = allPartiesCanBeRescheduled && selectedParties && selectedParties.length === 1;
  const showBulkRescheduleRequest = allPartiesCanBeRescheduled;
  const showThreeDotsDropdown = renderExportCSV || hasConsentForm || candidateId;

  const getButtonClass = (testClass?: string) => cn(testClass, { 'mx-2 desktop:mx-0 bg-white': !onSlideoutActions });

  switch (currentStatus) {
    case 'invited':
      return (
        <>
          <Dropdown
            medium={medium}
            aboveButton={onSlideoutActions}
            onClick={() => setShowMenu('mark_as')}
            isOpen={showMenu === 'mark_as'}
            onClose={() => setShowMenu(null)}
            text='Mark as…'
            className={getButtonClass('xx-mark-as-dropdown')}
          >
            {canContact && (
              <DropdownLink onClick={() => handleBulkAction('mark_invited')}>Mark as invited</DropdownLink>
            )}
            {canContact && study.style === 'video_call' && (
              <TippyOrNot
                show={!canSchedule}
                content='For this action, you need to increase the study participant limit.'
              >
                <DropdownLink disabled={!canSchedule} onClick={handleClickManualSchedule}>
                  Mark as scheduled…
                </DropdownLink>
              </TippyOrNot>
            )}
            {canContact && study.style !== 'video_call' && (
              <DropdownLink onClick={() => handleBulkAction('start')}>Mark as started</DropdownLink>
            )}
            <DropdownLink className='xx-remove-cta' onClick={() => handleBulkAction('remove')}>
              Mark as removed
            </DropdownLink>
          </Dropdown>
          {canContact && (
            <Dropdown
              medium={medium}
              className={getButtonClass()}
              primary
              aboveButton={onSlideoutActions}
              text='Compose'
              icon='composeEmail'
              disabled={study.state === 'paused'}
              isOpen={onSlideoutActions ? showMenu === 'compose' : mode === SEND_INVITES_DROPDOWN}
              onClose={handleClose}
              onClick={onSendClick}
            >
              <DropdownLink
                onClick={() => {
                  if (study.incentive_method === 'tremendous' && study.funding.usd_outstanding > 0) {
                    setMode(FUND_STUDY);
                  } else {
                    setMode(SEND_INVITES);
                    closeProfileSlideout?.();
                  }
                }}
              >
                Participation invite
              </DropdownLink>
              {study.has_screener && (
                <DropdownLink
                  onClick={() => {
                    setMode(SEND_SCREENER);
                    closeProfileSlideout?.();
                  }}
                >
                  Resend screener
                </DropdownLink>
              )}
              <DropdownLink onClick={() => onLinkClick(AD_HOC_MESSAGE_SLIDEOUT)}>Ad hoc message</DropdownLink>
            </Dropdown>
          )}
          {showThreeDotsDropdown && (
            <Dropdown
              medium={medium}
              aboveButton={onSlideoutActions}
              onClick={() => setShowMenu('three_dots')}
              isOpen={showMenu === 'three_dots'}
              onClose={() => setShowMenu(null)}
              text={<VerticalDotsSVG />}
              hideCaret
              className={getButtonClass('xx-three-dots-dropdown')}
              dataTestId='three-dots-dropdown'
            >
              {hasConsentForm && (
                <DropdownLink onClick={() => handleBulkAction('consent_form_export')}>
                  <ExportSVG />
                  <div className='whitespace-nowrap ml-2'>Export consent form</div>
                </DropdownLink>
              )}
              {renderExportCSV?.()}
              {candidateId && (
                <DropdownLink className='flex items-center' spa href={`/candidates/${candidateId}`}>
                  <SingleUserSVG />
                  <div className='whitespace-nowrap ml-2'>View on full page</div>
                </DropdownLink>
              )}
            </Dropdown>
          )}
        </>
      );
    case 'shortlisted':
      return (
        <>
          <Dropdown
            medium={medium}
            aboveButton={onSlideoutActions}
            onClick={() => setShowMenu('mark_as')}
            isOpen={showMenu === 'mark_as'}
            onClose={() => setShowMenu(null)}
            text='Mark as…'
            className={getButtonClass('xx-mark-as-dropdown')}
          >
            {canContact && (
              <DropdownLink onClick={() => handleBulkAction('mark_invited')}>Mark as invited</DropdownLink>
            )}
            {canContact && study.style === 'video_call' && (
              <TippyOrNot
                show={!canSchedule}
                content='For this action, you need to increase the study participant limit.'
              >
                <DropdownLink disabled={!canSchedule} onClick={handleClickManualSchedule}>
                  Mark as scheduled…
                </DropdownLink>
              </TippyOrNot>
            )}
            {canContact && study.style !== 'video_call' && (
              <DropdownLink onClick={() => handleBulkAction('start')}>Mark as started</DropdownLink>
            )}
            <DropdownLink className='xx-remove-cta' onClick={() => handleBulkAction('remove')}>
              Mark as removed
            </DropdownLink>
          </Dropdown>
          {['active', 'pending'].includes(study.state) && (
            <TippyOrNot show={study.state === 'pending'} content='Study requires approval before you can invite.'>
              <Dropdown
                medium={medium}
                className={getButtonClass()}
                primary
                disabled={study.state === 'pending'}
                aboveButton={onSlideoutActions}
                text='Compose'
                icon='composeEmail'
                dataTestId='Compose'
                isOpen={onSlideoutActions ? showMenu === 'compose' : mode === SEND_INVITES_DROPDOWN}
                onClose={handleClose}
                onClick={onSendClick}
              >
                {study.has_screener && (
                  <DropdownLink
                    onClick={() => {
                      if (study.incentive_method === 'tremendous' && study.funding.usd_outstanding > 0) {
                        setMode(FUND_STUDY);
                      } else {
                        setMode(SEND_SCREENER);
                        closeProfileSlideout?.();
                      }
                    }}
                  >
                    Screener invite
                  </DropdownLink>
                )}
                <DropdownLink
                  onClick={() => {
                    if (study.incentive_method === 'tremendous' && study.funding.usd_outstanding > 0) {
                      setMode(FUND_STUDY);
                    } else {
                      setMode(SEND_INVITES);
                      closeProfileSlideout?.();
                    }
                  }}
                >
                  Participation invite
                </DropdownLink>
                <DropdownLink aria-label='Ad hoc' onClick={() => onLinkClick(AD_HOC_MESSAGE_SLIDEOUT)}>
                  Ad hoc message
                </DropdownLink>
              </Dropdown>
            </TippyOrNot>
          )}
          {showThreeDotsDropdown && (
            <Dropdown
              medium={medium}
              aboveButton={onSlideoutActions}
              onClick={() => setShowMenu('three_dots')}
              isOpen={showMenu === 'three_dots'}
              onClose={() => setShowMenu(null)}
              text={<VerticalDotsSVG />}
              hideCaret
              className={getButtonClass('xx-three-dots-dropdown')}
              dataTestId='three-dots-dropdown'
            >
              {hasConsentForm && (
                <DropdownLink
                  className='flex-nowrap flex items-center'
                  onClick={() => handleBulkAction('consent_form_export')}
                >
                  <ExportSVG />
                  <div className='whitespace-nowrap ml-2'>Export consent form</div>
                </DropdownLink>
              )}
              {renderExportCSV?.()}
              {candidateId && (
                <DropdownLink className='flex items-center' spa href={`/candidates/${candidateId}`}>
                  <SingleUserSVG />
                  <div className='whitespace-nowrap ml-2'>View on full page</div>
                </DropdownLink>
              )}
            </Dropdown>
          )}
        </>
      );
    case 'requested':
      return (
        <>
          <Dropdown
            medium={medium}
            aboveButton={onSlideoutActions}
            onClick={() => setShowMenu('mark_as')}
            isOpen={showMenu === 'mark_as'}
            onClose={() => setShowMenu(null)}
            text='Mark as…'
            className={getButtonClass('xx-mark-as-dropdown')}
          >
            {canContact && study.style === 'video_call' && (
              <TippyOrNot
                show={!canSchedule}
                content='For this action, you need to increase the study participant limit.'
              >
                <DropdownLink disabled={!canSchedule} onClick={handleClickManualSchedule}>
                  Mark as scheduled…
                </DropdownLink>
              </TippyOrNot>
            )}
            {canContact && (
              <DropdownLink className='xx-completed-cta' onClick={() => onLinkClick(COMPLETE_AND_THANKS)}>
                Mark as completed…
              </DropdownLink>
            )}
            <DropdownLink className='xx-remove-cta' onClick={() => handleBulkAction('remove')}>
              Mark as removed
            </DropdownLink>
          </Dropdown>
          {canContact && (
            <Dropdown
              className={getButtonClass()}
              primary
              medium={medium}
              aboveButton={onSlideoutActions}
              text='Compose'
              icon='composeEmail'
              isOpen={showMenu === 'compose'}
              onClose={() => setShowMenu(null)}
              onClick={() => setShowMenu('compose')}
            >
              <DropdownLink
                onClick={() => {
                  onLinkClick(SEND_INVITES);
                  if (study.incentive_method === 'tremendous' && study.funding.usd_outstanding > 0) {
                    setMode(FUND_STUDY);
                  } else {
                    setMode(SEND_INVITES);
                    closeProfileSlideout?.();
                  }
                }}
              >
                Participation invite
              </DropdownLink>
              <DropdownLink onClick={() => onLinkClick(SEND_INVITED_REMINDER)}>Invitation reminder</DropdownLink>
              <DropdownLink onClick={() => onLinkClick(AD_HOC_MESSAGE_SLIDEOUT)}>Ad hoc message</DropdownLink>
            </Dropdown>
          )}
          {showThreeDotsDropdown && (
            <Dropdown
              medium={medium}
              aboveButton={onSlideoutActions}
              onClick={() => setShowMenu('three_dots')}
              isOpen={showMenu === 'three_dots'}
              onClose={() => setShowMenu(null)}
              text={<VerticalDotsSVG />}
              hideCaret
              className={getButtonClass('xx-three-dots-dropdown')}
              dataTestId='three-dots-dropdown'
            >
              {hasConsentForm && (
                <DropdownLink
                  className='flex-nowrap flex items-center'
                  onClick={() => handleBulkAction('consent_form_export')}
                >
                  <ExportSVG />
                  <div className='whitespace-nowrap ml-2'>Export consent form</div>
                </DropdownLink>
              )}
              {renderExportCSV?.()}
              {candidateId && (
                <DropdownLink className='flex items-center' spa href={`/candidates/${candidateId}`}>
                  <SingleUserSVG />
                  <div className='whitespace-nowrap ml-2'>View on full page</div>
                </DropdownLink>
              )}
            </Dropdown>
          )}
        </>
      );
    case 'applied':
      return (
        <>
          <Dropdown
            medium={medium}
            aboveButton={onSlideoutActions}
            onClick={() => setShowMenu('mark_as')}
            isOpen={showMenu === 'mark_as'}
            onClose={() => setShowMenu(null)}
            text='Mark as…'
            className={getButtonClass('xx-mark-as-dropdown')}
          >
            {canContact && (
              <>
                <DropdownLink onClick={() => handleBulkAction('shortlist')}>Mark as shortlisted</DropdownLink>
                <DropdownLink onClick={() => handleBulkAction('mark_invited')}>Mark as invited</DropdownLink>
                {study.style === 'video_call' && (
                  <TippyOrNot
                    show={!canSchedule}
                    content='For this action, you need to increase the study participant limit.'
                  >
                    <DropdownLink disabled={!canSchedule} onClick={handleClickManualSchedule}>
                      Mark as scheduled…
                    </DropdownLink>
                  </TippyOrNot>
                )}
              </>
            )}
            <DropdownLink className='xx-remove-cta' onClick={() => handleBulkAction('remove')}>
              Mark as removed
            </DropdownLink>
          </Dropdown>
          {canContact && (
            <Dropdown
              medium={medium}
              className={getButtonClass()}
              primary
              aboveButton={onSlideoutActions}
              text='Compose'
              icon='composeEmail'
              isOpen={showMenu === 'compose'}
              onClose={() => setShowMenu(null)}
              onClick={() => setShowMenu('compose')}
            >
              <DropdownLink className='xx-shortlist-cta' onClick={() => onLinkClick(SEND_INVITES)}>
                Participation invite
              </DropdownLink>
              <DropdownLink onClick={() => onLinkClick(AD_HOC_MESSAGE_SLIDEOUT)}>Ad hoc message</DropdownLink>
            </Dropdown>
          )}
          {showThreeDotsDropdown && (
            <Dropdown
              medium={medium}
              aboveButton={onSlideoutActions}
              onClick={() => setShowMenu('three_dots')}
              isOpen={showMenu === 'three_dots'}
              onClose={() => setShowMenu(null)}
              text={<VerticalDotsSVG />}
              hideCaret
              className={getButtonClass('xx-three-dots-dropdown')}
              dataTestId='three-dots-dropdown'
            >
              {hasConsentForm && (
                <DropdownLink
                  className='flex-nowrap flex items-center'
                  onClick={() => handleBulkAction('consent_form_export')}
                >
                  <ExportSVG />
                  <div className='whitespace-nowrap ml-2'>Export consent form</div>
                </DropdownLink>
              )}
              {renderExportCSV?.()}
              {candidateId && (
                <DropdownLink className='flex items-center' spa href={`/candidates/${candidateId}`}>
                  <SingleUserSVG />
                  <div className='whitespace-nowrap ml-2'>View on full page</div>
                </DropdownLink>
              )}
            </Dropdown>
          )}
        </>
      );
    case 'booked':
      return (
        <>
          {canContact && (
            <>
              {!onSlideoutActions && (showManualReschedule || showBulkRescheduleRequest) && (
                <Dropdown
                  medium={medium}
                  className={getButtonClass()}
                  aboveButton={onSlideoutActions}
                  text='Reschedule…'
                  isOpen={showMenu === 'reschedule'}
                  onClose={() => setShowMenu(null)}
                  onClick={() => setShowMenu('reschedule')}
                >
                  <DropdownLink
                    disabled={!showBulkRescheduleRequest}
                    onClick={() => onLinkClick(RESCHEDULE_REQUEST_SLIDEOUT)}
                  >
                    Send reschedule request
                  </DropdownLink>
                  <TippyOrNot show={!showManualReschedule} content='Select only 1 candidate to manually choose a time.'>
                    <DropdownLink disabled={!showManualReschedule} onClick={() => onLinkClick(MANUAL_RESCHEDULE_MODAL)}>
                      Choose new time
                    </DropdownLink>
                  </TippyOrNot>
                </Dropdown>
              )}
              <Dropdown
                medium={medium}
                className={getButtonClass('xx-mark-as-dropdown')}
                aboveButton={onSlideoutActions}
                text='Mark as…'
                isOpen={showMenu === 'mark_as'}
                onClose={() => setShowMenu(null)}
                onClick={() => setShowMenu('mark_as')}
              >
                <DropdownLink onClick={() => onLinkClick(COMPLETE_AND_THANKS)}>Mark as completed…</DropdownLink>
                <DropdownLink onClick={() => handleCancel()}>
                  Mark as canceled{study.style === 'video_call' && '…'}
                </DropdownLink>
                <DropdownLink onClick={() => handleBulkAction('no_show')}>Mark as no-showed</DropdownLink>
              </Dropdown>
              <Dropdown
                primary
                medium={medium}
                aboveButton={onSlideoutActions}
                onClick={() => setShowMenu('compose')}
                isOpen={showMenu === 'compose'}
                onClose={() => setShowMenu(null)}
                text='Compose'
                icon='composeEmail'
                className={getButtonClass('xx-dropdown-cta')}
              >
                <DropdownLink onClick={() => onLinkClick(SEND_REMINDERS)}>Interview reminder</DropdownLink>
                <DropdownLink onClick={() => onLinkClick(AD_HOC_MESSAGE_SLIDEOUT)}>Ad hoc message</DropdownLink>
              </Dropdown>
            </>
          )}

          {showThreeDotsDropdown && (
            <Dropdown
              medium={medium}
              aboveButton={onSlideoutActions}
              onClick={() => setShowMenu('three_dots')}
              isOpen={showMenu === 'three_dots'}
              onClose={() => setShowMenu(null)}
              text={<VerticalDotsSVG />}
              hideCaret
              className={getButtonClass('xx-three-dots-dropdown')}
              dataTestId='three-dots-dropdown'
            >
              <DropdownLink onClick={() => onLinkClick(UPDATE_MODERATOR)}>Change moderator…</DropdownLink>
              {hasConsentForm && (
                <DropdownLink
                  className='flex-nowrap flex items-center'
                  onClick={() => handleBulkAction('consent_form_export')}
                >
                  <ExportSVG />
                  <div className='whitespace-nowrap ml-2'>Export consent form</div>
                </DropdownLink>
              )}
              {renderExportCSV?.()}
              {candidateId && (
                <DropdownLink className='flex-nowrap flex items-center' spa href={`/candidates/${candidateId}`}>
                  <SingleUserSVG />
                  <div className='whitespace-nowrap ml-2'>View on full page</div>
                </DropdownLink>
              )}
            </Dropdown>
          )}
        </>
      );
    case 'started':
      return (
        <>
          {canContact && (
            <Dropdown
              className={getButtonClass('xx-mark-as-dropdown')}
              medium={medium}
              aboveButton={onSlideoutActions}
              text='Mark as…'
              isOpen={showMenu === 'mark_as'}
              onClose={() => setShowMenu(null)}
              onClick={() => setShowMenu('mark_as')}
            >
              <DropdownLink onClick={() => handleBulkAction('mark_invited')}>Mark as invited…</DropdownLink>
              <DropdownLink onClick={() => onLinkClick(COMPLETE_AND_THANKS)}>Mark as completed…</DropdownLink>
              <DropdownLink onClick={() => onLinkClick(CANCEL_TASK_SLIDEOUT)}>Mark as canceled…</DropdownLink>
            </Dropdown>
          )}
          {canContact && (
            <Dropdown
              primary
              medium={medium}
              aboveButton={onSlideoutActions}
              onClick={() => setShowMenu('compose')}
              isOpen={showMenu === 'compose'}
              onClose={() => setShowMenu(null)}
              text='Compose'
              icon='composeEmail'
              className={getButtonClass('xx-dropdown-cta')}
            >
              <DropdownLink onClick={() => onLinkClick(SEND_REMINDERS)}>Send reminder</DropdownLink>
              <DropdownLink onClick={() => onLinkClick(AD_HOC_MESSAGE_SLIDEOUT)}>Ad hoc message</DropdownLink>
            </Dropdown>
          )}
          {showThreeDotsDropdown && (
            <Dropdown
              medium={medium}
              aboveButton={onSlideoutActions}
              onClick={() => setShowMenu('three_dots')}
              isOpen={showMenu === 'three_dots'}
              onClose={() => setShowMenu(null)}
              text={<VerticalDotsSVG />}
              hideCaret
              className={getButtonClass('xx-three-dots-dropdown')}
              dataTestId='three-dots-dropdown'
            >
              {hasConsentForm && (
                <DropdownLink
                  className='flex-nowrap flex items-center'
                  onClick={() => handleBulkAction('consent_form_export')}
                >
                  <ExportSVG />
                  <div className='whitespace-nowrap ml-2'>Export consent form</div>
                </DropdownLink>
              )}
              {renderExportCSV?.()}
              {candidateId && (
                <DropdownLink className='flex items-center' spa href={`/candidates/${candidateId}`}>
                  <SingleUserSVG />
                  <div className='whitespace-nowrap ml-2'>View on full page</div>
                </DropdownLink>
              )}
            </Dropdown>
          )}
        </>
      );
    case 'completed':
      return canContact ? (
        <>
          <Dropdown
            medium={medium}
            className={getButtonClass('xx-mark-as-dropdown')}
            aboveButton={onSlideoutActions}
            text='Mark as…'
            isOpen={showMenu === 'mark_as'}
            onClose={() => setShowMenu(null)}
            onClick={() => setShowMenu('mark_as')}
          >
            <DropdownLink onClick={() => handleCancel()}>Mark as canceled</DropdownLink>
            <DropdownLink onClick={() => handleBulkAction('no_show')}>Mark as no-showed</DropdownLink>
          </Dropdown>
          <Dropdown
            primary
            medium={medium}
            aboveButton={onSlideoutActions}
            onClick={() => setShowMenu('compose')}
            isOpen={showMenu === 'compose'}
            onClose={() => setShowMenu(null)}
            text='Compose'
            icon='composeEmail'
            className={getButtonClass('xx-dropdown-cta')}
          >
            <DropdownLink onClick={() => onLinkClick(SEND_THANKS)}>Thanks</DropdownLink>
            <DropdownLink onClick={() => onLinkClick(AD_HOC_MESSAGE_SLIDEOUT)}>Ad hoc message</DropdownLink>
          </Dropdown>
          {showThreeDotsDropdown && (
            <Dropdown
              medium={medium}
              aboveButton={onSlideoutActions}
              onClick={() => setShowMenu('three_dots')}
              isOpen={showMenu === 'three_dots'}
              onClose={() => setShowMenu(null)}
              text={<VerticalDotsSVG />}
              hideCaret
              className={getButtonClass('xx-three-dots-dropdown')}
              dataTestId='three-dots-dropdown'
            >
              {hasConsentForm && (
                <DropdownLink
                  className='flex-nowrap flex items-center'
                  onClick={() => handleBulkAction('consent_form_export')}
                >
                  <ExportSVG />
                  <div className='whitespace-nowrap ml-2'>Export consent form</div>
                </DropdownLink>
              )}
              {renderExportCSV?.()}
              {candidateId && (
                <DropdownLink className='flex items-center' spa href={`/candidates/${candidateId}`}>
                  <SingleUserSVG />
                  <div className='whitespace-nowrap ml-2'>View on full page</div>
                </DropdownLink>
              )}
            </Dropdown>
          )}
        </>
      ) : null;
    case 'no_show':
    case 'canceled':
    case 'rejected':
      return (
        <>
          {canContact && allPartiesCanBeRescheduled && (
            <Button
              medium={medium}
              className={getButtonClass()}
              onClick={() => onLinkClick(RESCHEDULE_REQUEST_SLIDEOUT)}
            >
              Reschedule
            </Button>
          )}

          <Dropdown
            medium={medium}
            className={getButtonClass('xx-mark-as-dropdown')}
            aboveButton={onSlideoutActions}
            text='Mark as…'
            isOpen={showMenu === 'mark_as'}
            onClose={() => setShowMenu(null)}
            onClick={() => setShowMenu('mark_as')}
          >
            <DropdownLink onClick={() => handleBulkAction('shortlist')}>Mark as shortlisted</DropdownLink>
            {canContact && study.style === 'video_call' && (
              <>
                <DropdownLink
                  aria-label='Mark as scheduled'
                  disabled={!canSchedule}
                  onClick={handleClickManualSchedule}
                >
                  Mark as scheduled…
                </DropdownLink>
                <DropdownLink onClick={() => handleBulkAction('no_show')}>Mark as no-showed</DropdownLink>
              </>
            )}
            {canContact && (
              <DropdownLink onClick={() => onLinkClick(COMPLETE_AND_THANKS)}>Mark as completed…</DropdownLink>
            )}
          </Dropdown>
          {canContact && (
            <Dropdown
              medium={medium}
              className={getButtonClass()}
              primary
              aboveButton={onSlideoutActions}
              text='Compose'
              icon='composeEmail'
              disabled={study.state === 'paused'}
              isOpen={onSlideoutActions ? showMenu === 'compose' : mode === SEND_INVITES_DROPDOWN}
              onClose={handleClose}
              onClick={onSendClick}
            >
              <DropdownLink onClick={() => onLinkClick(AD_HOC_MESSAGE_SLIDEOUT)}>Ad hoc message</DropdownLink>
            </Dropdown>
          )}
          {showThreeDotsDropdown && (
            <Dropdown
              medium={medium}
              aboveButton={onSlideoutActions}
              onClick={() => setShowMenu('three_dots')}
              isOpen={showMenu === 'three_dots'}
              onClose={() => setShowMenu(null)}
              text={<VerticalDotsSVG />}
              hideCaret
              className={getButtonClass('xx-three-dots-dropdown')}
              dataTestId='three-dots-dropdown'
            >
              {hasConsentForm && (
                <DropdownLink
                  className='flex-nowrap flex items-center'
                  onClick={() => handleBulkAction('consent_form_export')}
                >
                  <ExportSVG />
                  <div className='whitespace-nowrap ml-2'>Export consent form</div>
                </DropdownLink>
              )}
              {renderExportCSV?.()}
              {candidateId && (
                <DropdownLink
                  className='xx-three-dots-dropdown flex items-center'
                  spa
                  href={`/candidates/${candidateId}`}
                >
                  <SingleUserSVG />
                  <div className='whitespace-nowrap ml-2'>View on full page</div>
                </DropdownLink>
              )}
            </Dropdown>
          )}
        </>
      );
    default:
      return <></>;
  }
};
