import React, { useState } from 'react';

import { TippyOrNot } from '@components/common/TippyOrNot';
import { ExportCsvModal } from '@components/shared/ExportCsvModal';

import { Button } from './Button';

interface Props {
  disabled?: boolean;
  disabledTooltip?: string;
  subjectString: string;
  params: CsvExportParams;
}

export const ExportCsvButtonServerSide: React.FC<Props> = ({ disabled, disabledTooltip, subjectString, params }) => {
  const [open, setOpen] = useState<boolean>(false);

  if (disabled) {
    return (
      <TippyOrNot content={disabledTooltip} show={!!disabledTooltip}>
        <div>
          <Button disabled aria-label='Export CSV' iconSuffix='export'>
            Export CSV
          </Button>
        </div>
      </TippyOrNot>
    );
  }

  return (
    <>
      <Button aria-label='Export CSV' iconSuffix='export' onClick={() => setOpen(true)}>
        Export CSV
      </Button>
      {open && <ExportCsvModal setOpen={setOpen} subjectString={subjectString} params={params} />}
    </>
  );
};
