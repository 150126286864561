import * as React from 'react';
import { Button } from 'components/common';
import { useUserConnectedAccounts } from 'hooks/useUserConnectedAccounts';
import { WarningSVG } from 'components/svgs';

const getWarningConfig = (connectedAccounts) => {
  if (!connectedAccounts || connectedAccounts.length === 0) {
    return null;
  }

  const invalidConnectedAccounts = connectedAccounts.filter((account: ConnectedAccount) => account.is_invalid);
  const nylasAccount = connectedAccounts.find((account: ConnectedAccount) => account.provider === 'nylas');

  if (invalidConnectedAccounts.length === 0 && !nylasAccount) {
    return null;
  }

  if (nylasAccount && invalidConnectedAccounts.length === 0) {
    const message = `Your current ${nylasAccount.provider_name} integration will disconnect on December 2. Please reintegrate with the new version.`;

    return {
      message,
      buttonText: 'Reintegrate'
    };
  }

  const message =
    invalidConnectedAccounts.length > 1 || nylasAccount
      ? 'There are multiple issues with your integrations.'
      : `Your ${invalidConnectedAccounts[0].provider_name} connection has expired. Please reconnect.`;

  return {
    message,
    buttonText: invalidConnectedAccounts.length > 1 ? 'Go to Integrations' : 'Reconnect'
  };
};

export const InvalidConnectedAccount: React.FC = () => {
  const { connectedAccounts } = useUserConnectedAccounts();

  const warningConfig = getWarningConfig(connectedAccounts);

  if (!warningConfig) {
    return null;
  }

  const { message, buttonText } = warningConfig;
  const button = (
    <Button href='/user/connected_accounts' small className='ml-3'>
      {buttonText}
    </Button>
  );

  return (
    <div className='px-page bg-red-700 text-white flex py-3 border-b border-gray-200'>
      <div className='flex items-center mx-auto'>
        <WarningSVG className='mr-3' />
        <span>{message}</span>
        {button}
      </div>
    </div>
  );
};
