import Tippy from '@tippyjs/react';
import { Text } from '@components/common';
import { Column } from '@components/common/Grid';
import React from 'react';

interface Props {
  iconHref: string;
  tooltip?: string;
  value: string | number;
  label: string;
}

export const SummaryColumn: React.FC<Props> = ({ iconHref, tooltip, value, label }) => {
  return (
    <Column className='px-4 border-r border-gray-200 last:border-r-0'>
      <Tippy disabled={!tooltip} content={tooltip}>
        <svg className='w-4 h-4'>
          <use href={iconHref} />
        </svg>
      </Tippy>
      <Text className='my-2 text-xl' bold>
        {value}
      </Text>
      <Text className='text-sm leading-4 text-gray-500'>{label}</Text>
    </Column>
  );
};
