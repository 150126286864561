import * as React from 'react';
import DatePicker from 'react-datepicker';
import { getWrapperClass } from '@components/CandidateAttrs/index';
import { useInputState } from '@components/CandidateAttrs/hooks/useInputState';

export const DateInput: React.FC<CandidateAttrInput<string>> = ({
  value,
  styles,
  label,
  setValue,
  onSave,
  onFocus,
  onBlur
}) => {
  const { handleBlur, handleFocus, focused } = useInputState(value, setValue, onBlur, onFocus);
  const selected = value && value !== '' && new Date(value);

  const handleChange = (v) => {
    setValue(v);
    onSave && onSave(v);
  };

  if (!styles) return null;

  return (
    <tr data-testid='attr-field' className={getWrapperClass(styles, focused)}>
      <td className={styles.label}>{label}</td>
      <td className={styles.value}>
        <DatePicker
          autoFocus
          selected={selected || null}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className='w-full tablet:text-base flex-1 no-outline py-2.5 px-4'
          placeholderText='Enter...'
          showYearDropdown
          startOpen={true}
          customInput={<input />}
        />
      </td>
    </tr>
  );
};
