import * as React from 'react';

import { Pill } from '@components/common';

type Props = {
  calendarEvent: CalendarEvent;
};

export const CalendarEventStatusPill: React.FC<Props> = ({ calendarEvent }) => (
  <Pill color='gray'>{calendarEvent.replaced_at ? 'Replaced' : 'Canceled'}</Pill>
);
