export const DELETE_LABEL_MAP = {
  Story: 'Delete insight',
  Clip: 'Delete highlight',
  Highlight: 'Delete highlight',
  HighlightReel: 'Delete highlight reel'
};

export const ARTIFACT_COLUMN_LABELS = {
  title: 'Name',
  kind: 'Type ',
  subtitle: 'Study',
  tag_ids: 'Tags',
  study_user_ids: 'Creator',
  created_at: 'Created',
  updated_at: 'Last updated',
  views: '# of views',
  clips: '# of highlights'
};

export const STATIC_COLUMNS = ['model_id', 'title', 'actions'];
