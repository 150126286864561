import { WithUser } from 'hooks/useRecordsWithUser';
import React from 'react';
import { Link } from 'react-router-dom';
import tinytime from 'tinytime';

import { ColumnDef, Table } from '@components/shared/Table';

const template = tinytime('{MMMM} {DD} at {h}:{mm}{a}');

export const tableDefition: ColumnDef<WithUser<AiChat>>[] = [
  {
    id: 'title',
    size: 200,
    minSize: 150,
    accessorFn: (originalRow) => originalRow.first_content,
    header: (props) => (
      <Table.ColumnHeader disableSort disableHide {...props}>
        First message
      </Table.ColumnHeader>
    ),
    cell: (props) => (
      <Table.Cell
        {...props}
        render={({ row }) => {
          return (
            <Link className='px-2 truncate' to={`${row.original.uuid}`}>
              {row.original.first_content || 'No messages'}
            </Link>
          );
        }}
      />
    )
  },
  {
    id: 'prompt_id',
    minSize: 80,
    size: 120,
    header: (props) => (
      <Table.ColumnHeader disableSort disableHide {...props}>
        Prompt
      </Table.ColumnHeader>
    ),
    accessorFn: (originalRow) => `${originalRow.prompt_id}`,
    cell: (props) => <Table.Cell {...props} />
  },
  {
    id: 'creator',
    minSize: 80,
    size: 120,
    accessorFn: (originalRow) => `${originalRow.user_id}`,
    header: (props) => (
      <Table.ColumnHeader disableSort disableHide {...props}>
        User
      </Table.ColumnHeader>
    ),
    cell: (props) => (
      <Table.Cell {...props} render={({ row }) => <span className='px-2 truncate'>{row.original.user.name}</span>} />
    )
  },
  {
    id: 'created_at',
    size: 80,
    minSize: 120,
    accessorFn: (originalRow) => `${template.render(new Date(originalRow.created_at))}`,
    header: (props) => (
      <Table.ColumnHeader disableSort disableHide {...props}>
        Date
      </Table.ColumnHeader>
    ),
    cell: (props) => (
      <Table.Cell
        {...props}
        render={({ row }) => (
          <span className='px-2 truncate'>{template.render(new Date(row.original.created_at))}</span>
        )}
      />
    )
  }
];
