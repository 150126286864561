import React, { forwardRef, HTMLAttributes, useMemo, useState } from 'react';
import { Table } from '@components/shared/Table';
import { getTableColumnDefs } from '@components/ScreenerResults/components/CardSortResponse/components/helpers/getTableColumnDefs';
import { ResponseDetailsModal } from '@components/ScreenerResults/components/CardSortResponse/components/ResponseDetailsModal';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
}

export const ResponsesTable = forwardRef<HTMLDivElement, Props>(({ answers, className, ...rest }, ref) => {
  const [preview, setPreview] = useState<NonNullable<ScreenerResponseAnswerValue<'card_sort'>> | null>(null);

  const columns = useMemo(() => getTableColumnDefs({ setPreview }), []);

  return (
    <>
      <Table data={answers} columns={columns} className='w-full bg-white border border-gray-200 table-fixed' />
      {preview && <ResponseDetailsModal response={preview} onClose={() => setPreview(null)} />}
    </>
  );
});
