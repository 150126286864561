import React from 'react';

/* eslint-disable no-useless-escape */

export const isUrl = (str: string): boolean => {
  // Remove leading/trailing whitespace
  str = str.trim();

  // Regular URL pattern with protocol, including hash fragments and query parameters
  const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]*)?$/i;

  // Domain only pattern (for cases like blog.example.com)
  const domainPattern = /^[\w-]+(\.[\w-]+){2,}([\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]*)?$/i;

  return urlPattern.test(str) || domainPattern.test(str);
};

export const LinkifiedText: React.FC<{ text?: string }> = ({ text }) => {
  if (!text) {
    return null;
  }

  const addMarkup = (word) => {
    const link = word.startsWith('http') ? word : `https://${word}`;

    return isUrl(word) && !word.startsWith('javascript:') ? `<a target='_blank' href='${link}'>${word}</a>` : word;
  };

  const words = text.split(' ');
  const formatedWords = words.map(addMarkup);
  const html = formatedWords.join(' ');

  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};
