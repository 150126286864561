import * as React from 'react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Alert, Button, Spinner, Text } from '@components/common';
import { CloseIcon, ModalBackdrop } from '@components/common/Modal';
import { WindowHeader, WindowLayout, WindowLayoutBody } from '@components/layouts/WindowLayout';
import { UploadBoxSVG } from '@components/svgs';
import { useAuthProvider } from '@hooks/useAuthProvider';

import { useBulkUploadActions, useBulkUploadState } from '../..';
import { useCreateRecordingItems } from '../../modals/useCreateRecordingItems';

export const SUPPORTED_FILE_TYPES = ['mp4', 'm4v', 'mov', 'mp3'];

export const UploadManagerZDS: React.FC = () => {
  const { studyId } = useBulkUploadState();
  const [loading, setLoading] = useState(false);

  const { getButtonProps, isConnected, isInvalid, isLoading, isOpen: authPopupOpen } = useAuthProvider('zoom');

  const { createItems } = useCreateRecordingItems({ studyId });
  const [error, setError] = useState<{
    heading: string;
    message: string;
  } | null>(null);

  const { closeManagerZDS, submitModal, openZoomImportModal } = useBulkUploadActions();

  const handleDrop = async (files: File[]) => {
    if (SUPPORTED_FILE_TYPES) {
      for (const file of files) {
        if (!SUPPORTED_FILE_TYPES.some((ext) => file.name.toLowerCase().endsWith(ext))) {
          const parts = file.name.split('.');
          const badExt = parts.length > 1 ? `.${parts[parts.length - 1]} files` : 'this kind of file';

          // May consider moving to toast - discuss with design
          setError({
            heading: `Sorry, we don’t support ${badExt}`,
            message: `Please upload a ${SUPPORTED_FILE_TYPES.join(', ')} file.`
          });
          return;
        }
      }
    }

    setLoading(true);
    submitModal(await createItems(files));
  };

  // one of three usages. confusing.
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openDropzone
  } = useDropzone({
    disabled: loading,
    onDrop: handleDrop,
    noClick: true,
    accept: {
      'video/mp4': ['.mp4'],
      'video/x-m4v': ['.mv4'],
      'video/quicktime': ['.mov'],
      'audio/mpeg': ['.mp3']
    }
  });

  return (
    <div className='xx-upload-manager-zds fixed inset-0 z-50 bg-white'>
      {authPopupOpen && <ModalBackdrop />}
      <WindowLayout>
        <WindowHeader>
          <div className='flex items-center justify-between flex-1'>
            <Text h='700'>Upload manager</Text>

            <button onClick={closeManagerZDS} aria-label='Close panel' className='hover:text-indigo-500 text-gray-700'>
              <CloseIcon className='w-5 h-5 text-indigo-600' />
            </button>
          </div>
        </WindowHeader>
        <WindowLayoutBody className='flex-1'>
          <div className='px-page py-gutter flex w-full h-full'>
            {!loading && (
              <>
                <input {...getInputProps()} />

                <div
                  {...getRootProps()}
                  className='bg-gray-50 flex flex-col items-center justify-center flex-1 my-6 border border-indigo-600 border-dashed rounded-lg'
                >
                  {!loading && <UploadBoxSVG />}
                  {!isDragActive && (
                    <span className='block pb-2 mt-6 text-lg font-bold text-gray-700'>
                      Drag &amp; drop, or upload videos files
                    </span>
                  )}
                  {!loading && isDragActive && (
                    <span className='block pb-2 mt-6 text-lg font-bold text-gray-700'>Drop your files here!</span>
                  )}
                  {!loading && (
                    <div className='text-gray-500'>We support MP4, M4V, MOV, and MP3 files of any size.</div>
                  )}

                  <div className='flex mt-6 space-x-6'>
                    {((isConnected && !isInvalid) || isLoading) && (
                      <Button
                        disabled={isLoading}
                        icon='uploadZoom'
                        medium
                        onClick={() => openZoomImportModal(studyId)}
                      >
                        Import from Zoom
                      </Button>
                    )}
                    {(!isConnected || isInvalid) && !isLoading && (
                      <Button icon='uploadZoom' medium {...getButtonProps({ callback: openZoomImportModal })}>
                        {isInvalid ? 'Re-authorize Zoom' : 'Connect Zoom'}
                      </Button>
                    )}
                    <Button icon='uploadVideo' className='xx-browse-files' medium onClick={openDropzone}>
                      Browse files
                    </Button>
                  </div>
                  {error && (
                    <Alert type='error' className='my-6'>
                      <span className='block mb-1 font-bold'>{error.heading}</span>
                      <span className='block'>{error.message}</span>
                    </Alert>
                  )}
                  {loading && (
                    <div className='bg-gray-50 flex items-center justify-center w-full h-full rounded-lg'>
                      <Spinner className='w-10 h-10' />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </WindowLayoutBody>
      </WindowLayout>
    </div>
  );
};
