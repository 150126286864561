import React from 'react';

import { Table } from '@components/shared/Table';
import { ColumnDef } from '@tanstack/react-table';
import { VideoCameraSVG } from '@components/svgs';
import { ClickPointer } from '@components/ScreenerResults/components/PrototypeTestResponse/components/ClickPointer';
import { Text, Tooltip } from '@components/common';

export type DefinitionFnReturn = ColumnDef<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>>[];

type Args = { setPreview: (value: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>) => void };

export type DefinitionFn = (args: Args) => DefinitionFnReturn;

export const getTableColumnDefs: DefinitionFn = ({ setPreview }) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'participation_name',
      accessorKey: 'participation_name',
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>>
          {...props}
          render={({ row }) => (
            <div className='flex items-center space-x-2 px-2'>
              <div className='truncate'>{row.original.participation_name || 'Unnamed'}</div>
              {row.original.session_uuid && (
                <a href={`/sessions/${row.original.session_uuid}`} target='_blank'>
                  <VideoCameraSVG className='w-4 h-4 ml-2' />
                </a>
              )}
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Name
        </Table.ColumnHeader>
      )
    },
    {
      id: 'outcome',
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>>
          {...props}
          render={({ row }) => <ClickPointer className='px-2' size='lg' failure={!row.original.completed} />}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Outcome
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>> {...props} />,
      id: 'cards_and_categories',
      accessorFn: (row) => `${row.cards.length} / ${row.categories.length}`,
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          Cards / Categories
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>> {...props} />,
      id: 'total_time',
      accessorFn: (row) => (row.time_in_task ? `${(row.time_in_task / 1000).toFixed(1)}s` : ''),
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          Total time
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'card_sort'>>>
          {...props}
          render={({ row }) => (
            <Tooltip content='Details'>
              <button
                aria-label='Details'
                onClick={() => setPreview(row.original)}
                className='px-2 hover:bg-gray-100 inline-flex items-center px-2 space-x-2 border border-gray-200 rounded'
              >
                {row.original.categories.slice(0, 2).map(({ cards }, index) => (
                  <div key={index}>{cards.length || '0'}</div>
                ))}
                {row.original.categories.length > 2 && (
                  <Text className='text-sm text-gray-500'>+{row.original.categories.length - 2}</Text>
                )}
              </button>
            </Tooltip>
          )}
        />
      ),
      id: 'responses',
      header: (props) => (
        <Table.ColumnHeader {...props} disableSort>
          Response
        </Table.ColumnHeader>
      )
    }
  ];

  return columns;
};
