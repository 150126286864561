import React from 'react';

import { Table } from '@components/shared/Table';
import { ColumnDef } from '@tanstack/react-table';
import { ChevronDownSVG } from '@components/svgs';
import { ProgressBar, Text, Tooltip } from '@components/common';
import { pct } from '@components/utils';
import cn from 'classnames';
import pluralize from 'pluralize';
import { CategoriesOptionsDropdown } from '@components/ScreenerResults/components/CardSortResponse/components/CategoriesOptionsDropdown';

export type DefinitionFnReturn = ColumnDef<any>[];

export type DefinitionFn = () => DefinitionFnReturn;

export const getCategoriesTableColumnDefs: DefinitionFn = () => {
  const columns: DefinitionFnReturn = [
    {
      id: 'category',
      cell: (props) => (
        <Table.Cell<any>
          {...props}
          render={({ row }) => (
            <div className='flex items-center space-x-2 px-2'>
              <div className='truncate'>{row.original.participation_name || 'Unnamed'}</div>
              {row.getIsExpanded() && (
                <div className='flex flex-col space-y-3'>
                  <Text bold h='200'>
                    Cards
                  </Text>
                  {row.original.categories.map((item) => (
                    <Text h='400'>{item}</Text>
                  ))}
                </div>
              )}
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Category
        </Table.ColumnHeader>
      )
    },
    {
      id: 'cards_number',
      cell: (props) => (
        <Table.Cell<any>
          {...props}
          render={({ row }) => (
            <div className='flex items-center space-x-2 px-2'>
              <div className='truncate'>{pluralize('card', row.original.cards.length || 0, true)}</div>
              {row.getIsExpanded() && (
                <div className='flex flex-col space-y-3'>
                  <div className='flex items-center space-x-2'>
                    <div className='h200 font-bold'>Frequency</div>
                    <Tooltip>The number of responses where this card was put into this category</Tooltip>
                  </div>
                  {row.original.frequencies.map((item) => (
                    <Text h='400'>{item}</Text>
                  ))}
                </div>
              )}
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Number of cards
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<any>
          render={({ row }) => (
            <div className='flex items-center space-x-2 px-2'>
              <div className='px-2 flex items-center'>
                <ProgressBar size='sm' progress={pct(row.original.cards.length, row.original.categories.length)} />
                <div className='ml-4 w-8 h400'>{pct(row.original.cards.length, row.original.categories.length)} %</div>
              </div>
            </div>
          )}
          {...props}
        />
      ),
      id: 'avg_agreement',
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Avg. Agreement
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<any>
          {...props}
          render={({ row }) => (
            <div className='px-2 flex items-center space-x-w'>
              <CategoriesOptionsDropdown />
              <button onClick={() => row.getToggleExpandedHandler()}>
                <ChevronDownSVG className={cn('w-4 h-4 transform', { ['rotate-180']: row.getIsExpanded() })} />
              </button>
            </div>
          )}
        />
      ),
      id: 'expand',
      header: (props) => <Table.ColumnHeader {...props} isStatic />
    }
  ];

  return columns;
};
