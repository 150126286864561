import React, { SVGProps } from 'react';

export const OnlineTaskSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <rect fill='none' height='24' width='24' />
    <path d='M8.25 23.25H15.75' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' />
    <path d='M12 20.25V23.25' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' />
    <path d='M0.75 16.5H23.25' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' />
    <path
      d='M21.75 3C22.1478 3 22.5294 3.15804 22.8107 3.43934C23.092 3.72064 23.25 4.10218 23.25 4.5V18.75C23.25 19.1478 23.092 19.5294 22.8107 19.8107C22.5294 20.092 22.1478 20.25 21.75 20.25H2.25C1.85218 20.25 1.47064 20.092 1.18934 19.8107C0.908035 19.5294 0.75 19.1478 0.75 18.75V4.5C0.75 4.10218 0.908035 3.72064 1.18934 3.43934C1.47064 3.15804 1.85218 3 2.25 3'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
    <path
      d='M5.25 13.5V2.25C5.25 1.85218 5.40804 1.47064 5.68934 1.18934C5.97064 0.908035 6.35218 0.75 6.75 0.75H17.25C17.6478 0.75 18.0294 0.908035 18.3107 1.18934C18.592 1.47064 18.75 1.85218 18.75 2.25V13.5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
    <path
      d='M8.25 6.75H11.25V9.75H8.25V6.75Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
    <path d='M5.25 3.75H18.75' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' />
    <path d='M14.25 6.75H15.75' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' />
    <path d='M14.25 9H15.75' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' />
    <path d='M8.25 12.75H15.75' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' />
  </svg>
);
