import React, { FC, ReactNode } from 'react';

import * as Icons from '../../icons';
import { ArtifactAttributes } from '../../types';
import { ChecklistSVG, HighlightSVG } from '@components/svgs';

enum ArtifactType {
  'Participation' = 'Interview',
  'Transcript' = 'Transcript',
  'Highlight' = 'Highlight',
  'HighlightReel' = 'Reel',
  'Repo::Session' = 'Interview',
  'Story' = 'Insight',
  'Clip' = 'Highlight',
  'ScreenerResponse' = 'Survey Response'
}

interface Props {
  kind: ArtifactAttributes['kind'];
}

const ICONS_MAP: Record<keyof typeof ArtifactType, ReactNode> = {
  HighlightReel: <Icons.Clip className='inline h-4 w-4' />,
  Clip: <HighlightSVG className='inline h-4 w-4' />,
  'Repo::Session': <Icons.Interview className='inline h-4 w-4' />,
  Highlight: <HighlightSVG className='inline h-4 w-4' />,
  Participation: <Icons.Interview className='inline h-4 w-4' />,
  ScreenerResponse: <ChecklistSVG className='w-4 h-4 inline' />,
  Story: <Icons.Insight className='inline h-4 w-4' />,
  Transcript: <HighlightSVG className='inline h-4 w-4' />
};

export const ArtifactKind: FC<Props> = ({ kind }) => {
  return (
    <span className='px-2 truncate'>
      {ICONS_MAP[kind]} {ArtifactType[kind]}
    </span>
  );
};
