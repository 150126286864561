import React, { VFC, useState } from 'react';

import { Tabs } from '@components/common';
import { compact } from '@components/utils';

import { LABELS, TABS } from './constants';
import { TabValues } from './types';
import { CardSortSummary } from './components';
import { CardsTable, CategoriesTable, ResponsesTable, AgreementMatrix } from './components';


interface Props {
  answers: ScreenerResponseAnswerValue<'card_sort'>[];
}

export const CardSortResponse: VFC<Props> = ({ answers }) => {
  const [activeTab, setActiveTab] = useState<TabValues>('table');

  const validAnswers = compact(answers) as NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'agreement_matrix':
        return <AgreementMatrix categories={[]} answers={validAnswers} />;
      case 'cards':
        return <CardsTable answers={validAnswers} />;
      case 'categories':
        return <CategoriesTable answers={validAnswers} />;
      case 'table':
        return <ResponsesTable answers={validAnswers} />;
      default:
        return null;
    }
  };

  if (validAnswers.length === 0) {
    return null;
  }

  return (
    <div>
      <CardSortSummary answers={validAnswers} sortType={'open'} />

      <Tabs<TabValues> current={activeTab} tabs={TABS} labels={LABELS} onSelect={setActiveTab} className='mb-4' />

      {renderTabContent()}
    </div>
  );
};
